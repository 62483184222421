//#region 
import React from "react";
import PageTitle from "../../Components/pageTitle";
import PageBody from "../../Components/pageBody";
import { useSelector } from "react-redux";
import { translate } from "../../utils/translate";
import Modal from "../../Components/modal";
import { TitleContainer } from "../indicadoresAgricola/style";
//#endregion

const UserManagerPage: React.FC = () => {
  const state = useSelector<any, any>(({ language }) => language);
  return (
    <>
      <TitleContainer style={{ width: "120%" }}>
        <PageTitle>
          <h1>{translate(state.language, "Gerenciar usuario")}</h1>
          <small>{translate(state.language, "gerenciamento de usuarios")}</small>
        </PageTitle>
        {/* <Modal /> */}
      </TitleContainer>
      <PageBody isFullWidth={true}>

      </PageBody>
    </>
  );
};

export default UserManagerPage;
