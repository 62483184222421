import React, { useEffect, useState } from "react";
import { FilterContainer, Header, UnselectIndicator } from "./style";
import FilterGeneric from "./filterGeneric";
import { v4 as uuid } from "uuid";
import { groupBy } from "lodash";
import useFilter from "../../hooks/useFilter";
import useTranslate from "../../hooks/useTranslate";

interface IProps {
  filters: string[];
  addDinamycFilters?: {
    label: string;
    name: string;
  }[];
}
interface IDinamicList {
  filter: string;
  id: string;
}
const Filter: React.FC<IProps> = ({ filters, addDinamycFilters, children }) => {
  const [hideItem, setHide] = useState("");
  const [dinamicList, setDinamicList] = useState<IDinamicList[]>([]);
  const { filter, handleRemoveFilter, removeComparativoSafra } = useFilter();
  const { translate } = useTranslate();

  const addNewItemInList = (props: any, idCreated?: string) => {
    const id = idCreated || uuid();
    props.map((item: any) => {
      setDinamicList((e) => [...e, { ...item, id }]);
    });
  };
  const removeSectionInList = (id: string) => {
    removeComparativoSafra(id);
    const removeItemFromArr = dinamicList.filter(
      ({ id: idElement }) => idElement != id
    );
    const returnItemArr = dinamicList.filter(
      ({ id: idElement }) => idElement == id
    );
    returnItemArr.map(({ name, label, id }: any) => {
      handleRemoveFilter({ key: name, idKey: id });
    });
    setDinamicList(removeItemFromArr);
  };
  
  const grouppedItem = groupBy(dinamicList, "id");
  const filtersApply = filter?.filtersApply && Object.keys(filter.filtersApply);
  useEffect(() => {
    if (dinamicList.length < 1) {
      
      let resultData: any = [];
      filtersApply?.map((key: string) => {
        const [name, id] = key.split("_");
        const { title: label } = filter?.filtersApply[key];
        const result = { name, label, id };
        resultData = [...resultData, result];
      });
      const groupId = groupBy(resultData, "id");
      const keysGroupId = Object.keys(groupId);
      const result = keysGroupId.map((key: string) => {
        const data = groupId[key];
        addNewItemInList(data, key);
        return { id: key, data };
      });
    }
  }, [filtersApply]);

  return (
    <>
      <FilterContainer>
        <Header style={{ marginTop: "-13px" }}>
          <p>{translate("Adicionar Filtros")}</p>
          <small>
            {translate("aplicar filtros, refinando a analise dos dados.")}
          </small>
        </Header>
        {children}
        <>
          {filters?.map((item: string) => {
            return (
              
              <div key={`${item}_idElement`}>
                
                <FilterGeneric
                  filter={item}
                  hide={hideItem == item}
                  onPress={(e) =>
                    e == hideItem ? setHide("") : setHide(`${e}`)
                  }
                />
              </div>
            );
          })}
          {addDinamycFilters && (
            <UnselectIndicator
              onClick={() => addNewItemInList(addDinamycFilters)}
            >
               {translate("adicionar filtros")}
            </UnselectIndicator>
          )}

          {addDinamycFilters &&
            Object.keys(grouppedItem).map((key: string) => {
              
              const group = grouppedItem[key];
              return (
                <div
                  style={{
                    padding: "5px 5px",
                    background: "#2f3243",
                    marginTop: "5px",
                  }}
                >
                  {group.map((props: any) => {
                    const { name, id } = props;
                    
                    return (
                      <FilterGeneric
                        filter={name}
                        style={{ marginTop: "-0px" }}
                        hide={hideItem == `${name}_${id}`}
                        id={id}
                        removeCloseButton
                        onPress={(e) =>
                          hideItem == `${name}_${id}`
                            ? setHide("")
                            : setHide(`${name}_${id}`)
                        }
                        removeSection={() => removeSectionInList(id)}
                      />
                    );
                  })}
                </div>
              );
            })}
        </>
      </FilterContainer>
    </>
  );
};

export default Filter;





                // {
                //     "path": "M 67.363954,117.12809 45.736796,144.13004 26.669058,110.72952 46.888535,100.74775 Z",
                //     "id": "2106-T1",
                //     "name": "2106-T1"
                // },
                // {
                //     "path": "M 83.040447,97.228536 68.387726,116.10432 48.136257,99.883945 c 0,0 9.501878,-4.255048 10.173725,-5.118858 0.671848,-0.863809 3.743169,-3.807145 3.871132,-4.542974 0.128029,-0.735846 3.775161,-6.846477 3.775161,-6.846477 z",
                //     "id": "2106-T2",
                //     "name": "2106-T2"
                // }