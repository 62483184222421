import { groupBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Layout } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import useFarm from "./useFarm";
interface IGetTalhaoWithFarm {
  CodFazenda: any;
  DescricaoFazenda: any;
  CodTalhao: any;
}
const useTalhao: any = () => {
  const talhao: any = useSelector<any | any>(({ talhao }) => talhao);
  const dispatch = useDispatch();
  const { farm } = useFarm();

  const setTalhao = (data: any) => {
    dispatch({
      type: "SET_TALHAO",
      payload: data,
    });
  };
  const getTalhaoWithFarm: () => IGetTalhaoWithFarm = () => {
    const groupTalhoes = groupBy(talhao, "Codigo");
    const codTalhoes = Object.keys(groupTalhoes);
    const talhoesGroupWithFarm = codTalhoes.map((item) => {
      const { CodFazenda, Descricao: DescricaoTalhao } = groupTalhoes[item][0];
      const { Codigo, Descricao } = farm?.filter(
        (e: any) => e.Codigo == CodFazenda
      )[0];
      return {
        codigoFazenda: Codigo,
        descricaoFazenda: Descricao,
        codigoTalhao: item,
        descricaoTalhao: DescricaoTalhao,
      };
    });
    const CodFazenda = groupBy(talhoesGroupWithFarm, "codigoFazenda");
    const DescricaoFazenda = groupBy(talhoesGroupWithFarm, "descricaoFazenda");
    const CodTalhao = groupBy(talhoesGroupWithFarm, "codigoTalhao");
    return { CodFazenda, DescricaoFazenda, CodTalhao };
  };
  return { setTalhao, talhao, getTalhaoWithFarm };
};

export default useTalhao;
