import React, { useState } from "react";
import { Item } from "./style";
import { v4 as uuid } from "uuid";
import {
  AiOutlineBarChart,
  AiOutlineTable,
  AiOutlineEyeInvisible,
  AiOutlineEye,
  AiOutlineLineChart,
  AiOutlineProfile,
} from "react-icons/ai";
interface Ilayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}
interface ISidebar {
  verification?: boolean;
  component?: any;
  title: string;
  subtitle: string;
  subLevels: any;
  sharedLayout?: Ilayout[];
}
const SidebarReports: React.FC<ISidebar> = ({
  verification,
  title,
  subLevels,
  subtitle,
  sharedLayout,
  component,
}) => {
  const [clickedElement, setClickedElement] = useState(false);

  return (
    <>
      <Item
        draggable={!subLevels}
        subLevels={!!subLevels}
        disabled={!!verification}
        active={clickedElement}
        unselectable="on"
        onDragStart={(e) => {
          e.dataTransfer.setData("component", component);
          e.dataTransfer.setData("id", uuid());
        }}
        onClick={(e) => setClickedElement((e) => (!!subLevels ? !e : false))}
      >
        {!!subLevels ? (
          <>
            <div style={{ display: "flex" }}>
              <div>
                <h1>{title}</h1>
                <small>{subtitle}</small>
              </div>
              <div style={{ marginLeft: "auto" }}>
                {clickedElement ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </div>
            </div>
          </>
        ) : (
          <>
            <h1>{title}</h1>
            <small>{subtitle}</small>
          </>
        )}

        {subLevels &&
          clickedElement &&
          subLevels.map((subitem: any) => {
            let verifikey = sharedLayout?.find(
              (item) => item.i === subitem.component
            );
            return (
              <Item
                key={subitem.component}
                draggable={true}
                unselectable="on"
                onDragStart={(e) => {
                  e.dataTransfer.setData("component", subitem.component);
                  e.dataTransfer.setData("id", uuid());
                }}
              >
                <h1>
                  {subitem.graph_type === "bar" && <AiOutlineBarChart />}
                  {subitem.graph_type === "table" && <AiOutlineTable />}
                  {subitem.graph_type === "lines" && <AiOutlineLineChart />}
                  {subitem.graph_type === "kpi" && <AiOutlineProfile />}
                  <span style={{ marginLeft: "5px" }} />
                  {subitem.title}
                </h1>
                <small>{subitem.subtitle}</small>
              </Item>
            );
          })}
      </Item>
    </>
  );
};

export default SidebarReports;
