import styled, { css } from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
  display: flex;
`;
export const IdepenteImage = styled.img`
  width: 100px;
  position: fixed;
  top: 20px;
  left: 20px;
`;
export const Form = styled.form`
  position: fixed;
  display: flex;
  width: 400px;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
  padding: 10px;
  z-index: 9999999;
  button,
  img,
  h1,
  p,
  input {
    flex-basis: 100%;
  }
  h1 {
    font-size: 48px;
    font-weight: 500;
    color: black;
  }
  p {
    font-size: 13px;
    font-weight: 100;
    margin-bottom: 50px;
  }
  small {
    margin-top: 5px;
    margin-right: auto;
  }
  button {
    background-color: #70a5ff;
    border: none;
    padding: 15px;
    color: #fff;
    margin-top: 30px;
    text-transform: uppercase;
    cursor: pointer;
  }
  img {
    margin-top: 100px;
    max-width: 132px;
    height: 33px;
    margin-bottom: 100px;
  }
  .showInSmall {
    display: none;
  }
  @media (max-width: ${theme.ligth.mobileDimension}) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    img {
      margin-top: 50px;
      max-width: 90px;
      margin-bottom: 100px;
    }
    .showInSmall {
      display: block;
      font-size: 13px;
      text-align: center;
      margin-top: 20px;
    }
  }
  @media (max-width: ${theme.ligth.mediumDimension}) {
    img {
      margin-top: 20px;
      margin-bottom: 80px;
    }
  }
`;
export const Content = styled.ul`
  list-style: none;
  margin-left: 50%;
  z-index: 999;
  li {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 100px;
    h1 {
      color: black;
      font-size: 24;
      font-weight: 500;
    }
    p {
      width: 500px;
    }
  }
  @media (max-width: ${theme.ligth.mobileDimension}) {
    .invisibledInMobile {
      display: none;
    }
  }
  @media (max-width: ${theme.ligth.mediumDimension}) {
    img {
      z-index: 0;
      overflow: hidden;
      max-width: 620px;
    }
  }
`;
interface IInput {
  focus?: boolean;
}
export const Input = styled.input<IInput>`
  padding: 15px;
  border-radius: 2px;
  border: none;
  background: transparent;
  outline: none;
  & + input {
    margin-top: 10px;
  }
  ${({ focus }) =>
    focus &&
    css`
      background: transparent;
    `}
`;
export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  background: #f5f5f7;
  align-items: center;
  padding: 3px 15px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
`;
