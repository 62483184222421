import styled, { css } from "styled-components";
import theme from "../../styles/theme";
interface IProps {
  isFullWidth?: boolean;
}
export const Container = styled.div<IProps>`
  margin-top: 30px;

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 120%;
    `}
  @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
    ${({ isFullWidth }) =>
      isFullWidth &&
      css`
        width: 100%;
      `}
  }
  @media only screen and (max-width: ${theme.ligth.mediumDimension}) {
    ${({ isFullWidth }) =>
      isFullWidth &&
      css`
        width: 100%;
      `}
  }
`;
