import { Reducer } from "redux";
import produce from "immer";
import IData from "./interface";

const INITIAL_STATE: IData = {
  layout: [],
  indicatorSelected: {
    name: "",
    filters: [],
    id: "",
  },
};

const dashboardLayout: Reducer<IData> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_DASHBOARD_LAYOUT": {
      return produce(state, (draft: any) => {
        draft.layout = action.payload;
      });
    }
    case "SET_INDICATOR_SELECTED": {
      return produce(state, (draft: any) => {
        draft.indicatorSelected = action.payload;
      });
    }
    default: {
      return state;
    }
  }
};

export default dashboardLayout;
