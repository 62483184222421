import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface IButton {
    editMode?: boolean;
    saveDashboard?: boolean;
    disabled?: boolean;
  }

export const Button = styled(Link)<IButton>`
  padding: 10px 35px;
  border: none;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 4;
  display: ${({ disabled }) => (disabled ? "none" : "")};
  &:hover {
    background-color: #2F3243;
    color: #fff;
  }
  ${({ editMode }) =>
    !editMode &&
    css`
      background-color: #2F3243;
      color: #fff;
    `};
  ${({ saveDashboard }) =>
    saveDashboard &&
    css`
      &:hover {
        background-color: #b8ff9f;
        color: black;
      }
    `}
`;
export const ButtonClick = styled.button<IButton>`
  padding: 10px 35px;
  border: none;
  border-radius: 4px;
  outline: none;
  
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 4;
  display: ${({ disabled }) => (disabled ? "none" : "")};
  &:hover {
    background-color: #2F3243;
    color: #fff;
  }
  ${({ editMode }) =>
    !editMode &&
    css`
      background-color: #2F3243;
      color: #fff;
    `};
  ${({ saveDashboard }) =>
    saveDashboard &&
    css`
      &:hover {
        background-color: #b8ff9f;
        color: black;
      }
    `}
`;