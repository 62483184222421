import React, { useEffect, useState } from "react";
import PainelOficina from "../../reports/painelOficina";
import ButtonToTv from "../../Components/buttonToTv";
import PageTitle from "../../Components/pageTitle";
import PageBody from "../../Components/pageBody";
import { translate } from "../../utils/translate";
import ProgressBar from "../../Components/progressBar";
import { useSelector } from "react-redux";
const PainelDeOficinaPage: React.FC = () => {
  const state = useSelector<any, any>(({ language }) => language);
  return (
    <>
      <PageTitle>
        <h1>{translate(state.language, "Painel Oficina")}</h1>
        <small>
          {translate(state.language, "analise do painel de oficina")}
        </small>
        <ProgressBar time={60} />
        <ButtonToTv style={{ marginLeft: "81%" }} link="painelOficina/tv" />
      </PageTitle>
      <PageBody isFullWidth={true}>
        <PainelOficina />
      </PageBody>
    </>
  );
};

export default PainelDeOficinaPage;
