import styled from "styled-components";
import { CheckboxSVGMap } from "react-svg-map";

export const MapSvg = styled(CheckboxSVGMap)`
.examples__block__map__tooltip {
    position: fixed;
    display: none;
    width: 200px;
    padding: 10px;
    border: 1px solid darkgray;
    background-color: #000;
  }
  width: 100%;
  .svg-map {
    background: red !important;
  }
  .arido {
    fill: url(#arido) !important;
  }
  .svg-map__location {
    transition: 0.1s;
    stroke: #2f324360;
    stroke-width: 0.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: transparent;
    cursor: pointer;
    outline: 0;
  }
   
  .svg-map__location:hover {
    stroke: #2f3243;
    outline: 0;
  }
  .svg-map__location[aria-checked="true"] {
    outline: 0;
    stroke: #2f3243;
    stroke-width: .8px;
  }
  .percentage_true {
    fill: red;
  }
  .percentage_false {
    fill: #f4bc4495;
  }
  .percentage_0 {
    fill: #fff !important;
  }
  .percentage_10 {
    fill: #f4bc4420 !important;
  }
  .percentage_20 {
    fill: #f4bc4430 !important;
  }
  .percentage_30 {
    fill: #f4bc4440 !important;
  }
  .percentage_40 {
    fill: #f4bc4450 !important;
  }
  .percentage_50 {
    fill: #f4bc4460 !important;
  }
  .percentage_60 {
    fill: #f4bc4470 !important;
  }
  .percentage_70 {
    fill: #f4bc4480 !important;
  }
  .percentage_80 {
    fill: #f4bc4490 !important;
  }
  .percentage_90 {
    fill: #f4bc4495 !important;
  }
  .percentage_100 {
    fill: #ffae0090 !important;
  }
`;
interface BUtton {
  active: boolean;
}
export const Button = styled.button<BUtton>`
  background-color: ${({active}) => active ? '#fff' : '#00000033'};
  color: ${({active}) => active ? '#000' : '#fff'};
  padding: 10px 25px;
  text-transform: uppercase;
  height: 49px;
  width: 100%;
  cursor:pointer;
  border: none;
  text-align: left;
  margin-bottom: 8px;
`