import React, { createContext, useState, useCallback } from "react";

interface IEditModeContext {
  editMode:boolean;
  editModeChange: ()=> void;
  exitEditMode: ()=> void;
}
export const EditModeContext = createContext<IEditModeContext>(
  {} as IEditModeContext
);

export const EditModeProvider: React.FC = ({ children }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const editModeChange = ()=>{
    setEditMode(e => !e)
  }
  const exitEditMode = ()=>{
    setEditMode(false)
  }
  return (
    <EditModeContext.Provider
      value={{
        editMode,
        editModeChange,
        exitEditMode
      }}
    >
      {children}
    </EditModeContext.Provider>
  );
};
