import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;
