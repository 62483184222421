import React, { memo, useEffect, useState } from "react";
import CardBody from "../../Components/card";
import { KpiContainer, Container, Table, Legend as LegendTable } from "./style";
import { format } from "date-fns";
import useUser from "../../hooks/useUser";
import axios from "axios";
import AnalogClock from "react-analogue-clock";
import Col from "../../Components/column";
import Row from "../../Components/row";
import {
  IFactoryTreatmentDat,
  IPainelOficinaData,
  IQuadroClassesManutencao,
} from "./interface";
import GaugeChart from "react-gauge-chart";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { Label } from "reactstrap";
import { groupBy, orderBy, sumBy, toNumber } from "lodash";
import useSWR from "swr";
import  useTranslate  from "../../hooks/useTranslate";
const ColheitaTalhao: React.FC = () => {
  const [data, setData] = useState<IPainelOficinaData>();
  const { userInformation } = useUser();
  const [actualHour, setActualHour] = useState("");
  const [totalValue, setTotalValue] = useState<number>(0);
  const [totalOsAberto, setTotalOsAberto] = useState<any>();
  const hour = (date: Date) => format(date, "HH:mm:ss");
  const {translate} = useTranslate()
  const clockOptions = {
    baseColor: "#ffffff",
    borderColor: "#000000",
    borderWidth: 5,
    centerColor: "#000000",
    handColors: {
      hour: "#000000",
      minute: "#000000",
      second: "#000000",
    },
    notchColor: "#000000",
    numbersColor: "#000000",
    showNumbers: false,
    size: 300,
  };
  const swr = useSWR(
    userInformation.UrlApiFarm4all &&
      `${userInformation.UrlApiFarm4all}agricola/sig/painelOficina`,
    (url) => fetchData(url),
    { refreshInterval: 3600000, revalidateOnFocus: false }
  );
  const fetchData = async (url: string) => {
    const { data: dataInformation } = await axios.get(url, {
      auth: {
        username: userInformation.UsuarioApiFarm4all,
        password: userInformation.SenhaApiFarm4all,
      },
    });
    const groupByDscMotivoOsParada = groupBy(
      dataInformation.QuadroOSAberto,
      "DscMotivoOSParada"
    );
    setTotalOsAberto(
      groupByDscMotivoOsParada?.[translate("aguardando peca")]?.length || 0
    );
    setTotalValue(dataInformation.QuadroOSAberto.length);
    setData(dataInformation);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const hourUpdated = hour(new Date());
      setActualHour(hourUpdated);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatData = (): IFactoryTreatmentDat => {
    const dataFinaly: any[] = [];
    data?.QuadroClassesManutencao.map((item: IQuadroClassesManutencao) => {
      
      const total = sumBy(data.QuadroClassesManutencao, translate("quantidade"));
      const dataTreatment = {
        name: item.DscClasse,
        value: ((item.Quantidade / total) * 100).toFixed(2),
      };

      return dataFinaly.push(dataTreatment);
    });

    const groupAguardandoPecas = groupBy(
      data?.QuadroOSAberto,
      "DscMotivoOSParada"
    );
    const totalAguardandoPecas = groupAguardandoPecas["Aguardando Peça"];
    const dataOsAberto =
      data?.QuadroOSAberto.length && toNumber(data?.QuadroOSAberto.length) / 30;
    return {
      data: dataFinaly,
      dataAguarPecas: toNumber(totalAguardandoPecas?.length) / 30,
      dataOsAberto,
    };
  };
  const renderCustomBarLabel = (props:any) => {
    const { x, width, y, payload } = props;
    
    return <text x={x} y={y + 20} fill="#666" textAnchor="middle" dy={-6} style={{ fontSize: '10px' }}>{`${payload.value}`}</text>;
  };

  return (
    <>
      <Container>
        <Col>
          <Row style={{ gap: "12px" }}>
            <CardBody
              style={{ marginTop: "20px" }}
              title={translate("quadro por classe de manutencao")}
            >
              <small style={{ margin: "0 auto" }}>
              {translate("quadro por classe de manutencao")}
              </small>
              <ResponsiveContainer maxHeight={300}>
                <BarChart data={formatData().data}>
                  <CartesianGrid strokeDasharray="3 3" visibility="hidden" />
                  <XAxis dataKey="name" />
                  <YAxis padding={{ top: 50 }} hide={true} />
                  <Tooltip formatter={(e) => ` ${e.toLocaleString()} %`} />
                  <Bar
                    label={{
                      color: "black",
                      fontSize: 15,
                      position: "top",
                      formatter: (e) => ` ${e.toLocaleString()} %`,
                    }}
                    maxBarSize={80}
                    dataKey="value"
                    name={translate("quantidade")}
                    background={{ fill: "#eee" }}
                    fill="#8884d8"
                  >
                    <Label />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardBody>
            <CardBody
              style={{ flexBasis: "800px", marginTop: "20px" }}
              title={translate("analise abertas x encerradas nos ultimos 15 dias")}
            >
              <small style={{ margin: "0 auto" }}>
              {translate("analise abertas x encerradas nos ultimos 15 dias")}
              </small>
              <ResponsiveContainer maxHeight={300}>
                <BarChart
                  data={[
                    {
                      name: translate("abertos"),
                      value: data?.QuantidadeOSAbertasUltimos15Dias,
                    },
                    {
                      name: translate("encerrados"),
                      value: data?.QuantidadeOSEncerradasUltimos15Dias,
                    },
                  ]}
                >
                  <CartesianGrid strokeDasharray="3 3" visibility="hidden" />
                  <XAxis  dataKey="name" />
                  <Tooltip />
                  <Bar
                    label={{
                      color: "black",
                      fontSize: 15,
                      position: "center",
                    }}
                    maxBarSize={80}
                    background={{ fill: "#eee" }}
                    dataKey="value"
                    fill="#8884d8"
                  >
                    <Label />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardBody>
          </Row>
          <Row style={{ marginTop: "15px", gap: "15px" }}>
            <CardBody
              style={{ flexBasis: "1050px" }}
              title={translate("manutecao por sistema veicular")}
            >
              <small style={{ margin: "0 auto" }}>
                {translate("manutecao por sistema veicular")}
              </small>
              <ResponsiveContainer maxHeight={300}>
                <BarChart data={orderBy(data?.QuadroSistemasVeiculares)}>
                  <CartesianGrid strokeDasharray="3 3" visibility="hidden" />
                  <XAxis tick={renderCustomBarLabel}  interval={0} dataKey="DscSistema" />
                  <YAxis padding={{ top: 50 }} hide={true} />
                  <Tooltip
                    formatter={(e) =>
                      ` ${toNumber(e).toFixed(2).toLocaleString()} %`
                    }
                  />
                  <Bar
                    label={{
                      color: "black",
                      fontSize: 12,
                      position: "top",
                      formatter: (e) =>
                        ` ${toNumber(e).toFixed(2).toLocaleString()} %`,
                    }}
                    dataKey={translate("percentual")}
                    maxBarSize={80}
                    name={translate("% quantidade")}
                    background={{ fill: "#eee" }}
                    fill="#8884d8"
                  >
                    <Label />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardBody>
            <CardBody
              style={{
                alignItems: "center",
                alignContent: "center",
                flexBasis: "50px",
              }}
            >
              <h1 className="hourInformation">
                <strong>{format(new Date(), "dd/MM/yyyy")}</strong> {actualHour}
              </h1>
              <br />
              <br />
              <AnalogClock {...clockOptions} />
            </CardBody>
            <CardBody style={{ flexBasis: "50px", alignItems: "center" }}>
              <small style={{ margin: "0 auto" }}> {translate("o.s aguardando pecas")} </small>
              <GaugeChart
                arcPadding={0}
                formatTextValue={(e) => e}
                id="gauge-chart1"
                nrOfLevels={3}
                cornerRadius={0}
                animate={false}
                percent={toNumber(totalOsAberto) / 100}
                textColor="#000"
                needleColor="#345243"
              />
              <small style={{ margin: "0 auto" }}> {translate("o.s em aberto")} </small>
              <GaugeChart
                formatTextValue={(e) => e}
                arcPadding={0}
                cornerRadius={0}
                animate={false}
                id="gauge-chart2"
                percent={totalValue / 100}
                nrOfLevels={3}
                textColor="#000"
                needleColor="#345243"
              />
            </CardBody>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <CardBody
              style={{ maxHeight: "500px" }}
              title={translate("quadro de o.s abertas")}
            >
              <div
                className="scroll"
                style={{
                  maxHeight: "350px",
                  overflow: "scroll",
                  scrollbarWidth: "thin",
                }}
              >
                <Table>
                  <thead>
                    <tr>
                      <th>{translate("numero o.s")}</th>
                      <th>{translate("data entrada o.s")}</th>
                      <th>{translate("fazenda")}</th>
                      <th>{translate("equipamento")}</th>
                      <th>{translate("mecanico responsavel")}</th>
                      <th>{translate("motivo o.s parada")}</th>
                      <th>{translate("data")}</th>
                      <th>{translate("hora")}</th>
                      <th>{translate("dias parados")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.QuadroOSAberto.map((os) => {
                      const {} = os;
                      return (
                        <tr>
                          <td>{os.OrdemServicoOficinaId}</td>
                          <td>
                            {format(new Date(os.DtEntrada), "dd/MM/yyyy")}
                          </td>
                          <td>{os.CodFazenda}</td>
                          <td>{os.CodEquipamento}</td>
                          <td>{os.NomeMecanicoResponsavel}</td>
                          <td>{os.DscMotivoOSParada}</td>
                          <td align={!os.DtParada ? "center" : "left"}>
                            {os.DtParada
                              ? format(new Date(os.DtParada), "dd/MM/yyyy")
                              : "-"}
                          </td>
                          <td>{os.HoraParada}</td>
                          <td>{os.DiasParados}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Row>
        </Col>
      </Container>
      <br />
    </>
  );
};

export default memo(ColheitaTalhao);
