import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const warnn = (text:string) =>
  toast.error(text ? text : "Erro, preencha o formulario corretamente! ");
export const success = (text:string) =>
  toast.success(text ? text : "Nova empresa cadastrada com sucesso! ");
export const info = (text:string, disableAutoClose?:boolean) =>
  toast.info( text ? text : "Nova empresa cadastrada com sucesso!", { autoClose:disableAutoClose ? false : 5000 ,  } );
export const dismiss = () => toast.dismiss();
