import React, { memo, useCallback, useContext, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { Item, Icon, GlobalStyle } from "./styled";
import { EditModeContext } from "../../contexts/editmode.context";
import componentsImport from "../../utils/ImportConponents";
import useDropableZone from "../../hooks/useDropableZone";
import useSelectedIndicator from "../../hooks/useSelectedIndicator";
import useTranslate from "../../hooks/useTranslate";
import ReactTooltip from "react-tooltip";
import { AiOutlineClose, AiOutlineCopy } from "react-icons/ai";

interface Ilayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  id: string;
  title: string;
}
const DashboardContent: React.FC<any> = () => {
  const [isDragging, setDragging] = useState(false);
  const { selectedItem } = useSelectedIndicator();
  const { translate } = useTranslate();
  const ResponsiveReactGridLayout = WidthProvider(RGL);
  const availableHandles: any[] = ["s", "w", "e", "n", "sw", "nw", "se", "ne"];
  const { editMode } = useContext(EditModeContext);
  const { onDrop, layout, updateLayout, closeComponent, updateTitle } =
    useDropableZone();
  return (
    <>
      <GlobalStyle />
      <ResponsiveReactGridLayout
        isBounded={true}
        isDroppable={editMode}
        isResizable={editMode}
        isDraggable={editMode}
        draggableHandle=".draggableHandle"
        style={{
          minHeight: "90vh",
          marginBottom: "100px",
          transition: "none !important",
          background: editMode ? "rgb(0 0 0 / 2%)" : "",
        }}
        className="layout"
        layout={layout}
        onDragStart={() => setDragging(true)}
        measureBeforeMount={false}
        cols={20}
        resizeHandles={availableHandles}
        rowHeight={50}
        onResizeStop={(e) => updateLayout(e)}
        onDragStop={(e) => (updateLayout(e), setDragging(false))}
        onDrop={onDrop}
      >
        {layout?.map((item: Ilayout, index: number) => {
          return (
            <Item
              dragging={isDragging}
              id="item"
              selectedItem={item?.id === selectedItem?.id}
              onClick={() => {
                if (item?.id != selectedItem?.id) {
                }
              }}
              editMode={editMode}
              key={`${item?.i}|${item?.id}|${item?.title}`}
              data-grid={{
                h: item?.h,
                w: item?.w,
                x: item?.x,
                y: item?.y,
                // static: item?.id !== selectedItem?.id,
              }}
            >
              {editMode && (
                <>
                  <ReactTooltip
                    id={`closeIndicatorButtom${item.id}`}
                    place="bottom"
                    className="zIndexOffAll"
                    type="dark"
                    effect="solid"
                    key={`${item.i}546`}
                  />
                  <Icon
                    distance="10px"
                    data-for={`closeIndicatorButtom${item.id}`}
                    data-tip={translate("remover indicador")}
                    data-iscapture="true"
                    onClick={() => closeComponent(item.id)}
                  >
                    <AiOutlineClose style={{ pointerEvents: "none" }} />
                  </Icon>
                </>
              )}
              {componentsImport[item.i]?.component({
                id: item.id,
                nameComponent: item.i,
                defineTitle(e: string) {
                  updateTitle(layout, e, item.id);
                },
                customTitle: item.title != "undefined" && item.title,
              })}
            </Item>
          );
        })}
      </ResponsiveReactGridLayout>
    </>
  );
};

export default memo(DashboardContent);
