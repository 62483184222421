import React from "react";
import ReactTooltip from "react-tooltip";
import { Item } from "./style";
interface IProps {
  name: string;
  url: string;
  active: boolean;
  hide?: boolean;
}

const Link: React.FC<IProps> = ({ children, name, url, active, hide }) => {
  return (
    <>
      <Item
        hide={hide}
        data-for={url}
        data-tip={name}
        data-iscapture="true"
        to={url}
        isEnable={active}
      >
        {children}
        {hide && <small>{name}</small>}
      </Item>
      {!hide && (
        <ReactTooltip
          id={url}
          place="right"
          type="dark"
          effect="solid"
          multiline={true}
        />
      )}
    </>
  );
};
export default Link;
