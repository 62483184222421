import { useCallback, useEffect, useState } from "react";
import { Layout } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";

const usePeriodoProducao = () => {
  const periodoProducao:any = useSelector<any | any>(({ periodoProducao }) => periodoProducao);
  const dispatch = useDispatch();

  const setPeriodoProducao = (data: any) => {
    dispatch({
      type: "SET_PERIODO_PRODUCAO",
      payload: data,
    });
  };
  return { setPeriodoProducao, periodoProducao };
};

export default usePeriodoProducao;
