//#region
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, Redirect, useRouteMatch } from "react-router-dom";
import { servicoInternoApi as api, servicoInternoApi } from "../../services";
import SidebarReports from "../../Components/sidebarDashboardReports";
// import FilterApply from "../../Components/applyFilter";
import DashboardContent from "../../Components/content";
import { EditModeContext } from "../../contexts/editmode.context";
import { Title, Button, ContainerButton } from "./style";
import "../../styles/style.css";
import {
  AiOutlineClear,
  AiOutlineEdit,
  AiOutlineSelect,
  AiOutlineSave,
} from "react-icons/ai";
import useSelectedIndicator from "../../hooks/useSelectedIndicator";
import { ChangeTitle } from "../../Components/changeTextInput";
import { toPng } from "html-to-image";
import { translate } from "../../utils/translate";
import useTranslate from "../../hooks/useTranslate";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import IUser from "../../store/modules/user/interface";
import { warnn, info, success, dismiss } from "../../services/toasty";
import Filter from "../../Components/filter";
import ReactTooltip from "react-tooltip";
//#endregion
interface IRouteParams {
  id?: string;
}
interface Ilayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}
interface IDashboardUser {
  user: IUser;
}
interface IDashboard {
  Id: any;
  Layout: any;
  Subtitulo: string;
  Titulo: string;
}
interface IData {
  dashboardSelected: {
    layout: Ilayout;
  };
}

const DashboardManager: React.FC = () => {
  const data = useSelector<IDashboardUser, any>((state) => state.user);
  const { language } = useSelector<any, any>((props) => props);

  const dataShared = useSelector<IData, any>(
    ({ dashboardSelected }) => dashboardSelected
  );
  const { selectedItem, handleRemoveSelection } = useSelectedIndicator();
  const { params } = useRouteMatch<IRouteParams>();
  const { editMode, editModeChange, exitEditMode } = useContext(
    EditModeContext
  );
  const [information, setInformation] = useState<IDashboard>();
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.Usuario) {
      handleData();
    }
  }, [data.Usuario]);
  const handleData = useCallback(async () => {
    info(translate(language.language, "carregando dashboard, aguarde"));

    try {
      let informations = await servicoInternoApi.get(
        `getDashboard?UsuarioSigId=${data.Usuario}&DashboardId=${params.id}`
      );
      if (informations) {
        dispatch({
          type: "SET_DASHBOARD_LAYOUT",
          payload: informations.data.Layout
            ? JSON.parse(informations.data.Layout)
            : [],
        });
        setInformation(informations.data);
        dismiss();
      }
    } catch (error) {
      warnn(error);
    }
  }, [data.Usuario]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Titulo: information?.Titulo,
      Subtitulo: information?.Subtitulo,
    },
    onSubmit: () => {},
  });
  const { values, setFieldValue } = formik;
  const updateDashboard = useCallback(async () => {
    info(translate(language.language, "salvando dashboard, aguarde"));
    try {
      const updateDataInApi = await servicoInternoApi.post(
        `atualizarDashboard`,
        {
          DashboardId: params.id,
          Layout: JSON.stringify(dataShared.layout),
          Titulo: values?.Titulo,
          Subtitulo: values.Subtitulo,
          UsuarioSigId: data.Usuario,
        }
      );
      if (updateDataInApi) {
        dispatch({
          type: "UPDATE_DASHBOARD",
          payload: {
            DashboardId: params.id,
            Layout: JSON.stringify(dataShared.layout),
            Titulo: values?.Titulo,
            Subtitulo: values.Subtitulo,
            UsuarioSigId: data.Usuario,
          },
        });
        dismiss();
        success(translate(language.language, "dashboard salvo"));
      }
    } catch (error) {
      dismiss();
      warnn(error);
    }
  }, [dataShared, values]);
  const generateReportPdf = useCallback(() => {
    var node = document.getElementById("pdReport");
    node &&
      toPng(node).then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "my-image-name.jpeg";
        link.href = dataUrl;
        link.click();
      });
  }, []);

  if (redirect) {
    return <Redirect to={`/home`} />;
  }
  return (
    <>
      <div id="pdReport">
        <Title
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Link
              to="/home-dashboard"
              onClick={exitEditMode}
              style={{ maxWidth: "130px" }}
            >
              {translate(language.language, "voltar")}
            </Link>
            <ChangeTitle
              editMode={editMode}
              name="Titulo"
              value={values.Titulo}
              id={params?.id}
              isTitle={true}
              settingNewValueUpdated={(e) => setFieldValue("Titulo", e)}
            />
            <ChangeTitle
              editMode={editMode}
              name="Subtitulo"
              subtitle={true}
              value={values.Subtitulo}
              id={params?.id}
              settingNewValueUpdated={(e) => setFieldValue("Subtitulo", e)}
            />
          </div>
          <ContainerButton>
            <Button
              data-for="editButton"
              data-tip={!editMode ? translate(language.language, "entrar em modo edicao") : translate(language.language, "sair do modo edicao")}
              data-iscapture="true"
              type="button"
              editMode={!editMode}
              onClick={(e) => (editModeChange(), editMode && updateDashboard())}
            >
              {editMode ? (
                <AiOutlineSelect style={{ pointerEvents: "none" }} />
              ) : (
                <AiOutlineEdit style={{ pointerEvents: "none" }} />
              )}
            </Button>
            {!!selectedItem.id && (
              <>
                <Button
                  data-for="clearSelect"
                  data-tip={translate(language.language, "limpar selecao")}
                  data-iscapture="true"
                  type="button"
                  onClick={handleRemoveSelection}
                >
                  <AiOutlineClear style={{ pointerEvents: "none" }} />
                </Button>
                <ReactTooltip
                  id="clearSelect"
                  place="bottom"
                  type="dark"
                  effect="solid"
                  multiline={true}
                />
              </>
            )}
          </ContainerButton>
        </Title>
        {/* <FilterApply />*/}
        <SidebarReports editMode={editMode} />
        <DashboardContent />
        {/* {!editMode && <Filter />} */}
      </div>

      <ReactTooltip
        id="editButton"
        place="bottom"
        type="dark"
        effect="solid"
        multiline={true}
      />
      <ReactTooltip
        id="saveButton"
        place="bottom"
        type="dark"
        effect="solid"
        multiline={true}
      />
      <ReactTooltip
        id="printButton"
        place="bottom"
        type="dark"
        effect="solid"
        multiline={true}
      />
    </>
  );
};

export default DashboardManager;
