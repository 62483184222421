//#region 
import React from "react";
import IndicadoresAlgodoeira from "../../reports/indicadoresAlgodoeira";
import PageTitle from "../../Components/pageTitle";
import PageBody from "../../Components/pageBody";
import { translate } from "../../utils/translate";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { TitleContainer } from "./style";
//#endregion

const IndicadoresAlgodoeiraPage: React.FC = () => {
  const state = useSelector<any, any>(({ language }) => language);
  const date = format(new Date(), "dd/MM/yyyy HH:mm:ss");
  return (
    <>
      <TitleContainer style={{ width: "120%" }}>
        <PageTitle>
          <h1>{translate(state.language, "indicadores algodoeira")}</h1>
          <small>
            {translate(state.language, "valores referente a")} <strong>{date}</strong>
          </small>
        </PageTitle>
        {/* <ButtonToTv style={{ marginLeft: '95%' }} link="indicadoresAgricola/tv" /> */}

        {/* <Modal /> */}
      </TitleContainer>
      <PageBody isFullWidth={true}>
         <IndicadoresAlgodoeira /> 
      </PageBody>
    </>
  );
};

export default IndicadoresAlgodoeiraPage;
