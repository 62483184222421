//#region imports
import React, { memo } from "react";
import IndicadoresAno from "./indicadoresAno";
import EntradasSemanas from "./entradasSemanas";
import FluxoAnimaisAcumuladoAno from "./fluxoAnimaisAcumuladoAno";
import AnimaisRebanho from "./animaisRebanho";
import ArrobaNegociada from "./arrobaNegociada";
import AnimaisPesoAbate from "./animaisPesoAbate";
import Mortalidade from "./mortalidade7Dias";
import Row from "../../Components/row";
//#endregion

const Pecuaria: React.FC = () => (
  <>
    <Row style={{ flexWrap: "wrap" }}>
      <IndicadoresAno />
      <FluxoAnimaisAcumuladoAno style={{ marginTop: "20px" }} />
      <ArrobaNegociada style={{ marginTop: "20px" }} />
      <EntradasSemanas style={{ marginTop: "20px" }} />
      <AnimaisRebanho style={{ marginTop: "20px" }} />
      <AnimaisPesoAbate style={{ marginTop: "20px" }} />
      <Mortalidade style={{ marginTop: "20px" }} />
    </Row>
  </>
);

export default memo(Pecuaria);
