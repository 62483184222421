import { Reducer } from "redux";
import produce from "immer";

const INITIAL_STATE = {};
const farm: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_COMPARATIVOSAFRA": {
      return produce(state, (draft: any) => {
        return (draft = { ...draft, [action.payload.id]: action.payload.body });
      });
    }
    case "REMOVE_COMPARATIVOSAFRA": {
      return produce(state, (draft: any) => {
        delete draft[action.payload.id];
      });
    }
    default: {
      return state;
    }
  }
};

export default farm;
