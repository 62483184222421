import bau from "./json/bau.json";
import jcn from "./json/jcn.json";
import alfacitrus from "./json/alfacitrus.json";
import flora from "./json/flora.json";
import antaris from "./json/Antaris.json";
import fazendaPF from "./json/Fazenda_PF.json";

export default {
  "C00021": bau,
  "C00051": jcn,
  "C00071": alfacitrus,
  "C00318": flora,
  "C03150": antaris,
  "C01878": fazendaPF,
  "C00017": bau
};
/*  Código é o CardCode do cliente */