import React, { CSSProperties, memo } from "react";
import CardBody from "../../../Components/card";
import { Table } from "../../../styles/globalComponents";
import { IEntradaSemana } from "../interface";
import useUser from "../../../hooks/useUser";
import useTranslate from "../../../hooks/useTranslate";
import axios from "axios";
import useSWR from "swr";
interface GenericFunctionType {
  <T>(url: string): Promise<T>;
}
interface IProps {
  style?: CSSProperties;
}
const Pecuaria: React.FC<IProps> = ({ style }) => {
  const { userInformation } = useUser();
  const {translate} = useTranslate()
  const { data: entradasSemana } = useSWR(
    userInformation.UrlApiFarm4all &&
      `${userInformation.UrlApiFarm4all}/pecuaria/sig/indicadores/entradasSemana`,
    (url) => fetchData<IEntradaSemana[]>(url)
  );
  const fetchData: GenericFunctionType = async (url: string) => {
    const { data } = await axios.get(url, {
      auth: {
        username: userInformation.UsuarioApiFarm4all,
        password: userInformation.SenhaApiFarm4all,
      },
    });
    return data;
  };
  return (
    <CardBody style={{ maxHeight: "500px" }} title={translate("entradas na semana")}>
      <div
        className="scroll"
        style={{
          overflow: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <Table>
          <thead>
            <tr>
              <th>{translate("tipo")}</th>
              <th>{translate("registro de negociacao")}</th>
              <th>{translate("gtas")}</th>
              <th>{translate("desembarques")}</th>
              <th>{translate("manejos de entrada")}</th>
              <th>{translate("entrada gado geral")}</th>
            </tr>
          </thead>
          <tbody>
            {entradasSemana?.map((props) => {
              return (
                <tr>
                  <td>{props.Tipo}</td>
                  <td>{props["Registro de Negociação"]}</td>
                  <td>{props.GTAs}</td>
                  <td>{props.Desembarques}</td>
                  <td>{props["Manejos de Entrada"]}</td>
                  <td>{props["Entrada Gado Geral"]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </CardBody>
  );
};

export default memo(Pecuaria);
