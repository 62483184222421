import { useSelector } from "react-redux";
import { translate as Translated } from "../utils/translate";

const useTranslate = () => {
  const state = useSelector<any, any>(({ language }) => language);
  
  const translate = (text:string) => Translated(state.language, text)
  
  return { translate };
};
export default useTranslate;
