import styled, { css } from "styled-components";
import theme from "../../styles/theme";

export const FilterContainer = styled.section`
  width: 309px;
  top: 0;
  border-radius: 0px !important;
  padding: 30px 15px;
  right: 0px;
  height: 110vh;
  border-radius: 11px;
  position: fixed;
  background: #2f3243;
  small {
    color: #fff;
    margin-bottom: 2px;
  }
  div {
    margin-top: 4px;
  }
  @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
    position: static;
    max-width: 97%;
  }
  @media only screen and (max-width: ${theme.ligth.mediumDimension}) {
    position: static;
    width: 100%;
  }
`;
interface IUnselectIndicator {
  hover?: boolean;
}
export const UnselectIndicator = styled.button<IUnselectIndicator>`
  width: 100%;
  padding: 15px 13px;
  background-color: #1e1f29;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  transition: 0.5s;
  text-align: left;
  display: flex;
  justify-content: left;
  font-size: 13px;
  border: none;
  outline: none;
  ${({ hover }) =>
    hover &&
    css`
      background: #6a678e;
      border: 0.5px solid #6a678e;
      color: #ffff;
    `}
  cursor: pointer;
  span {
    margin-left: 10px;
  }
`;
export const Header = styled.div`
  margin-bottom: 32px;
  p {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    font-weight: 500;
  }
  small {
    color: #fff;
    font-weight: 400;
  }
`;
