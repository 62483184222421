//#region
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../../styles/style.css";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import PageBody from "../../Components/pageBody";
import { translate } from "../../utils/translate";
import HomeDashboard from "../../reports/home";
import Filter from "../../Components/filter";
import useFilter from "../../hooks/useFilter";
import useTranslate from "../../hooks/useTranslate";
import Sipec from '../../reports/pecuaria';
import EntradasSemanaStackedBar from '../../reports/pecuaria/entradasNaSemanaStackedBar';
import Mortalidade from '../../reports/pecuaria/mortalidade7DiasBarGraph'
const Home: React.FC = (props: any) => {
  const { language } = useSelector<any, any>((props) => props);
  const { filter } = useFilter();
  const { translate } = useTranslate();
  const filterApp = filter?.filtersApply?.CodSafra?.title;

  return (
    <>
      <PageTitle>
        <h1>Dashboard {translate("pecuaria")}</h1>
        <small>dashboard {translate("pecuaria")} </small>
      </PageTitle>
      <PageBody>
        <Mortalidade />
        <EntradasSemanaStackedBar />
      </PageBody>
    </>
  );
};

export default Home;
