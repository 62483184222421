import React from "react";
import styled from "styled-components";

export const Icon = styled.div`
  z-index: 99999;
  cursor: pointer;
  margin-top: 0px !important;
`;
// export const InputAutoComplet = styled.input`
//     width: 100%;
//     border: none;
//     font-size: 14px;
//     color: #fff;
//     cursor: n-resize;
//     padding: 14px 30px;
//     -webkit-text-decoration: none;
//     text-decoration: none;
//     background-color: #00000059;
//     outline: none;
// `;