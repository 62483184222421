import React from "react";
import SideMenu from "../../Components/sideMenu";
import ComparativoSafraComponent from "../../reports/comparativoSafra";
import Filter from "../../Components/filter";
import PageTitle from "../../Components/pageTitle";
import PageBody from "../../Components/pageBody";
import useTranslate from "../../hooks/useTranslate";

// import { translate } from "../../utils/translate";
import { useSelector } from "react-redux";
const ComparativoSafra: React.FC = () => {
  const state = useSelector<any, any>(({ language }) => language);
  const { translate } = useTranslate();

  return (
    <>
      <PageTitle>
        <h1>{translate("Comparativo Safra")}</h1>
        <small>
          {translate("visualização comparativo safra")}
        </small>
      </PageTitle>
      <PageBody>
        <Filter
          addDinamycFilters={[
            { name: "CodSafra", label: translate("safra") },
            { name: "CodPeriodoProducao", label: translate("periodo producao") },
            { name: "CodFazenda", label: translate("fazenda") },
          ]}
          filters={[]}
        />
        <ComparativoSafraComponent />
      </PageBody>
    </>
  );
};

export default ComparativoSafra;
