import React, { CSSProperties, memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import { IAnimaisPesoAbate, IMortalidade7Dias } from "../interface";
import useUser from "../../../hooks/useUser";
import useTranslate from "../../../hooks/useTranslate";
import axios from "axios";
import useSWR from "swr";
import { countBy, groupBy } from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Label,
} from "recharts";
interface IProps {
  style?: CSSProperties;
  dto?: any;
}
const Mortalidade7DiasGraphBar: React.FC<IProps> = ({ style, dto }) => {
  const { userInformation } = useUser();
  const { translate } = useTranslate();
  const { data } = useSWR(
    userInformation.UrlApiFarm4all &&
      `${userInformation.UrlApiFarm4all}/pecuaria/sig/indicadores/mortalidade7Dias`,
    (url) => fetchData(url)
  );
  const fetchData: any = async (url: string) => {
    const { data } = await axios.get(url, {
      auth: {
        username: userInformation.UsuarioApiFarm4all,
        password: userInformation.SenhaApiFarm4all,
      },
    });
    const countedData = countBy(data, "DscCausaMorte");
    const dataReturn = Object.keys(countedData);
    const dataTrated = dataReturn.map((name: any) => {
      const value = countedData[name];
      return { name, value };
    });
    return dataTrated;
  };
  return (
    <CardBody
      style={{ height: "400px" }}
      returnTitleUpdated={(e) => dto.defineTitle(e)}
      title={dto?.customTitle || translate("mortalidade ultimos 7 dias")}
    >
      <ResponsiveContainer maxHeight={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" visibility="hidden" />
          <XAxis dataKey="name" />
          <Tooltip />
          <Bar
            label={{
              color: "black",
              fontSize: 15,
              position: "center",
            }}
            maxBarSize={80}
            background={{ fill: "#eee" }}
            dataKey="value"
            fill="#8884d8"
          >
            <Label />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </CardBody>
  );
};

export default memo(Mortalidade7DiasGraphBar);
