import React, { CSSProperties, memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import { Kpi, Table } from "../../../styles/globalComponents";
import { IAnimaisPesoAbate, IMortalidade7Dias } from "../interface";
import useUser from "../../../hooks/useUser";
import useTranslate from "../../../hooks/useTranslate";
import axios from "axios";
import useSWR from "swr";
interface GenericFunctionType {
  <T>(url: string): Promise<T>;
}
interface IProps {
  style?: CSSProperties;
  dto?: any;
}
const Mortalidade7Dias: React.FC<IProps> = ({ style, dto }) => {
  const { userInformation } = useUser();
  const { translate } = useTranslate();
  const { data } = useSWR(
    userInformation.UrlApiFarm4all &&
      `${userInformation.UrlApiFarm4all}/pecuaria/sig/indicadores/mortalidade7Dias`,
    (url) => fetchData<IMortalidade7Dias[]>(url)
  );
  const fetchData: GenericFunctionType = async (url: string) => {
    const { data } = await axios.get(url, {
      auth: {
        username: userInformation.UsuarioApiFarm4all,
        password: userInformation.SenhaApiFarm4all,
      },
    });
    return data;
  };
  return (
    <CardBody
      returnTitleUpdated={(e) => dto.defineTitle(e)}
      style={style || { marginTop: "0", justifyContent: "stretch" }}
      title={dto?.customTitle || translate("mortalidade ultimos 7 dias")}
    >
      <div
        className="scroll"
        style={{
          overflow: "scroll",
          maxHeight: "400px",
          scrollbarWidth: "thin",
        }}
      >
        <Table>
          <thead>
            <tr>
              <th>{translate("lote")}</th>
              <th>{translate("curral/pasto")}</th>
              <th>{translate("categoria")}</th>
              <th>{translate("data morte")}</th>
              <th>{translate("causa")}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(
              ({ CodLocal, DscCategoria, DscCausaMorte, DtMorte, Lote }) => {
                return (
                  <tr>
                    <td>{Lote}</td>
                    <td>{CodLocal}</td>
                    <td>{DscCategoria}</td>
                    <td>{DtMorte}</td>
                    <td>{DscCausaMorte}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </div>
    </CardBody>
  );
};

export default memo(Mortalidade7Dias);
