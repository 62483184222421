import styled, { css } from "styled-components";
import { BsX, BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";
import { AiOutlineFundView } from "react-icons/ai";
interface Items {
  first?: Boolean;
  imgBackground?: string;
}
interface IButton {
  editMode?: boolean;
  saveDashboard?: boolean;
  disabled?: boolean;
}
export const CloseIcon = styled(BsX)`
  padding: 10px;
  z-index: 88888;
  box-sizing: initial;
  color: #000;

  transition: 0.5s;
  &:hover {
    color: white;
    background-color: #2f3243;
    transition: 0.5s;
    border-radius: 100px;
  }
`;
export const ViewIcon = styled(AiOutlineFundView)`
  padding: 10px;
  z-index: 88888;
  box-sizing: initial;
  color: #000;

  transition: 0.5s;
  &:hover {
    color: white;
    background-color: #2f3243;
    transition: 0.5s;
    border-radius: 100px;
  }
`;
export const ArrowIcon = styled(BsArrowRight)`
  padding: 10px;
  z-index: 88888;
  box-sizing: initial;
  color: #000;

  transition: 0.5s;
  &:hover {
    color: blue;
    background-color: #00000008;
    transition: 0.5s;
    border-radius: 100px;
  }
`;

export const ContainerButton = styled.div`
  position: absolute;
  top: 35px;
  right: 10%;
  justify-content: space-between;
  flex-wrap: nowrap;
`;
export const Button = styled(motion.button)<IButton>`
  padding: 10px 35px;
  border: none;
  border-radius: 4px;
  outline: none;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 4;
  display: ${({ disabled }) => (disabled ? "none" : "")};
  &:hover {
    background-color: #2f3243;
    color: #fff;
  }
  ${({ editMode }) =>
    !editMode &&
    css`
      background-color: #2f3243;
      color: #fff;
    `};
  ${({ saveDashboard }) =>
    saveDashboard &&
    css`
      &:hover {
        background-color: #b8ff9f;
        color: black;
      }
    `}
`;
export const Item = styled(motion.div)<Items>`
  list-style: none;
  height: auto;
  margin-right: 30px;
  margin-top: 30px;
  width: 210px;
  overflow: visible;
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  min-height: 150px;
  background: #f5f5f5;
  cursor: pointer;
  pointer-events: all;
  border-radius: 5px;
  padding: 20px;
  z-index: 10005;
  transition: 0.5s;
  &:hover {
    box-shadow: #00000029 0px 0px 40px;
    transition: 0.5s;
    /* transform: scale(1.05); */
  }
  ${(props) =>
    props.imgBackground &&
    css`
      background-image: url(${props.imgBackground});
      background-size: cover;
    `}
  ${(props) =>
    props.first &&
    css`
      background: #679bff1f;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
    `};
  section {
    margin-top: auto;
  }
  #dataInformation {
    display: flex;
    height: 20px;
    width: 100%;
    align-items: center;
    p {
      margin-left: 10px;
    }
  }
  h1 {
    font-size: 13px;
    font-family: "Roboto";
    color: #000;
    text-transform: uppercase;
    font-weight: 500;
    max-width: 210px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p {
    font-size: 11px;
    text-transform: lowercase;
    margin-top: 4px;
    font-family: "Roboto";
    color: #000;
    max-width: 210px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
export const Legend = styled.div`
  margin-top: -95%;
`;
export const Title = styled.div`
  margin-top: 30px;
  font-family: "Roboto";
  h1 {
    font-size: 26px;
    font-weight: 500;
    margin-top: 14px;
  }
  a {
    text-decoration: none;
    font-size: 12px;
    padding: 5px 40px;
    background: #2f3243;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
  }
`;
export const ListItems = styled.div`
  flex-wrap: wrap;
  display: flex;
`;
export const ItemGroup = styled.div``;
