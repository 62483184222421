import axios from "axios";
import useSector from "./useSector";
import useUser from "./useUser";
import useFarm from "./useFarm";
import usetalhao from "./useTalhao";
import usePeriodoProducao from "./usePeriodoProducao";

const useFetch = () => {
  const { userInformation } = useUser();
  const { setFarm } = useFarm();
  const { setSector } = useSector();
  const { setTalhao } = usetalhao();
  const { setPeriodoProducao } = usePeriodoProducao();

  const fetchFarm = async () => {
    const { data } = await axios.post(
      `${userInformation.UrlApiFarm4all}agricola/fazenda/get`,
      {},
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setFarm(data);
  };
  const fetchSector = async () => {
    const { data } = await axios.post(
      `${userInformation.UrlApiFarm4all}agricola/setor/get`,
      {},
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setSector(data);
  };
  const fetchTalhao = async () => {
    const { data } = await axios.post(
      `${userInformation.UrlApiFarm4all}agricola/talhao/get`,
      {},
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setTalhao(data);
  };
  const fetchPeriodoProducao = async (codPeriodoProducao: string) => {
    if (codPeriodoProducao) {
      const { data } = await axios.get(
        `${userInformation.UrlApiFarm4all}agricola/periodoProducao/${codPeriodoProducao}`,
        {
          auth: {
            username: userInformation.UsuarioApiFarm4all,
            password: userInformation.SenhaApiFarm4all,
          },
        }
      );
      setPeriodoProducao(data);
    }
  };

  return { fetchFarm, fetchSector, fetchTalhao, fetchPeriodoProducao };
};

export default useFetch;
