//#region imports
import React, { useCallback, useEffect, useState } from "react";
import useFilter from "../../../hooks/useFilter";
import { BodyFilter, ButtonFilter, ListItemFilter } from "../../buttonStyle";
import {
  AiOutlineClose,
  AiOutlineDeleteRow,
  AiOutlineFieldTime,
} from "react-icons/ai";
import { Icon } from "./style";
import ReactTooltip from "react-tooltip";
import useUser from "../../../hooks/useUser";
import useTranslate from "../../../hooks/useTranslate";
import axios from "axios";
import FilterOptions from "../../../config/filters.json";
import { CSSProperties } from "styled-components";
// import { translate } from "../../../utils/translate";
import { useDispatch, useSelector } from "react-redux";

import { orderBy } from "lodash";
import { boolean } from "yup/lib/locale";
import InputComponentAutoComplet from "../inputFilterAutoComplet/index";
interface IProps {
  removeCloseButton?: boolean;
  filter: any;
  hide: boolean;
  removeSection?: () => void;
  onPress: (s: string) => void;
  id?: string;
  text?: string;
  style?: CSSProperties;
}
interface IJsonPropsFilter {
  [key: string]: {
    label: string;
    name: string;
    url: string;
    dependencies: string[];
    icon: string;
    id: string;
    description: string;
    postApi?: boolean;
    optional?: string[];
    IsMult?: boolean;
  };
}

const Filter: React.FC<IProps> = ({
  filter: F,
  hide,
  style,
  removeSection,
  removeCloseButton,
  onPress,
  id: identificator,
}) => {
  const path = window.location.pathname.substr(1);
  const filterOptions: IJsonPropsFilter = FilterOptions;
  const { language } = useSelector<any, any>((props) => props);
  const { name, label, id, description, IsMult, postApi } = filterOptions?.[F];
  
  const [selectedItem, setSelectedItem] = useState<string>();
  const { userInformation } = useUser();
  const identificatorWithId = `${F}_${identificator}`;
  const { handleAddFilter, filter, handleRemoveFilter } =
    useFilter(identificator);
  const [data, setData] = useState<any[]>([]);
  const [dataFiltered, setValueFiltered] = useState<any[]>([]);
  const {translate} = useTranslate()
  const setFilter = useCallback(
    async (i: string) => {
      
      const { cod, title, information } = JSON.parse(i);
      setSelectedItem(cod);
      handleAddFilter(name, cod, title, information);
    },
    [selectedItem, filter]
  );
  function filterData(event: any) {
    const inputValue = event.target.value;

    const result = data.filter((components) => {
      return (
        (components?.[description] &&
        components?.[description]
          .toLowerCase()
          .includes(inputValue?.toLowerCase())
          ) || (components?.[id] &&
          components?.[id]
            .toLowerCase()
            .includes(inputValue?.toLowerCase()))
            
      );
    });
    setValueFiltered(result);
  }

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation, filter]);
  async function fetchData() {
    const [ident] = identificatorWithId.split("_");
    const filterOptionsInstance = filterOptions?.[identificator ? ident : F];
    const verifyIfNeedTratamentUrl =
      filterOptionsInstance?.dependencies.length > 0;
    const verifyIfNeedOptionalUrlTratament =
      filterOptionsInstance?.optional &&
      filterOptionsInstance?.optional.length > 0;
    let urlTratament = filterOptionsInstance?.url;
    if (verifyIfNeedTratamentUrl) {
      filterOptionsInstance?.dependencies.map((key: string, index: any) => {
        urlTratament = urlTratament.replace(
          `{${index}}`,
          filter?.filtersApply?.[
            identificator ? `${key}_${identificator}` : key
          ]?.value
        );
      });
    }
    if (verifyIfNeedOptionalUrlTratament) {
      filterOptionsInstance?.optional?.map((key: string, index: any) => {
        if (!filter?.filtersApply?.[key]?.value) {
          const url = urlTratament.split("?");
          return (urlTratament = url[0]);
        }
        return (urlTratament = urlTratament.replace(
          `{${key}}`,
          filter?.filtersApply?.[key]?.value
        ));
      });
    }
    if(postApi) {
      
      const { data } = await axios.post(        
        `${userInformation.UrlApiFarm4all}${urlTratament}`,
        {},
        {
          auth: {
            username: userInformation.UsuarioApiFarm4all,
            password: userInformation.SenhaApiFarm4all,
          },
        }
      );
      
      setData(orderBy(data, ["Codigo", "Descricao"], ["desc", "desc"]));
      setValueFiltered(orderBy(data, ["Codigo", "Descricao"], ["desc", "desc"]));

    } else {
      const { data } = await axios.get(
        `${userInformation.UrlApiFarm4all}${urlTratament}`,
        {
          auth: {
            username: userInformation.UsuarioApiFarm4all,
            password: userInformation.SenhaApiFarm4all,
          },
        }
      );

      setData(orderBy(data, ["Codigo", "Descricao"], ["desc", "desc"]));
      setValueFiltered(orderBy(data, ["Codigo", "Descricao"], ["desc", "desc"]));
    }
  }
  return (
    <>
      <ButtonFilter
        style={style}
        disabled={data.length > 0 ? false : true}
        selected={
          filter?.filtersApply?.[identificator ? identificatorWithId : name]
        }
        onClick={() => onPress(F)}
      >
        <AiOutlineFieldTime />
        <span>
          {filter?.filtersApply?.[identificator ? identificatorWithId : name]
            ?.title
            ? filter?.filtersApply?.[identificator ? identificatorWithId : name]
                ?.title
            : label}
        </span>

        {filter?.filtersApply?.[identificator ? identificatorWithId : name]
          ?.title &&
          !removeCloseButton && (
            <>
              <Icon
                data-for={`remove${path}`}
                data-tip={translate("remover filtro")}
                data-iscapture="true"
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  handleRemoveFilter({
                    key: name,
                  });
                  setSelectedItem("");
                }}
              >
                <AiOutlineClose style={{ pointerEvents: "none" }} />
              </Icon>
              <ReactTooltip
                id={`remove${path}`}
                place="top"
                type="dark"
                effect="solid"
                multiline={true}
              />
            </>
          )}
        {identificator && (
          <>
            <Icon
              data-for={`remove${path}`}
              data-tip={translate("remover secao")}
              data-iscapture="true"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                // handleRemoveSection({
                //   key: name,
                // });
                // setSelectedItem("");
                removeSection && removeSection();
              }}
            >
              <AiOutlineDeleteRow style={{ pointerEvents: "none" }} />
            </Icon>
            <ReactTooltip
              id={`remove${path}`}
              place="top"
              type="dark"
              effect="solid"
              multiline={true}
            />
          </>
        )}
      </ButtonFilter>
      {hide && data.length > 0 && (
        <>
          {/* <input type="text" style="" /> */}
          {/* <InputAutoComplet type="text" placeholder="Pesquise" onChange={setAutoSelect(true)} /> */}
          <InputComponentAutoComplet
            onChange={filterData}
            type={"text"}
            placeholder={translate("Pesquise")}
          />
          
          <BodyFilter>
            {dataFiltered?.map((props) => {
              return (
                <ListItemFilter
                  onClick={(e) =>
                    setFilter(
                      JSON.stringify({
                        cod: props[id],
                        title: props[description],
                        information: props
                      })
                    )
                  }
                  disabled={selectedItem === props[id]}
                >
                  {props[id]} - {props[description]}
                </ListItemFilter>
              );
            })}
          </BodyFilter>
        </>
      )}
    </>
  );
};

export default Filter;
