//#region 
import React from "react";
import { InformationNotDataToShow } from "../.././styles/globalComponents";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import useTranslate from "../../hooks/useTranslate";

//#endregion
interface IProps {
  message?: string;
  icon?: any
}
const NoDataToShow: React.FC<IProps> = ({message, icon}) => {
  const {translate} = useTranslate()

  return (
    <InformationNotDataToShow>
      {icon || <AiOutlineQuestionCircle size="50px" />}
      <h1>{message || translate("sem visualizacao disponivel")}</h1>
    </InformationNotDataToShow>
  );
};

export default NoDataToShow;