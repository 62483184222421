import React, { useContext } from "react";
import { GlobalStyleAll } from "./styles/globalStyle";
import { AuthProvider } from "./contexts/auth.context";
import { OpenFilterProvider } from "./contexts/openFilter.context";
import { BrowserRouter, useLocation } from "react-router-dom";
import { AnimateSharedLayout } from "framer-motion";
import { Provider } from "react-redux";
import Routes from "./routes";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { EditModeProvider } from "./contexts/editmode.context";
import { TvmodeProvider } from "./contexts/tvmode";

function App() {
  return (
    <Provider store={store}>
      <OpenFilterProvider>
        <EditModeProvider>
          <TvmodeProvider>
            <PersistGate loading={null} persistor={persistor} />
            <ToastContainer />
            <GlobalStyleAll />
            <AnimateSharedLayout>
              <AuthProvider>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </AuthProvider>
            </AnimateSharedLayout>
          </TvmodeProvider>
        </EditModeProvider>
      </OpenFilterProvider>
    </Provider>
  );
}

export default App;
