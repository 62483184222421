//#region
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../../styles/style.css";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import PageBody from "../../Components/pageBody";
import { translate } from "../../utils/translate";
import HomeDashboard from "../../reports/home";
import Filter from "../../Components/filter";
import useFilter from "../../hooks/useFilter";
import Cotacao from "../../Components/currencyCot";
// import Weather from "../../Components/weather";
import Row from "../../Components/row";
import { Button, ContainerButton } from "./style";
import { Redirect } from "react-router";
import { AiOutlineForm } from "react-icons/ai";
const Home: React.FC = (props: any) => {
  const { language } = useSelector<any, any>((props) => props);
  const { filter } = useFilter();
  const filterApp = filter?.filtersApply?.CodSafra?.title;


  return (
    <>
      <PageTitle>
        <h1>{translate(language.language, "pagina inicial")}</h1>
        <small>
          Dashboard {<strong>{filterApp || translate(language.language, "Sem safra selecionada")}</strong>}
        </small>
      </PageTitle>
      <PageBody>
        <Filter filters={["CodSafra"]} />
        <HomeDashboard />
        <Row
          style={{
            alignItems: "center",
            borderLeft: "8px solid rgb(38 40 54)",
            paddingLeft: "10px",
            gap: "20px",
            position: "absolute",
            maxWidth: "800px",
            bottom: "30px",
          }}
        >
          <Cotacao />
          {/* <Weather /> */}
        </Row>
      </PageBody>
    </>
  );
};

export default Home;
