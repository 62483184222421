//#region
import React from "react";
import LancamentosEncerradosDia from "../reports/indicadoresAgricola/lancamentosEncerradosDia";
import InsumosAplicados from "../reports/indicadoresAgricola/insumosAplicados";
import ManutencaoPreventiva from "../reports/indicadoresAgricola/manutencaoPreventiva";
import MoivoOsAberto from "../reports/indicadoresAgricola/motivoOsAberto";
import VencimentoCnh from "../reports/indicadoresAgricola/vencimentoCnh";
import VolumeAbastecimento from "../reports/indicadoresAgricola/volumeAbastecimento";
import VolumeClimatico from "../reports/indicadoresAgricola/volumeClimatico";
import DuracaoProjetada from "../reports/indicadoresAgricola/duracaoProjetada";
import AnimaisPesoAbate from '../reports/pecuaria/animaisPesoAbate'
//#endregion

interface IComponents {
  [key: string]: {
    component: Function;
    filters: string[];
  };
}
interface IProps {
  dto: {
    id: string;
    nameComponent: string;
    customTitle: string;
  };
}
const componentsImport: IComponents = {
  animaisPesoAbate: {
    component: (props: IProps) => <AnimaisPesoAbate dto={props} />,
    filters: [],
  },
  lancamentoEncerradoDia: {
    component: (props: IProps) => <LancamentosEncerradosDia dto={props} />,
    filters: [],
  },
  insumosAplicados: {
    component: (props: IProps) => <InsumosAplicados dto={props} />,
    filters: [],
  },
  manutencaoPreventiva: {
    component: (props: IProps) => <ManutencaoPreventiva dto={props} />,
    filters: [],
  },
  moivoOsAberto: {
    component: (props: IProps) => <MoivoOsAberto dto={props} />,
    filters: [],
  },
  vencimentoCnh: {
    component: (props: IProps) => <VencimentoCnh dto={props} />,
    filters: [],
  },
  volumeAbastecimento: {
    component: (props: IProps) => <VolumeAbastecimento dto={props} />,
    filters: [],
  },
  volumeClimatico: {
    component: (props: IProps) => <VolumeClimatico dto={props} />,
    filters: [],
  },
  duracaoProjetada: {
    component: (props: IProps) => <DuracaoProjetada dto={props} />,
    filters: [],
  },
};

export default componentsImport;
