import React, { memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import HideModal from "../../../Components/hideModal";
import Table from "../../../Components/table";
import { KpiContainer, Container, Legend as LegendTable } from "../style";
import { flatten, groupBy, orderBy, sumBy, toNumber } from "lodash";
import { format, sub } from "date-fns";
import useFilter from "../../../hooks/useFilter";
import useUser from "../../../hooks/useUser";
import axios from "axios";
import NoDataToShow from "../../../Components/noDataToShow";
import useTranslate from "../../../hooks/useTranslate";
import { IAlertaVencimentoCnh } from "../interface";

const VencimentoCnh: React.FC<any> = (props) => {
  const { userInformation } = useUser();
  const [data, setData] = useState<IAlertaVencimentoCnh[]>([]);
  const { translate } = useTranslate();
  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation]);

  const fetchData = async () => {
    const { data: dataInfo } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/relatorios/logistica/alertaVencimentoCNH`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setData(orderBy(dataInfo, ["DiasVencimento"], ["desc"]));
  };

  return (
    <>
      <CardBody
        returnTitleUpdated={(e) => props.dto.defineTitle(e)}
        title={props?.dto?.customTitle || translate("alerta de vencimento de cnh")}
      >
        <Container>
          <KpiContainer style={{ height: "100%", overflow: "scroll" }}>
            {data.length < 1 ? (
              <NoDataToShow />
            ) : (
              <Table style={{ borderCollapse: "collapse" }}>
                <tr>
                  <th>{translate("nome")}</th>
                  <th>{translate("registro")}</th>
                  <th>{translate("categoria")}</th>
                  <th>{translate("data vencimento")}</th>
                  <th>{translate("dias para o vencimento")}</th>
                </tr>
                {data.map((Os: IAlertaVencimentoCnh) => {
                  const vencimento =
                    toNumber(Os.DiasVencimento) >= 0 &&
                    toNumber(Os.DiasVencimento) <= 30;
                  return (
                    <tr className={!vencimento ? translate("vencido") : ""}>
                      <td>{Os.Nome}</td>
                      <td>{Os.Registro}</td>
                      <td>{Os.Categoria}</td>
                      <td>
                        {Os.DataVencimento !== undefined ? format(new Date(Os.DataVencimento), "dd/MM/yyyy") : ""}
                      </td>
                      <td>
                        {vencimento
                          ? `Vencerá em ${Os.DiasVencimento} dias`
                          : `Venceu há ${
                              toNumber(Os.DiasVencimento) * -1
                            } dias`}
                      </td>
                    </tr>
                  );
                })}
              </Table>
            )}
          </KpiContainer>
        </Container>
      </CardBody>
    </>
  );
};

export default VencimentoCnh;
