import React, { memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import Table from "../../../Components/table";
import { KpiContainer, Container} from "../style";
import { groupBy, orderBy, sumBy, toNumber } from "lodash";
import { format, sub } from "date-fns";
import useUser from "../../../hooks/useUser";
import axios from "axios";
import { IInsumosAplicados } from "../interface";
import useTranslate from "../../../hooks/useTranslate";

const InsumosAplicados: React.FC<any> = (props) => {
  const { userInformation } = useUser();
  const { translate } = useTranslate();

  const [listInsumos, setInsumosList] = useState<any>([]);
  const [data, setData] = useState<IInsumosAplicados[]>([]);

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation]);
  useEffect(() => {
    formatInsumosAplicadosData();
  }, [data]);

  const fetchData = async () => {
    const today = format(new Date(), "yyyy-MM-dd");
    const sevenDayBefore = format(
      sub(new Date(), {
        days: 6,
      }),
      "yyyy-MM-dd"
    );
    const { data: dataInfo } = await axios.post(
      `${userInformation.UrlApiFarm4all}agricola/relatorios/operacionais/operacoesAgricolasInsumos`,
      {
        DataInicial: sevenDayBefore,
        DataFinal: today,
      },
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setData(dataInfo);
  };
  const formatInsumosAplicadosData = () => {
    let result: any = { tr: [], td: { combustiveis: [] } };
    const insumos: any = groupBy(data, "U_DscInsumo");
    const farm: any = groupBy(data, "U_DscFazenda");

    const fazendasKey = Object.keys(farm);
    const insumosKey = Object.keys(insumos);

    const arrayFazendas = [translate("insumos"), ...orderBy(fazendasKey)];
    const arrayInsumos = [...orderBy(insumosKey)];
    const dataSet = fazendasKey.map((farmName: any) => {
      const obj = {};
      const farmArr = farm[farmName];
      const groupByInsumo = groupBy(farmArr, "U_DscInsumo");
      const resultData = Object.keys(groupByInsumo).map((key: string) => {
        const insumos = groupByInsumo[key];
        const total = sumBy(insumos, "totalutilizado");
        return Object.assign(obj, { [key]: total });
      });
      result.td[farmName] = obj;
    });
    result.tr = arrayFazendas;
    result.th = arrayInsumos;
    result.td.combustiveis = arrayInsumos;

    setInsumosList(result);
  };
  return (
    <>
      <CardBody 
      returnTitleUpdated={(e) => props.dto.defineTitle(e)}
      title={
        props?.dto?.customTitle || translate("insumos aplicados *ultimos 7 dia")
      }
      >
        <Container>
          <KpiContainer
            style={{ height: "100%", maxHeight: "400px", overflow: "scroll" }}
          >
            <small></small>
            <Table>
              <thead>
                <tr>
                  {listInsumos?.tr?.map((farmName: string) => {
                    return <th align="left">{farmName}</th>;
                  })}
                </tr>
              </thead>
              {listInsumos?.th?.map((nameInsumo: string) => {
                return (
                  <tr>
                    <td
                      style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {nameInsumo}
                    </td>
                    {listInsumos?.tr?.map((nameFarm: string) => {
                      return (
                        nameFarm != translate("Insumos") && (
                          <td align="left">
                            {toNumber(
                              listInsumos?.td?.[nameFarm]?.[
                                nameInsumo
                              ]?.toFixed(2) || 0
                            ).toLocaleString()}
                          </td>
                        )
                      );
                    })}
                  </tr>
                );
              })}
            </Table>
          </KpiContainer>
        </Container>
      </CardBody>
    </>
  );
};

export default InsumosAplicados;
