import React, {
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import PageTitle from "../../Components/pageTitle";
import { MapSvg, Button } from "./style";
import useUser from "../../hooks/useUser";
import useTranslate from "../../hooks/useTranslate";
import fazendasSvg from "../../config/maps";
import useFilter from "../../hooks/useFilter";
import Filter from "../../Components/filter";
import HideModal from "../../Components/hideModal";
import Datepicker from "../../Components/datepicker";
import { getLocationName } from "../../utils/mapLocation";
import TalhaoContainer from "../../Components/talhaoCard";
import { Select } from "../../Components/profile/style";
import Arido from "../../assets/legendImage/Arido.png";
import axios from "axios";
import { toNumber } from "lodash";
import { format } from "date-fns";
interface IFilter {
  url: string;
  field: string;
  visualization: string;
}
const Map: React.FC = () => {
  const [show, setShow] = useState<any | boolean>("");
  const { userInformation } = useUser();
  const { translate } = useTranslate();
  const { filter, handleAddFilter } = useFilter();
  const [data, setData] = useState<any>([]);
  const [focusedLocation, setFocusedLocation] = useState(null);
  const [hiddenHeatmap, setHiddenHeatmap] = useState(false);
  const [pointedLocation, setPointedLocation] = useState<string>("");
  const [selectedLocations, setSelectedLocation] = useState<any>([]);
  const [tooltipStyle, setTooltipStyle] = useState({ display: "none" });
  const localStorageData: any = localStorage.getItem("visualization");
  const [filterVisualization, setFilterVisualizations] = useState<any>();
  const farms: any = fazendasSvg;
  const reference = useRef<HTMLHeadingElement>(null);
  const [filters, setFilters] = useState([
    "CodFazendaSvg",
    "CodPeriodoProducaoNoSafra",
  ]);
  useEffect(() => {
    setSelectedLocation([]);
  }, [filter?.filtersApply?.CodFazendaSvg]);
  useEffect(() => {
    fetchData();
  }, [filter?.filtersApply, localStorageData]);

  async function fetchData() {
    if (filterVisualization) {
      const { data } = await axios.post(
        `${userInformation.UrlApiFarm4all}${filterVisualization.url}`,
        {
          CodPeriodoProducao: filter?.filtersApply?.CodPeriodoProducao?.value,
          CodFazenda: filter?.filtersApply?.CodFazendaSvg?.value,
          DtInicial: filter?.filtersApply?.dataInicial?.value,
          DtFinal: filter?.filtersApply?.dataFinal?.value,
          [filterVisualization?.field]:
            filter?.filtersApply?.[filterVisualization?.field]?.value,
        },
        {
          auth: {
            username: userInformation.UsuarioApiFarm4all,
            password: userInformation.SenhaApiFarm4all,
          },
        }
      );
      
      setData(data);
    }
  }
  function handleLocationFocus(event: any) {
    const focusedLocation = getLocationName(event);
    setFocusedLocation(focusedLocation);
  }
  function handleLocationBlur() {
    setFocusedLocation(null);
  }
  function handleOnChange(selectedNodes: any) {
    setSelectedLocation((prevState: any) => {
      return {
        ...selectedLocations,
        selectedLocations:
          filterVisualization?.visualization === "colheita" &&
          selectedNodes.map((node: any) => node.attributes.name.value),
        selectedOperacao:
          filterVisualization?.visualization === "operacao" &&
          selectedNodes.map((node: any) => node.attributes.name.value),
        selectedElementoClimatico:
          filterVisualization?.visualization === "climatico" &&
          selectedNodes.map((node: any) => node.attributes.name.value),
        selectedInsumo:
          filterVisualization?.visualization === "insumo" &&
          selectedNodes.map((node: any) => node.attributes.name.value),
        selectedFitossanidade:
          filterVisualization?.visualization === "fitossanidade" &&
          selectedNodes.map((node: any) => node.attributes.name.value),
      };
    });
  }
  function returnDataFromTalhao(idTalhao: string) {
    return data.findIndex((e: any) => e.CodTalhao === idTalhao);
  }
  function handleLocationMouseMove(event: any) {
    const pointedLocation = getLocationName(event);
    
    const tooltipStyle = {
      display: "block",
      pointerEvents: "none",
      position: "fixed",
      top: event.clientY + 10,
      maxWidth: 370,
      left: event.clientX - 100,
    };
    
    setPointedLocation(pointedLocation);
    setTooltipStyle(tooltipStyle);
  }
  function handleLocationMouseOut() {
    setTooltipStyle({ display: "none" });
  }
  function formatVal(val: number) {
    if (val == 0) {
      return 0;
    }
    if (val > 0 && val < 20) {
      return 10;
    }
    if (val > 20 && val < 30) {
      return 20;
    }
    if (val > 30 && val < 40) {
      return 30;
    }
    if (val > 40 && val < 50) {
      return 40;
    }
    if (val > 50 && val < 60) {
      return 50;
    }
    if (val > 60 && val < 70) {
      return 60;
    }
    if (val > 70 && val < 80) {
      return 70;
    }
    if (val > 80 && val < 90) {
      return 80;
    }
    if (val > 90 && val < 100) {
      return 90;
    }
    if (val == 100) {
      return 100;
    }
  }
  function formatClimatico(val: number) {
    if (val < 1) {
      return 0;
    }
    if (val > 0 && val < 287.0) {
      return 20;
    }
    if (val > 287.0 && val < 574.0) {
      return 40;
    }
    if (val > 574.0 && val < 861.0) {
      return 60;
    }
    if (val > 861.0 && val < 1147.0) {
      return 80;
    }
    if (val > 1147.0) {
      return 100;
    }
  }
  function formatInsumo(val: number) {
    if (val == 0) {
      return 0;
    }
    if (val > 0 && val < 25.0) {
      return 20;
    }
    if (val > 25.0 && val < 50.0) {
      return 40;
    }
    if (val > 50.0 && val < 75.0) {
      return 60;
    }
    if (val > 75.0 && val < 100.0) {
      return 80;
    }
    if (val > 100.0) {
      return 100;
    }
  }
  function handleChangeVisualization(event: any) {
    const { url, field, visualization } = JSON.parse(event.target.value);
    if (field) {
      setFilters(["CodFazendaSvg", "CodPeriodoProducaoNoSafra", field]);
    } else {
      setFilters(["CodFazendaSvg", "CodPeriodoProducaoNoSafra"]);
    }
    localStorage.setItem(
      "visualization",
      JSON.stringify({ url, field, visualization })
    );
    setFilterVisualizations({ url, field, visualization });
  }
  const getDataq = returnDataFromTalhao(pointedLocation);
  
  useEffect(() => {
    
    if (localStorageData) {
      const { url, field, visualization } = JSON.parse(localStorageData);
      if (field) {
        setFilters(["CodFazendaSvg", "CodPeriodoProducaoNoSafra", field]);
      } else {
        setFilters(["CodFazendaSvg", "CodPeriodoProducaoNoSafra"]);
      }
      setFilterVisualizations({ url, field, visualization });
    }
  }, [localStorageData]);
  return (
    <>
      <Filter filters={filters}>
        <Select
          style={{ backgroundColor: "#fff" }}
          onChange={handleChangeVisualization}
        >
          <option disabled selected>
            {translate("selecione uma visualizacao")}
          </option>
          <option
            selected={filterVisualization?.visualization === "colheita"}
            value={JSON.stringify({
              url: "agricola/sig/mapa/dadosColheita",
              visualization: "colheita",
            })}
          >
            {translate("colheita")}
          </option>
          <option
            selected={filterVisualization?.visualization === "operacao"}
            value={JSON.stringify({
              url: "agricola/sig/mapa/dadosOperacao",
              field: "CodOperacao",
              visualization: "operacao",
            })}
          >
            {translate("operacoes")}
            
          </option>
          <option
            selected={filterVisualization?.visualization === "climatico"}
            value={JSON.stringify({
              url: "agricola/sig/mapa/dadosClimaticos",
              field: "CodElementoClimatico",
              visualization: "climatico",
            })}
          >
            {translate("climatico")}
          </option>
          <option
            selected={filterVisualization?.visualization === "insumo"}
            value={JSON.stringify({
              url: "agricola/sig/mapa/dadosInsumo",
              field: "CodInsumo",
              visualization: "insumo",
            })}
          >
            {translate("insumos")}
          </option>
          <option
            selected={filterVisualization?.visualization === "fitossanidade"}
            value={JSON.stringify({
              url: "agricola/sig/mapa/dadosFitossanidade",
              field: "CodFitossanidade",
              visualization: "fitossanidade",
            })}
          >
            {translate("fitossanidade")}
            
          </option>
        </Select>
        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <Datepicker />
        </div>
      </Filter>
      <PageTitle>
        <h1>{translate("mapa")}</h1>
        <small>
        {translate("mapa selecionado")}
          <strong>
            {" "}
            {farms?.[userInformation?.CodEmpresa]?.[filter?.filtersApply?.CodFazendaSvg?.title]?.label}
          </strong>
        </small>
      </PageTitle>
      {show && (
        <HideModal close={() => setShow(false)} information={show}>
          {show}
        </HideModal>
      )}
      <div style={{ display: "flex", flexDirection: "row" }}>
              {farms?.[userInformation?.CodEmpresa]?.[filter?.filtersApply?.CodFazendaSvg?.title]?.label               
              && (
            <>           
              <div
                ref={reference}
                style={{ overflow: "hidden", maxHeight: "90vh", width: "700px" }}
              >
                <MapSvg
                  locationClassName={(e) => {
                    
                    
                    const getDataInfo = data[returnDataFromTalhao(e.id)];
                    const valuesRounded =
                      getDataInfo &&
                      Math.round(toNumber(getDataInfo?.PctColhido));
                    if (filterVisualization?.visualization === "colheita") {
                      return `${hiddenHeatmap && e.terreno
                        } svg-map__location percentage_${valuesRounded && formatVal(valuesRounded)
                        }`;
                    }
                    if (filterVisualization?.visualization === "operacao") {
                      return `svg-map__location percentage_0`;
                    }
                    if (filterVisualization?.visualization === "climatico") {

                      return `${hiddenHeatmap && getDataInfo?.Quantidade
                        } svg-map__location percentage_${formatClimatico(
                          getDataInfo?.Quantidade
                        )}`;
                    }
                    if (filterVisualization?.visualization === "insumo") {
                      return `${hiddenHeatmap && getDataInfo?.AreaRealizada
                        } svg-map__location percentage_${formatInsumo(
                          getDataInfo?.AreaRealizada
                        )}`;
                    }
                    if (filterVisualization?.visualization === "fitossanidade") {
                      return `svg-map__location percentage_${getDataInfo?.AcimaLimite}`;
                    }
                  }}

                  childrenAfter={data.map((e: any, index: number) => {
                    const element: any = e.CodTalhao && document?.querySelector("[name="+"'"+e.CodTalhao+"'"+"]");
                    const elProp = element && element?.getBBox();
                    const widthTreatment = Math.round(elProp?.width / 2 + elProp?.x).toFixed(1);
                    const heightTreatment = Math.round(elProp?.height / 2 + elProp?.y).toFixed(1);
                    if (heightTreatment && element?.getAttribute("name") === e.CodTalhao) {
                      return (
                        <text
                          fontSize="3"
                          id="ten"
                          x={widthTreatment}
                          y={heightTreatment}
                          style={{ pointerEvents: "none" }}
                        >
                          {e.CodTalhao}
                        </text>
                      );
                    }
                  })}
                  childrenBefore={
                    <defs>
                      <pattern
                        id="arido"
                        patternUnits="userSpaceOnUse"
                        width="10"
                        height="10"
                      >
                        <image href={Arido} x="0" y="0" width="25" height="25" />
                      </pattern>
                    </defs>
                  }
                  onLocationMouseOut={handleLocationMouseOut}
                  onLocationMouseOver={(event) =>
                    tooltipStyle.display === "none" &&
                    handleLocationMouseMove(event)
                  }
                  onLocationFocus={handleLocationFocus}
                  onLocationBlur={handleLocationBlur}
                  onChange={handleOnChange}                 
                  map={                    
                    {
                    ...farms[userInformation?.CodEmpresa]?.[filter?.filtersApply?.CodFazendaSvg?.title]?.information,
                    locations: farms[userInformation?.CodEmpresa]?.[filter?.filtersApply?.CodFazendaSvg?.title]?.information?.locations?.map(
                      (location: any, r: any) => location
                    ),
                  }
                }
                />
              </div>
            </>
          )}
        
        {selectedLocations?.selectedLocations?.length > 0 && filterVisualization?.visualization === "colheita" && (
           
            <TalhaoContainer>
              {selectedLocations?.selectedLocations?.map(
                (idTalhao: string, index: number) => {
                  
                  const getData = returnDataFromTalhao(idTalhao);
                  if (getData > -1) {
                    
                    return (
                      <>
                        <header>
                          <section>
                            <strong>{translate("talhao")}</strong> {idTalhao}
                          </section>
                        </header>
                        <body>
                          <div className="col" style={{ marginRight: "20px" }}>
                            <h2>
                              {data[getData].PctColhido >= 100 ? 100 : data[getData].PctColhido == 0 ? 0 : data[getData].PctColhido.toFixed(1).toLocaleString()}
                              {" "}
                              <span>%</span>
                            </h2>
                            <strong>{translate("percentual colhido (%)")}</strong>
                          </div>
                          <div className="col" style={{ gap: "12px" }}>
                            <div className="col">
                              <h1>{data[getData].AreaPlantada}</h1>
                              <span>{translate("area plantada")} </span>
                            </div>
                            <div className="col">
                              <h1>
                                {data[getData].AreaColhida.toLocaleString()}
                              </h1>
                              <span>{translate("area colhida")}</span>
                            </div>
                          </div>
                        </body>
                      </>
                    );
                  }
                }
              )}
            </TalhaoContainer>
          )}
        {filterVisualization?.visualization === "colheita" && (
          <>
            <TalhaoContainer
              pointed={pointedLocation.length > 0}
              style={tooltipStyle}
            >
              <>
                <header>
                  <section>
                    <strong>{translate("talhao")}</strong> {pointedLocation}
                  </section>
                </header>
                <body>
                  <div className="col" style={{ marginRight: "20px" }}>
                    <h2>
                      {data?.[getDataq]?.PctColhido >= 100
                        ? 100
                        : data?.[getDataq]?.PctColhido == 0
                          ? 0
                          : data?.[getDataq]?.PctColhido?.toFixed(
                            1
                          )?.toLocaleString()}{" "}
                      <span>%</span>
                    </h2>
                    <strong>{translate("percentual colhido (%)")}</strong>
                  </div>
                  <div className="col" style={{ gap: "12px" }}>
                    <div className="col">
                      <h1>{data?.[getDataq]?.AreaPlantada}</h1>
                      <span>{translate("area plantada")}</span>
                    </div>
                    <div className="col">
                      <h1>{data?.[getDataq]?.AreaColhida?.toLocaleString()}</h1>
                      <span>{translate("area colhida")}</span>
                    </div>
                  </div>
                </body>
              </>
            </TalhaoContainer>
            <TalhaoContainer
              pointed={true}
              style={{
                display: "block",
                pointerEvents: "none",
                position: "fixed",
                transition: ".51s",
                width: "450px",
                maxWidth: 370,
                right:
                  selectedLocations?.selectedLocations?.length > 0
                    ? "650px"
                    : "300px",
              }}
            >
              <>
                <header>
                  <section>
                    <p>{translate("legendas")}</p>
                  </section>
                </header>
                <body>
                  <div
                    className="col"
                    style={{ width: "90%", textAlign: "left" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "30px",
                        background: "linear-gradient(91deg, white, #f9d892)",
                      }}
                    />
                    <div
                      className="row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span>0%</span>
                      <span>{translate("percentual colhido")}</span>
                      <span>100%</span>
                    </div>
                    {hiddenHeatmap && (
                      <>
                        <div
                          className="row"
                          style={{
                            justifyContent: "space-between",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${Arido})`,
                              backgroundSize: "100px",
                              width: "100%",
                              backgroundColor: "#fff",
                              height: "30px",
                            }}
                          />
                        </div>
                        <span>{translate("terreno arido")}</span>
                      </>
                    )}
                  </div>
                </body>
              </>
            </TalhaoContainer>
          </>
        )}

        {selectedLocations?.selectedOperacao?.length > 0 &&
          filterVisualization?.visualization === "operacao" && (
            <TalhaoContainer style={{ width: "320px" }}>
              {selectedLocations?.selectedOperacao?.map(
                (idTalhao: string, index: number) => {
                  const getData = returnDataFromTalhao(idTalhao);
                  
                  return (
                    <>
                      <header>
                        <section>
                          <strong>{translate("talhao")} </strong> {idTalhao}
                        </section>
                      </header>
                      <body
                        style={{ flexDirection: "column", textAlign: "left" }}
                      >
                        {data[getData]?.Insumos.map((insumo: any) => {
                          return (
                            <>
                              <div
                                className="col"
                                style={{
                                  gap: "12px",
                                  width: "230px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  background: "#00000025",
                                  padding: "10px",
                                }}
                              >
                                <div className="col">
                                  <span>
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                      }}
                                    >
                                      Insumo{" "}
                                    </span>{" "}
                                    {insumo.DscInsumo}
                                  </span>
                                </div>
                                <div className="col">
                                  <span>
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                      }}
                                    >
                                      {translate("quantidade aplicada")}{" "}
                                    </span>{" "}
                                    {insumo.QtdAplicada.toLocaleString()}
                                  </span>
                                </div>
                                <div className="col">
                                  <span>
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                      }}
                                    >
                                       {translate("quantidade programada")}{" "}
                                    </span>{" "}
                                    {insumo.QtdProgramada.toLocaleString()}
                                  </span>
                                  {/* <h1> {insumo.QtdProgramada}</h1> */}
                                  {/* <span> quantidade programada </span> */}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </body>
                    </>
                  );
                }
              )}
            </TalhaoContainer>
          )}

        {selectedLocations?.selectedElementoClimatico?.length > 0 &&
          filterVisualization?.visualization === "climatico" && (
            <TalhaoContainer>
              {selectedLocations?.selectedElementoClimatico?.map(
                (idTalhao: string, index: number) => {
                  const getData = returnDataFromTalhao(idTalhao);
                  
                  return (
                    <>
                      <header>
                        <section>
                          <strong>{translate("talhao")}</strong> {idTalhao}
                        </section>
                      </header>
                      <body
                        style={{ flexDirection: "column", textAlign: "left" }}
                      >
                        <body>
                          <div className="col" style={{ marginRight: "20px" }}>
                            <h2>
                              {data[getData]?.Percentual.toFixed(2)}{" "}
                              <span>%</span>
                            </h2>
                            <strong>{translate("percentual")}</strong>
                          </div>
                          <div className="col" style={{ gap: "12px" }}>
                            <div className="col">
                              <h1>{data[getData]?.Quantidade?.toFixed(2)}</h1>
                              <span>{translate("quantidade")}</span>
                            </div>
                          </div>
                        </body>
                      </body>
                    </>
                  );
                }
              )}
            </TalhaoContainer>
          )}
        {filterVisualization?.visualization === "climatico" && (
          <>
            <TalhaoContainer
              pointed={pointedLocation.length > 0}
              style={tooltipStyle}
            >
              <>
                <header>
                  <section>
                    <strong>{translate("talhao")}</strong> {pointedLocation}
                  </section>
                </header>
                <body style={{ flexDirection: "column", textAlign: "left" }}>
                  <body>
                    <div className="col" style={{ marginRight: "20px" }}>
                      <h2>
                        {data?.[getDataq]?.Percentual?.toFixed(2)}{" "}
                        <span>%</span>
                      </h2>
                      <strong> {translate("percentual")}</strong>
                    </div>
                    <div className="col" style={{ gap: "12px" }}>
                      <div className="col">
                        <h1>{data?.[getDataq]?.Quantidade?.toFixed(2)}</h1>
                        <span> {translate("quantidade")}</span>
                      </div>
                    </div>
                  </body>
                </body>
              </>
            </TalhaoContainer>
            <TalhaoContainer
              pointed={true}
              style={{
                display: "block",
                pointerEvents: "none",
                position: "fixed",
                transition: ".51s",
                width: "450px",
                maxWidth: 370,
                right:
                  selectedLocations?.selectedElementoClimatico?.length > 0
                    ? "680px"
                    : "300px",
              }}
            >
              <>
                <header>
                  <section>
                    <p>{translate("legendas")}</p>
                  </section>
                </header>
                <body>
                  <div
                    className="col"
                    style={{ width: "90%", textAlign: "left" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "30px",
                        background: "linear-gradient(91deg, white, #f9d892)",
                      }}
                    />
                    <div
                      className="row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span>0</span>
                      <span>{translate("quantidade")}</span>
                      <span>1147</span>
                    </div>
                    {hiddenHeatmap && (
                      <>
                        <div
                          className="row"
                          style={{
                            justifyContent: "space-between",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${Arido})`,
                              backgroundSize: "100px",
                              width: "100%",
                              backgroundColor: "#fff",
                              height: "30px",
                            }}
                          />
                        </div>
                        <span>{translate("terreno arido")}</span>
                      </>
                    )}
                  </div>
                </body>
              </>
            </TalhaoContainer>
          </>
        )}

        {selectedLocations?.selectedInsumo?.length > 0 &&
          filterVisualization?.visualization === "insumo" && (
            <TalhaoContainer>
              {selectedLocations?.selectedInsumo?.map(
                (idTalhao: string, index: number) => {
                  const getData = returnDataFromTalhao(idTalhao);
                  
                  return (
                    <>
                      <header>
                        <section>
                          <strong>{translate("talhao")}</strong> {idTalhao}
                        </section>
                      </header>
                      <body
                        style={{ flexDirection: "column", textAlign: "left" }}
                      >
                        <body>
                          <div className="col" style={{ marginRight: "20px" }}>
                            <h2>
                              {data[getData]?.QtdKgs?.toFixed(2)}{" "}
                              <span>KGs</span>
                            </h2>
                            <strong>{translate("quantidade")}</strong>
                          </div>
                          <div className="col" style={{ gap: "12px" }}>
                            <div className="col">
                              <h1>
                                {data[getData]?.DoseRealizada?.toFixed(2)}{" "}
                                kgs/ha
                              </h1>
                              <span> {translate("dose realizada")}</span>
                            </div>
                            <div className="col">
                              <h1>
                                {data[getData]?.AreaRealizada?.toFixed(2)} ha
                              </h1>
                              <span> {translate("area realizada")}</span>
                            </div>
                          </div>
                        </body>
                      </body>
                    </>
                  );
                }
              )}
            </TalhaoContainer>
          )}
        {filterVisualization?.visualization === "insumo" && (
          <>
            <TalhaoContainer
              pointed={pointedLocation.length > 0}
              style={tooltipStyle}
            >
              <>
                <header>
                  <section>
                    <strong>{translate("talhao")}</strong> {pointedLocation}
                  </section>
                </header>
                <body style={{ flexDirection: "column", textAlign: "left" }}>
                  <body>
                    <div className="col" style={{ marginRight: "20px" }}>
                      <h2>
                        {data[getDataq]?.QtdKgs?.toFixed(2)} <span>KGs</span>
                      </h2>
                      <strong>{translate("quantidade")}</strong>
                    </div>
                    <div className="col" style={{ gap: "12px" }}>
                      <div className="col">
                        <h1>
                          {data[getDataq]?.DoseRealizada?.toFixed(2)} kgs/ha
                        </h1>
                        <span>{translate("dose realizada")}</span>
                      </div>
                      <div className="col">
                        <h1>{data[getDataq]?.AreaRealizada?.toFixed(2)} ha</h1>
                        <span>{translate("area realizada")}</span>
                      </div>
                    </div>
                  </body>
                </body>
              </>
            </TalhaoContainer>
            <TalhaoContainer
              pointed={true}
              style={{
                display: "block",
                pointerEvents: "none",
                position: "fixed",
                transition: ".51s",
                width: "450px",
                maxWidth: 370,
                right:
                  selectedLocations?.selectedInsumo?.length > 0
                    ? "680px"
                    : "300px",
              }}
            >
              <>
                <header>
                  <section>
                    <p>{translate("legendas")}</p>
                  </section>
                </header>
                <body>
                  <div
                    className="col"
                    style={{ width: "90%", textAlign: "left" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "30px",
                        background: "linear-gradient(91deg, white, #f9d892)",
                      }}
                    />
                    <div
                      className="row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span>0</span>
                      <span>{translate("quantidade")} Kg/s</span>
                      <span>171,00</span>
                    </div>
                    {hiddenHeatmap && (
                      <>
                        <div
                          className="row"
                          style={{
                            justifyContent: "space-between",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${Arido})`,
                              backgroundSize: "100px",
                              width: "100%",
                              backgroundColor: "#fff",
                              height: "30px",
                            }}
                          />
                        </div>
                        <span>{translate("terreno arido")}</span>
                      </>
                    )}
                  </div>
                </body>
              </>
            </TalhaoContainer>
          </>
        )}
        {selectedLocations?.selectedFitossanidade?.length > 0 &&
          filterVisualization?.visualization === "fitossanidade" && (
            <TalhaoContainer>
              {selectedLocations?.selectedFitossanidade?.map(
                (idTalhao: string, index: number) => {
                  const getData = returnDataFromTalhao(idTalhao);
                  
                  return (
                    <>
                      <header>
                        <section>
                          <strong>{translate("talhao")}</strong> {idTalhao}
                        </section>
                      </header>
                      <body
                        style={{ flexDirection: "column", textAlign: "left" }}
                      >
                        <body>
                          <div className="col" style={{ marginRight: "20px" }}>
                            <h2>{data[getData]?.Medicao?.toFixed(2)}</h2>
                            <strong>{translate("medicao")}</strong>
                          </div>
                          <div className="col" style={{ gap: "12px" }}>
                            <div className="col">
                              <h1>
                                {format(
                                  new Date(data[getData]?.Data),
                                  "dd/MM/yyyy"
                                )}
                              </h1>
                              <span>{translate("data")}</span>
                            </div>
                          </div>
                        </body>
                      </body>
                    </>
                  );
                }
              )}
            </TalhaoContainer>
          )}

        {filterVisualization?.visualization === "fitossanidade" && (
          <>
            <TalhaoContainer
              pointed={pointedLocation.length > 0}
              style={tooltipStyle}
            >
              <>
                <header>
                  <section>
                    <strong>{translate("talhao")}</strong> {pointedLocation}
                  </section>
                </header>
                <body style={{ flexDirection: "column", textAlign: "left" }}>
                  <body>
                    <div className="col" style={{ marginRight: "20px" }}>
                      <h2>{data?.[getDataq]?.Medicao?.toFixed(2)}</h2>
                      <strong>{translate("medicao")}</strong>
                    </div>
                    <div className="col" style={{ gap: "12px" }}>
                      <div className="col">
                        <h1>
                          {data?.[getDataq]?.Data &&
                            format(
                              new Date(data?.[getDataq]?.Data),
                              "dd/MM/yyyy"
                            )}
                        </h1>
                        <span> {translate("data")}</span>
                      </div>
                    </div>
                  </body>
                </body>
              </>
            </TalhaoContainer>
            <TalhaoContainer
              pointed={true}
              style={{
                display: "block",
                pointerEvents: "none",
                position: "fixed",
                transition: ".51s",
                width: "450px",
                maxWidth: 370,
                right:
                  selectedLocations?.selectedFitossanidade?.length > 0
                    ? "650px"
                    : "300px",
              }}
            >
              <>
                <header>
                  <section>
                    <p>{translate("legendas")}</p>
                  </section>
                </header>
                <body style={{ flexWrap: "wrap" }}>
                  <div
                    className="row"
                    style={{ width: "90%", textAlign: "left" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "30px",
                        background: "#f9d892",
                        maxWidth: "40px",
                      }}
                    />
                    <div
                      className="row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span>{translate("dentro do limite de infestacao")}</span>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      width: "90%",
                      textAlign: "left",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "30px",
                        maxWidth: "40px",
                        background: "red",
                      }}
                    />
                    <div
                      className="row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span>{translate("acima do limite de infestacao")}</span>
                    </div>
                  </div>
                </body>
              </>
            </TalhaoContainer>
          </>
        )}
      </div>
    </>
  );
};
export default Map;
