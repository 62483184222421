import React, { CSSProperties } from 'react';
import {Container} from './style'
interface Card {
    style?: CSSProperties,
    pointed?: boolean
}
const CardTalhao: React.FC<Card> = ({children, style, pointed}) => {
    return (
    <>
        <Container pointed={pointed} style={style}>
            {children}
        </Container>
    </>
    )
}
export default CardTalhao;