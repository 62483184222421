import { Reducer } from "redux";
import produce from "immer";
import IUser from "./interface";

const INITIAL_STATE: IUser = {
  Usuario: "",
  Empresa: "",
  Email: "",
  Layout: "",
  Idioma: "",
  UrlApiFarm4all: "",
  UsuarioApiFarm4all: "",
  SenhaApiFarm4all: "",
  Dashboards: [],
};
const language: Reducer<IUser> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGIN_USER": {
      return produce(state, (draft: any) => {
        let elements = Object.keys(action.payload);
        elements.map((key) => {
          draft[key] = action.payload[key];
        });
      });
    }
    case "INSERT_NEW_DASHBOARD": {
      return produce(state, (draft: any) => {
        draft.Dashboards.push(action.payload);
      });
    }
    case "SET_NEW_IMAGE": {
      return produce(state, (draft: any) => {
         draft.Base64ProfileImg = action.payload;
        //  draft.Dashboards.push(action.payload);
       });
    }
    case "REMOVE_DASHBOARD": {
      return produce(state, (draft: any) => {
        const dashs = state.Dashboards.filter(
          (item) => item.DashboardId != action.payload
        );
        draft.Dashboards = dashs;
      });
    }

    case "UPDATE_DASHBOARD": {
      return produce(state, (draft: any) => {
        const dashs = state.Dashboards.filter(
          (item) => item.DashboardId != action.payload.DashboardId
        );
        dashs.push(action.payload);
        draft.Dashboards = dashs;
      });
    }

    default: {
      return state;
    }
  }
};

export default language;
