//#region
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Container,
  ContainerInput,
  Form,
  Input,
  IdepenteImage,
  Content,
} from "./style";
import { AuthContext } from "../../contexts/auth.context";
import LogoLbr from "../../assets/logoLbrSemFundo.png";
import SigLogo from "../../assets/sigLogo.png";
import { info, warnn, dismiss, success } from "../../services/toasty";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineKey,
  AiOutlineUser,
} from "react-icons/ai";

import SigB from "../../assets/SIGB.png";
import SigA from "../../assets/SIGA.jpg";
import { useHistory } from "react-router-dom";
import useTranslate from "../../hooks/useTranslate";
//#endregion
const Login: React.FC = (props: any) => {
  const { logon } = useContext(AuthContext);
  const [userNameFocus, setUserNameFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {translate} = useTranslate()
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    onSubmit: async (values) => {
      info(translate("validando dados, aguarde."));
      try {
        let login: any = await logon(values);
        if (login.Usuario == undefined) {
          throw "relo";
        }
        if (login.error) {
          dismiss();
          return warnn(`${login.message}`);
        }
        dismiss();
        success(`${translate("bem vindo")}, ${values.userName}`);
        history.push("/home");
      } catch (err) {
        dismiss();
        warnn(translate("erro desconhecido, consulte o suporte"));
      }
    },
  });

  useEffect(() => {
    if (props?.location?.state?.logoff) {
      dismiss();
      warnn(translate("deslogado com sucesso!"));
    }
    if (props?.location?.state?.redirect) {
      dismiss();
      warnn(translate("usuario nao autenticado"));
    }
  }, []);

  const { handleChange, handleSubmit, errors, values } = formik;
  return (
    <>
      {/* <IdepenteImage src={LogoLbr} alt="logo farm4all" /> */}
      <Container>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <img
            style={{ height: "100%", transform: "scale(3.5)" }}
            src={SigLogo}
            alt=""
          />
          <h1>{translate("entrar")}</h1>
          <p>{translate("faca login, para ter acesso aos seus dashsboard")}</p>
          <ContainerInput>
            <AiOutlineUser
              color={userNameFocus && !!values.userName ? "#70a5ff" : "#000"}
            />
            <Input
              focus={userNameFocus && !!values.userName}
              onFocus={() => setUserNameFocus(true)}
              name="userName"
              onChange={handleChange}
              placeholder={translate("usuario")}
            />
            {errors.userName && <p>{errors.userName}</p>}
          </ContainerInput>
          <ContainerInput>
            <AiOutlineKey
              color={passwordFocus && !!values.password ? "#70a5ff" : "#000"}
            />
            <Input
              focus={passwordFocus && !!values.password}
              onFocus={() => setPasswordFocus(true)}
              name="password"
              type={showPassword ? "" : "password"}
              onChange={handleChange}
              placeholder={translate("senha")}
            />
            {showPassword ? (
              <AiOutlineEye
                style={{ cursor: "pointer" }}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <AiOutlineEyeInvisible
                style={{ cursor: "pointer" }}
                onClick={() => setShowPassword(true)}
              />
            )}
          </ContainerInput>
          <button>{translate("entrar")}</button>
          <small className='showInSmall'>{translate("atencao, o SIG e otimizado para tela maiores, utilize um dispositivo com tela maior")}</small>
        </Form>
        <Content>
          <img
            className="invisibledInMobile"
            src={SigB}
            width="1030px"
            style={{ marginTop: "10px" }}
          />
       
        </Content>
      </Container>
    </>
  );
};

export default Login;
