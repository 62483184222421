import React from "react";
import { CSSProperties } from "styled-components";
import { Container } from "./style";
interface IProps {
  style?: CSSProperties;
}
const Col: React.FC<IProps> = ({ children, style }) => {
  return <Container style={style}>{children}</Container>;
};

export default Col;
