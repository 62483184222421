import styled from 'styled-components'
interface ICard {
    srcUrl?: string;
}
export const Card = styled.div`
    .rowContainer {
        display: flex;
        gap:13px;
        flex-direction: row;
        align-items:center;
        .percent {
            font-size: 15px;
            font-weight: 500;
        }
    }
    .colContainer {
        display:flex;
        flex-direction: column;
        h1 {
            font-weight: bold;
            font-size: 13px;
        }   
        h2 {
            font-size: 10px;
            font-weight: 500;
        }
    }
`
export const Image = styled.img<ICard>`
width: 38px;
border: 3px solid #fff;
height: 30px;
border-radius: 100%;
background-size: cover;
background-image: url(${({srcUrl}) => srcUrl});
`