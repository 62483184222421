import React, { useRef, useState } from "react";
import { Input, Text } from "./style";
interface IChangeInputTextTitle {
  value?: string;
  settingNewValueUpdated(textValue: string): void;
  subtitle?: boolean;
  id: any;
  name: string;
  editMode: boolean;
  isTitle?: boolean;
  blurValue?: (e: any) => void;
  isInDraggableComponent?: boolean;
}

export const ChangeTitle: React.FC<IChangeInputTextTitle> = ({
  value,
  settingNewValueUpdated,
  subtitle,
  isTitle,
  editMode,
  blurValue,
  isInDraggableComponent,
}) => {
  const [whatsShow, setWhatsShow] = useState("text");
  const changeTitleRef = useRef<HTMLInputElement>(null);
  const changedInput = () => {
    if (editMode) {
      setWhatsShow("input");
      changeTitleRef.current?.focus();
    }
  };
  return (
    <>
      <Input
        isInDraggableComponent={isInDraggableComponent}
        data-for="title"
        data-tip="editar titulo"
        data-iscapture="true"
        subtitle={subtitle}
        name="title"
        ref={changeTitleRef}
        onChange={(e) => settingNewValueUpdated(e.target.value)}
        onBlur={(e) => (
          settingNewValueUpdated(e.target.value),
          setWhatsShow("text"),
          blurValue && blurValue(e.target.value)
        )}
        stateView={whatsShow}
        value={value}
      />
      <Text
        isTitle={isTitle || false}
        initial={!isInDraggableComponent && { x: -100, opacity: 0 }}
        animate={!isInDraggableComponent && { x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        subtitle={subtitle}
        onClick={changedInput}
        stateView={whatsShow}
      >
        {value}
      </Text>
    </>
  );
};
