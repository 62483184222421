import { useSelector } from "react-redux";

interface IProfile {
  user: {
    Usuario: string;
    Empresa: string;
    Email: string;
    CodEmpresa: string;
    Layout: string;
    Base64ProfileImg:string;
    Idioma: string;
    UrlApiFarm4all: string;
    UsuarioApiFarm4all: string;
    SenhaApiFarm4all: string;
    Dashboards: any[];
    Produtos: {
      NomeProduto: string,
      ProdutoId: number
    }[];
  };
}

interface IReturn {
  userInformation: {
    Usuario: string;
    Empresa: string;
    CodEmpresa: string;
    Base64ProfileImg:string;
    Email: string;
    Layout: string;
    Idioma: string;
    UrlApiFarm4all: string;
    UsuarioApiFarm4all: string;
    SenhaApiFarm4all: string;
    Dashboards: any[];
    Produtos: {
      NomeProduto: string,
      ProdutoId: number
    }[];
  };
}
const useUser = (): IReturn => {
  const userInformation = useSelector<IProfile, any>((state) => state?.user);
  return { userInformation };
};
export default useUser;
