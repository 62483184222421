import styled, { css } from "styled-components";
import theme from "../../styles/theme";
export const Item = styled.div`
  background-color: #ffffff;
  border: none !important;
  border-radius: 4px;
  display: flex;
  min-width: 300px;
  min-height: 80px;
`;
export const Container = styled.div`
  min-height: 90vh;
  min-width: 100vw;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  span {
    pointer-events: all;
  }
`;
export const TitleContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
  width:100% !important;
  }
  @media only screen and (max-width: ${theme.ligth.mediumDimension}) {
  width:100% !important;
  }
`;
