import styled from "styled-components";

export const KpiContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  align-items: left;
  align-content: left;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
