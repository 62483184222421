import { Reducer } from "redux";
import produce from "immer";

const INITIAL_STATE: any = {};

const dashboardLayout: Reducer<any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_FILTER": {
      return produce(state, (draft: any) => {
        const { indicator, title, value, key, information = '' } = action.payload;
        return {
          ...draft,
          [indicator]: {
            ...draft[indicator],
            filtersApply: {
              ...draft[indicator]?.filtersApply,
              [key]: {
                title,
                value,
                information,
              },
            },
          },
        };
      });
    }

    case "UPDATE_MULTFILTER": {
      return produce(state, (draft: any) => {
        const { indicator, values, key } = action.payload;
        return {
          ...draft,
          [indicator]: {
            ...draft[indicator],
            multiFilter: {
              ...draft[indicator]?.multiFilter,
              [key]: values,
            },
          },
        };
      });
    }
    case "REMOVE_FILTER": {
      return produce(state, (draft: any) => {
        delete draft[action.payload.indicator].filtersApply[action.payload.key];
        return;
      });
    }
    case "REMOVE_SECTION": {
      return produce(state, (draft: any) => {
        delete draft[action.payload.indicator].filtersApply[action.payload.key];
        return;
      });
    }
    case "CLEAR_FILTER_LIST": {
      return produce(state, (draft: any) => {
        return (draft = {});
      });
    }
    case "SET_YEAR": {
      return produce(state, (draft: any) => {
        const { indicator, value } = action.payload;
        return {
          ...draft,
          [indicator]: {
            ...draft[indicator],
            year: value,
          },
        };
      });
    }
    case "SET_VISUALIZATION": {
      return produce(state, (draft: any) => {
        const { indicator, value } = action.payload;
        return {
          ...draft,
          ...draft,
          [indicator]: {
            ...draft[indicator],
            visualization: value,
          },
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default dashboardLayout;
