import React, { memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import HideModal from "../../../Components/hideModal";
import Table from "../../../Components/table";
import { KpiContainer, Container, Legend as LegendTable } from "../style";
import { flatten, groupBy, orderBy, sumBy, toNumber } from "lodash";
import { format, sub } from "date-fns";
import useFilter from "../../../hooks/useFilter";
import useUser from "../../../hooks/useUser";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import useTranslate from "../../../hooks/useTranslate";
import { IDataApi } from "../interface";

const LancamentosEncerradosDia: React.FC<any> = (props) => {
  const { translate } = useTranslate();
  const [data, setData] = useState<IDataApi[]>([]);
  const { userInformation } = useUser();

  const [listAppoint, setListappoint] = useState<string[]>([]);
  const [listFarm, setListFarm] = useState<string[]>([]);
  const [columns, setColumns] = useState<any>([]);
  const [show, setShow] = useState<any | boolean>("");
  const formatApontamento = () => {
    const farm: any = groupBy(data, "DscFazenda");
    const appoints: any = groupBy(data, "Apontamento");
    const listFarm: string[] = [];
    const listAppointment: string[] = [];
    Object.keys(farm).map((item) => listFarm.push(item));
    Object.keys(appoints).map((item) => listAppointment.push(item));
    setListFarm(listFarm);
    setListappoint(listAppointment);
    setColumns(appoints);
  };
  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation]);
  useEffect(() => {
    formatApontamento();
  }, [data]);
  const fetchData = async () => {
    const today = format(new Date(), "yyyy-MM-dd");
    const sevenDayBefore = format(
      sub(new Date(), {
        days: 6,
      }),
      "yyyy-MM-dd"
    );
    const { data: apontamentosEncerrados } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/sig/indicadores/apontamentosEncerrados?Data=${today}`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );

    setData(apontamentosEncerrados);
  };
  return (
    <>
      {show && <HideModal close={() => setShow(false)} information={show} />}

      <CardBody
        returnTitleUpdated={(e) => props.dto.defineTitle(e)}
        title={props?.dto?.customTitle || translate("lancamentos encerrados do dia")}
      >
        <Container
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <KpiContainer style={{ height: "100%" }}>
            <Table>
              <tr>
                <th>{translate("Apontamentos")}</th>
                {listFarm.map((farmName: string) => (
                  <th>{farmName}</th>
                ))}
              </tr>
              {listAppoint.map((farmName: string, i: number) => (
                 
                <tr>
                  <td>{translate(farmName)}</td>
                  {columns[farmName].map((farmReturn: any, index: number) => {
                    const id = `tag${i}${index}${farmReturn["QtdEmAberto"]}${farmReturn["QtdEncerrada"]}`;
                    return (
                      <td
                        data-for={id}
                        data-tip={`${translate("em aberto")}: ${farmReturn["QtdEmAberto"]}`}
                        data-iscapture="true"
                        onClick={() =>
                          farmReturn["QtdEmAberto"] != 0 && setShow(farmReturn)
                        }
                        style={{
                          background:
                            farmReturn["QtdEmAberto"] >= 10
                              ? "red"
                              : farmReturn["QtdEmAberto"] == 0
                              ? "#fff"
                              : "yellow",
                          cursor:
                            farmReturn["QtdEmAberto"] > 0 ? "pointer" : "",
                        }}
                        className="center"
                      >
                        {farmReturn["QtdEncerrada"]}

                        <ReactTooltip
                          id={id}
                          place="bottom"
                          type="dark"
                          effect="solid"
                          multiline={true}
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </Table>
          </KpiContainer>
          <LegendTable>
            <small>{translate("legenda")}</small>
            <h1 style={{ background: "yellow" }}>{translate("entre 1 e 10")}</h1>
            <h1 style={{ background: "red" }}>{translate("acima de 10")}</h1>
          </LegendTable>
        </Container>
      </CardBody>
    </>
  );
};

export default LancamentosEncerradosDia;
