import styled from "styled-components";

export const KpiContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  align-items: left;
  overflow: hidden;
  overflow-x: scroll;
  align-content: left;
  ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #00000010;
    border-radius: 10px;
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 10px 3px;
  h1 {
    font-size: 14px;
    padding: 3px 30px;
    margin: 0px 10px;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
    overflow: scroll;
  align-items: center;
  justify-content: space-around;
`;

export const Table = styled.table`
  width: 100%;
  th {
    white-space: nowrap;
    padding: 10px;
    text-transform: uppercase;
    font-size: 14px;
  }
  td {
    white-space: nowrap;
    padding: 10px;
  }
    .center {
    text-align: center;
  }
  tr:hover {
    background: #00000010;
  }
  .vencido {
      border-left: 12px solid #f36161;
     background-color: #ff00000d; 
    td {
    }
  }
  .vencido:hover {
    background-color: #ff00000d;
  }
  .vencido td:hover {
    /* background-color: #f36161; */
  }
`;
