import { Reducer } from "redux";
import produce from "immer";

interface ILanguageState {
  language: string;
}

const INITIAL_STATE: ILanguageState = {
  language: "pt",
};

const language: Reducer<ILanguageState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_USER_LANGUAGE": {
        return produce(state,draft =>{
                draft.language = action.payload
        })
    }
    default: {
      return state;
    }
  }
};

export default language;
