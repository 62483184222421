import React, { useState } from "react";
import {
  Item,
  Title,
  ListItems,
  ItemGroup,
  CloseIcon,
  ContainerButton,
  Button,
} from "./style";
import { v4 as uuid } from "uuid";
import { Link, Redirect, useHistory } from "react-router-dom";
import { servicoInternoApi } from "../../services";
import { AiOutlineForm } from "react-icons/ai";
import IUser from "../../store/modules/user/interface";
import { translate } from "../../utils/translate";
import { useDispatch, useSelector } from "react-redux";
import { info, success, warnn, dismiss } from "../../services/toasty";
import ReactTooltip from "react-tooltip";
import PageTitle from "../../Components/pageTitle";
interface IDashboardUser {
  user: IUser;
}
interface IDashboard {
  DashboardId: string;
  Layout: string;
  Subtitulo: string;
  Titulo: string;
}
const HomeDashboard: React.FC = () => {
  const [redirect, setRedirect] = useState(false);
  const { language } = useSelector<any, any>((props) => props);
  const [id, setId] = useState<string | number>();
  const [disabledDadComponent, setDisabledDadComponent] = useState(false);
  const data = useSelector<IDashboardUser, any>((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  if (redirect) {
    return <Redirect to={`/dashboard/${id}`} />;
  }

  const deleteItem = async (id: string) => {
    let confirm = window.confirm(translate(language.language, "deseja realmente eliminar este dashboard?"));

    if (confirm) {
      info(translate(language.language, "deletando dashboard, aguarde"));
      try {
        await servicoInternoApi.post(
          `removerDashboard?UsuarioSigId=${data.Usuario}&DashboardId=${id}`
        );
        dispatch({
          type: "REMOVE_DASHBOARD",
          payload: id,
        });
        dismiss();
      } catch (error) {
        warnn(error);
      }
    }
  };

  const createNewDashboard = async () => {
    try {
      info(translate(language.language, "criando dashboard, aguarde um momento"));
      const dataToInsert = {
        DashboardId: uuid().substr(10),
        UsuarioSigId: data.Usuario,
        Layout: "",
        Titulo: translate(language.language, "titulo dashboard"),
        Subtitulo: translate(language.language, "descrição dadshboard"),
      };
      const creatingDashboard = await servicoInternoApi.post(
        "/adicionarDashboard",
        dataToInsert
      );

      if (creatingDashboard) {
        dispatch({
          type: "INSERT_NEW_DASHBOARD",
          payload: dataToInsert,
        });
        dismiss();
        return success(translate(language.language, "novo dashboard adicionado"));
      }
      return warnn(translate(language.language, "Erro, dashboard não foi criado"));
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <>
      <Title>
        <PageTitle>
          <h1>DASHBOARDS</h1>
          <small>{translate(language.language, "ir para um dashboard personalizado")}</small>
        </PageTitle>
        <ContainerButton>
          <Button
            style={{ overflow: "hidden" }}
            type="button"
            editMode={true}
            onClick={createNewDashboard}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
              }}
            >
              <AiOutlineForm style={{ marginRight: "10px" }} />
               {translate(language.language, "novo dashboard")}
            </div>
          </Button>
        </ContainerButton>
      </Title>
      <ListItems>
        {data?.Dashboards?.map((item: IDashboard, index: number) => (
          <>
            <ItemGroup
              onClick={(e) =>
                !disabledDadComponent &&
                history.push(`/dashboard/${item.DashboardId}`)
              }
              key={item.DashboardId}
            >
              <Item className="draggableHandle">
                <div
                  id="dataInformation"
                  style={{
                    justifyContent: "flex-end",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <CloseIcon
                      data-for={item.DashboardId}
                      data-tip={translate(language.language, "excluir dashboard")}
                      data-iscapture="true"
                      onMouseEnter={() => setDisabledDadComponent(true)}
                      onMouseLeave={() => setDisabledDadComponent(false)}
                      onClick={() => deleteItem(item.DashboardId)}
                    />
                  </div>
                </div>
                <section>
                  <h1 style={{ pointerEvents: "none" }}>{item.Titulo}</h1>
                  <p style={{ pointerEvents: "none" }}>{item.Subtitulo}</p>
                </section>
              </Item>
            </ItemGroup>
            <ReactTooltip
              id={item.DashboardId}
              place="bottom"
              type="dark"
              effect="solid"
              multiline={true}
            />
            <ReactTooltip
              id={`${item.DashboardId}_viewDash`}
              place="bottom"
              type="dark"
              effect="solid"
              multiline={true}
            />
          </>
        ))}
      </ListItems>
    </>
  );
};

export default HomeDashboard;
