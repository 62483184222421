import React, {
  CSSProperties,
  StyleHTMLAttributes,
  useCallback,
  useContext,
  useState,
} from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { EditModeContext } from "../../contexts/editmode.context";
import { ChangeTitle } from "../changeTextInput";
import { Body, Header } from "./style";
interface ICard {
  title?: string;
  isInMap?: boolean;
  width?: boolean;
  noHeader?: boolean;
  noCentered?: boolean;
  id?: any;
  style?: CSSProperties;
  scroll?: boolean;
  subtitle?: string;
  returnTitleUpdated?: (e: string) => void;
}
const Card: React.FC<ICard> = ({
  children,
  title,
  style,
  subtitle,
  returnTitleUpdated,
  id,
}) => {
  const [defaultTitle, setDefaultTitle] = useState(title);
  const { pathname } = useLocation();
  const [, pageName] = pathname.split("/");
  const { editMode } = useContext(EditModeContext);
  
  return (
    <>
      <Body style={style} title={title}>
        {title && <Header className="draggableHandle grabbingDrag">
          {pageName == "dashboard" ? (
            <ChangeTitle
              editMode={editMode}
              name="Titulo"
              value={defaultTitle}
              isInDraggableComponent={true}
              blurValue={(e) => returnTitleUpdated && returnTitleUpdated(e)}
              id={title}
              settingNewValueUpdated={(e) => setDefaultTitle(e)}
            />
          ) : (
            <>
              <h1>{title}</h1>
              <small style={{ marginLeft: '10px', opacity: .5, fontWeight: 'bold' }}>{subtitle && subtitle}</small>
            </>
            )}
        </Header>}
        {children}
      </Body>
    </>
  );
};
export default Card;
