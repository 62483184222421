//#region imports
import React, { useCallback, useContext, useState } from "react";
import {
  Card,
  Header,
  Image,
  Item,
  HeaderContainer,
  Select,
  HideButton,
  ProfilePhotoChange,
} from "./style";
import { useDispatch, useSelector } from "react-redux";
import { changeUserLanguage } from "../../store/modules/language/actions";
import { EditModeContext } from "../../contexts/openFilter.context";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../contexts/auth.context";
import { servicoInternoApi as api } from "../../services/index";
import useUser from "../../hooks/useUser";
import theme from "../../styles/theme";
import { translate } from "../../utils/translate";
import Link from "../../Components/link";
import SideMenuMock from "../../utils/sideMenuItems";
import useTranslate from "../../hooks/useTranslate";
import ReactTooltip from "react-tooltip";
interface IProps {
  activeItem?: string;
}
//#endregion

const SideMenu: React.FC<IProps> = ({ activeItem }) => {
  const { userInformation } = useUser();
  const { resetInfo } = useContext(AuthContext);
  const { translate } = useTranslate();
  const { language } = useSelector<any, any>((props) => props);

  const [exitPage, setExit] = useState(false);
  const dispatch = useDispatch();
  const handleChangeLanguage = useCallback(
    (language: string) => {
      dispatch(changeUserLanguage(language));
    },
    [dispatch]
  );
  function exit() {
    const confirm = window.confirm(translate("tem certeza que deseja sair?"));
    if (confirm) {
      localStorage.clear();
      resetInfo();
      setExit(true);
    }
  };
  const { show, changeVisualization } = useContext(EditModeContext);
  if (exitPage) {
    return <Redirect to={{ pathname: "/", state: { logoff: true } }} />;
  }
  const processImage = (file: any) => {
    const image = file[0];
    const reader: any = new FileReader();
    if (reader) {
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        dispatch({
          type: "SET_NEW_IMAGE",
          payload: reader?.result?.replace(
            image.type === "image/jpeg"
              ? "data:image/jpeg;base64,"
              : "data:image/png;base64,",
            ""
          ),
        });
        api.post(
          `inserirFotoUsuario`,
          {
            Usuario: userInformation.Usuario,
            Base64ProfileImage: reader.result.replace(
              image.type === "image/jpeg"
                ? "data:image/jpeg;base64,"
                : "data:image/png;base64,",
              ""
            ),
          },
          {
            auth: {
              username: userInformation.UsuarioApiFarm4all,
              password: userInformation.SenhaApiFarm4all,
            },
          }
        );
      };
    }
  };
  return (
    <>
      <Card hide={show}>
        <HeaderContainer hide={show}>
          <ProfilePhotoChange
            image={
              `data:image/jpeg;base64,${userInformation.Base64ProfileImg}` ||
              "https://img2.gratispng.com/20180331/eow/kisspng-computer-icons-user-clip-art-user-5abf13db298934.2968784715224718991702.jpg"
            }
            withIcon={false}
            withLabel={false}
            label="Selecionar Imagem"
            buttonStyles={{
              heigth: "100%",
              width: "100%",
              background: "red",
              position: "absolute",
              opacity: 0,
            }}
            singleImage={true}
            buttonText="Choose images"
            onChange={(e) => processImage(e)}
            imgExtension={[".jpg"]}
            maxFileSize={5242880}
          />
          <Header hide={show}>
            <small>{translate("bem vindo")},</small>
            <h1>{userInformation?.Usuario}</h1>
          </Header>
          <HideButton
            hide={show}
            data-for="hideMenu"
            data-tip={show ? translate("esconder menu") : translate("expandir menu")}
            onClick={(e) => changeVisualization()}
            color={theme.ligth.textColor}
            size="20px"
          />
        </HeaderContainer>
        <Item hide={show} isEveryHover={true}>
          <Select onChange={(e) => handleChangeLanguage(e.target.value)}>
            <option value="pt">{translate("portugues")}</option>
            <option value="eng">{translate("ingles")}</option>
            <option value="esp">{translate("espanhol")}</option>
            <option value="fr">{translate("frances")}</option>
          </Select>
        </Item>
        {SideMenuMock.map(({ url, name, icon: Icon, category }) => {
          
          if (name === "divisor") {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  borderTop: "1px solid rgb(175 175 175)",
                }}
              />
            );
          }
          if (name == "Sair") {
            return (
              <div
                onClick={exit}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100%",
                  padding: "10px",
                  backgroundColor: "#ff2e2e",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <Icon color="#fff" size="20px" />
              </div>
            );
          }
          if (category === "generic") {
            return (
              <Link
                hide={show}
                active={url == activeItem}
                name={translate(name)}
                url={url}
              >
                <Icon color={theme.ligth.textColor} size="20px" />
              </Link>
            )
          }

          return (
            userInformation?.Produtos?.map(({ NomeProduto }) => {
              return (NomeProduto === category && (
                <Link
                  hide={show}
                  active={url == activeItem}
                  name={translate(name)}
                  url={url}
                >
                  <Icon color={theme.ligth.textColor} size="20px" />
                </Link>
              ))
            })
          )



        })}

      </Card>
      <ReactTooltip
        id="hideMenu"
        place="right"
        type="dark"
        effect="solid"
        multiline={true}
      />
    </>
  );
};

export default SideMenu;
