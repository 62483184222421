import { useCallback, useEffect, useState } from "react";
import { Layout } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
interface Ilayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  id: string;
  title: string;
}
interface IDataLayout {
  dashboardSelected: {
    layout: any;
  };
}
const useDropzone = () => {
  const { layout } = useSelector<IDataLayout, any>(
    ({ dashboardSelected }) => dashboardSelected
  );
  const dispatch = useDispatch();
  const globalStateUpdateLayout = useCallback(async (updatedValue: any) => {
    await dispatch({
      type: "SET_DASHBOARD_LAYOUT",
      payload: updatedValue,
    });
  }, []);

  const onDrop = (test: any[], item: any, event: any) => {
    let id = event.dataTransfer.getData("id");
    let component = event.dataTransfer.getData("component");
    const { x, y } = item;
    let position = {
      x,
      y,
      w: 3,
      h: 3,
      i: component,
      id,
    };

    globalStateUpdateLayout(!!layout ? [...layout, position] : [position]);
  };
  const updateLayout = async (allLayout: Layout[], title?: string) => {
    const asyncUpdatedLayout = new Promise((resolve, reject) => {
      const updatedLayout = allLayout.map((item) => {
        const [i, id, title] = item.i.split("|");
        if (title) {
          return {
            ...item,
            i,
            id,
            title,
          };
        }
        return {
          ...item,
          i,
          id,
        };
      });
      if (updatedLayout) {
        resolve(updatedLayout);
      }
      reject(false);
    });
    globalStateUpdateLayout(await asyncUpdatedLayout);
  };
  const updateTitle = async (
    allLayout: Ilayout[],
    title?: string,
    id?: string
  ) => {
    const asyncUpdatedLayout = new Promise((resolve, reject) => {
      const updatedValue = () => {
        const groupNotHaveThisLayout = allLayout.filter(
          (props) => props.id != id
        );
        const newTitle = allLayout.filter((props) => props.id == id);
        const updateVal = { ...newTitle[0], title };
        return [...groupNotHaveThisLayout, updateVal];
      };
      if (updatedValue()) {
        resolve(updatedValue());
      }
      reject(false);
    });
    globalStateUpdateLayout(await asyncUpdatedLayout);
  };

  const closeComponent = async (componentId: string) => {
    let componentsFiltered = layout?.filter(
      (items: Ilayout) => items.id !== componentId
    );
    globalStateUpdateLayout(componentsFiltered);
  };
  return { layout, onDrop, updateLayout, closeComponent, updateTitle };
};

export default useDropzone;
