import React, { createContext, useState, useCallback } from "react";

interface ITvmodeContext {
  changeMode(mode: boolean): void;
  mode: boolean;
}
export const TvmodeContext = createContext<ITvmodeContext>(
  {} as ITvmodeContext
);

export const TvmodeProvider: React.FC = ({ children }) => {
  const [mode, setMode] = useState(false);
  const changeMode = useCallback((mode) => {
    setMode(mode);
  }, []);

  return (
    <TvmodeContext.Provider
      value={{
        changeMode,
        mode,
      }}
    >
      {children}
    </TvmodeContext.Provider>
  );
};
