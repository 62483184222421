import { createGlobalStyle, css } from "styled-components";
import theme from "./theme";
import BackgroundLogin from "../assets/BackLogin.jpg";
import { boolean } from "yup";
interface IProps {
  hide?: boolean;
  tv?:boolean;
  isLogin?: boolean;
}
export const GlobalStyle = createGlobalStyle<IProps>`  
body {
  background: ${theme.dark.backgroundBody};
}
    #root{
      width:100%;
      font-family: "Roboto";
      transition:.1s;
      max-width:1200px;
      margin-left: 17%;
    }
    ${({ isLogin }) =>
      isLogin &&
      css`
        body {
          /* background-image: url(${BackgroundLogin}); */
          /* background-size: cover; */
          /* background-repeat: no-repeat; */
        }
      `}
    ${({ hide }) =>
      !hide &&
      css`
        #root {
          max-width: 1440px;
          margin-left: 6%;
          transition: 0.1s;
        }
      `}
      @media only screen and (max-width: ${theme.ligth.mediumDimension}) {
         #root{
         max-width: 1024px;
          margin-left: 23%;
         } 
      ${({ hide }) =>
        !hide &&
        css`
          #root {
            max-width: 1200px;
            margin-left: 9%;
            transition: 0.1s;
          }
        `}}
      @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
        #root {
          max-width:${theme.ligth.mobileDimension};
          margin:10px;
          }
      ${({ hide }) =>
        !hide &&
        css`
          #root {
            max-width: ${theme.ligth.mobileDimension};
            transition: 0.1s;
          }
        `}
    }
  ${({tv}) => tv && css`
      #root{
      width:100%;
      font-family: "Roboto";
      transition:.1s;
      max-width:100%;

      margin-left: 10px;
    }
  
  `}
`;

export const GlobalStyleAll = createGlobalStyle`
  ::-webkit-scrollbar {
      width: 8px;
  }
  ::-webkit-scrollbar-thumb {
      background: #d6d6d6;
      border-radius: 10%;
  }
  ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.20);
  }
  
  * {
    padding:0;
    margin:0;
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    font-family: Roboto,sans-serif;
    
  }
  p, h1, button, h2, h3, h4, table {
        font-family:'Roboto';
        font-weight:300;
  }
`;
