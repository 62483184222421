import styled from "styled-components";
import theme from "../../styles/theme";
import Draggable from "react-draggable";

interface IProps {
  show?: boolean;
}
export const Container = styled(Draggable)<IProps>`
  @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
    position: static;
    max-width: 97%;
  }
  @media only screen and (max-width: ${theme.ligth.mediumDimension}) {
    position: static;
    width: 100%;
  }
`;
export const ContainerModal = styled.div`
  padding: 20px 10px;
  width: 350px;
  box-shadow:0px -1px 40px -20px black;
  position: absolute;
  height: 420px;
  cursor: grabbing;
  z-index:99999999999999999999999999999999999999999999999;
  background-color: #fff;
  border: 1px solid #00000010;
  margin: 0 auto;
  border-radius: 8px;
  h1 {
    font-size: 18px;
  }
  small {
    font-size: 12px;
  }
  section {
    flex-basis: 70%;
    flex-direction: column;
    display: flex;
    width: 100%;
  }
`;
export const ContainerHeader = styled.div`
  width: 100%;
  align-items:center;
  height: 50px;
  display:flex;
  justify-content:space-between;
  /* position: absolute; */
  flex-direction:row;
  left: 0;
  top: 0;
  h1 {
    font-size: 18px;
  }
  small {
    font-size: 12px;
    text-transform:lowercase;
  }
`;
