import React, { createContext, useState } from "react";

interface IEditModeContext {
  show: boolean;
  changeVisualization: () => void;
}
export const EditModeContext = createContext<IEditModeContext>(
  {} as IEditModeContext
);

export const OpenFilterProvider: React.FC = ({ children }) => {
  const [show, setShow] = useState<boolean>(false);
  const changeVisualization = () => {
    setShow((e) => !e);
  };

  return (
    <EditModeContext.Provider
      value={{
        show,
        changeVisualization,
      }}
    >
      {children}
    </EditModeContext.Provider>
  );
};
