//#region imports
import React, { useContext } from "react";
import AnaliseProdutividade from "../../reports/analiseProdutividade";
import Filter from "../../Components/filter";
import PageTitle from "../../Components/pageTitle";
import ButtonToTv from "../../Components/buttonToTv";
import PageBody from "../../Components/pageBody";
import { translate } from "../../utils/translate";
import {TvmodeContext} from '../../contexts/tvmode';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//#endregion

const AnaliseProdutividadePage: React.FC = () => {
  const { pathname } = useLocation();
  const {mode} = useContext(TvmodeContext);

  const state = useSelector<any, any>(({ language }) => language);
  return (
    <>
      <PageTitle>
        <h1>{translate(state.language, "Análise de Produtividade")}</h1>
        <small>
          {translate(
            state.language,
            "visualização de analise de produtividade"
          )}
        </small>
        <ButtonToTv style={{ marginLeft: '94%' }} link="analiseProdutividade/tv" />

      </PageTitle>
      <PageBody>
        {!mode && <Filter
          filters={[
            "CodSafra",
            "CodPeriodoProducao",
            "CodFazenda",
            "CodSetor",
            "CodTalhao",
          ]}
        />}
        <AnaliseProdutividade />
      </PageBody>
    </>
  );
};

export default AnaliseProdutividadePage;
