import styled, { css } from "styled-components";
import theme from "../../styles/theme";
interface ItemInterface {
  active: boolean;
  isInMap?: boolean;
}
export const MenuLink = styled.p<ItemInterface>``;
export const Body = styled.div`
  height: 100%;
  width: 100%;
  overflow: ${({ title }) => title && "hidden"};
  padding: 10px;
  border-radius: 9px;
  box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.11);
  background: ${theme.ligth.backgroundDefault};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  @media only screen and (max-width: 600px) {
    width: 97%;
  }
  .hourInformation {
    font-size: 24px;
  }
  .scroll {
    ::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
    }
    ::-webkit-scrollbar-thumb {
      background: #00000010;
      border-radius: 10px;
    }
  }
`;
export const Header = styled.div`
  display: flex;
  width: 120%;
  color: #fff;
  padding-left: 10px;
  text-align: center;
  background: ${theme.dark.backgroundNeutral};
  left: 0;
  padding-bottom: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  position: relative;
  align-items: center;
  padding-top: 30px;
  padding-left: 18px;
  margin-left: -11px;
  top: -21px;
  h1 {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
  }
  button {
    margin-left: auto;
  }
  
`;

export const Item = styled.p<ItemInterface>`
  padding: 11px 11px;
  border-radius: 8px;
  width: 150px;
  font-size: 12px;
  & + p {
    margin: 20px 0;
  }
  transition: 0.3s;
  ${(props) =>
    props.active &&
    css`
      color: #fff;
      background: #679bff;
      border-radius: 4px;
      color: #fff;
      font-weight: 300;
      cursor: pointer;
      transition: 0.4s;
    `}
  &:hover {
    color: #fff;
    background: #679bff;
    border-radius: 4px;
    font-weight: 300;
    cursor: pointer;
    transition: 0.4s;
  }
  ${(props) =>
    props.isInMap &&
    css`
      &:hover {
        width: 190px;
      }
    `}
`;
