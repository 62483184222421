import styled from "styled-components";

export const KpiContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  align-items: left;
  align-content: left;
@media only screen and (max-width: 600px) {
  flex-direction: column;
  justify-content: center;
  text-align: left;
  flex-basis:0;
  .centerGraph{
    margin:0 auto;
  }
  small{
    margin-top:20px;
  }
}
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;
