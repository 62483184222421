import styled, { css } from 'styled-components'
interface IInputAutoComplet {
    selected?: boolean;
}
export const Input = styled.input<IInputAutoComplet>`
    width: 100%;
    border: none;
    font-size: 14px;
    color: #fff;
    cursor: n-resize;
    padding: 14px 30px;
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #00000059;
    outline: none;
`;
