import React, { memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import HideModal from "../../../Components/hideModal";
import Table from "../../../Components/table";
import { KpiContainer, Container, Legend as LegendTable } from "../style";
import { flatten, groupBy, orderBy, sumBy, toNumber } from "lodash";
import { format, sub } from "date-fns";
import useFilter from "../../../hooks/useFilter";
import useUser from "../../../hooks/useUser";
import axios from "axios";
import NoDataToShow from "../../../Components/noDataToShow";
import useTranslate from "../../../hooks/useTranslate";
import { IMotivoOsEmAberto } from "../interface";

const MotivoOsAberto: React.FC<any> = (props) => {
  const { userInformation } = useUser();
  const [data, setData] = useState<IMotivoOsEmAberto[]>([]);
  const {translate} = useTranslate()
  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation]);

  const fetchData = async () => {
    const { data: dataInfo } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/relatorios/logistica/motivoOsEmAberto`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setData(orderBy(dataInfo, "CodOS"));
  };

  return (
    <>
      <CardBody 
      returnTitleUpdated={(e) => props.dto.defineTitle(e)}
      title={
        props?.dto?.customTitle || translate("analise de oficina - motivo os em aberto")
      }>
        <Container>
          <KpiContainer style={{ height: "100%" }}>
            <small></small>
            {data.length < 1 ? (
              <NoDataToShow />
            ) : (
              <Table style={{ borderCollapse: "collapse" }}>
                <tr>
                  <th>{translate("num o.s")}</th>
                  <th>{translate("fazenda")}</th>
                  <th>{translate("equipamento")}</th>
                  <th>{translate("mecanico responsavel")}</th>
                  <th>{translate("data entrada o.s")}</th>
                  <th>{translate("motivo o.s parada")}</th>
                  <th>{translate("data")}</th>
                  <th>{translate("hora")}</th>
                  <th align="center">{translate("dias parados")}</th>
                </tr>
                
                {data.map((Os: IMotivoOsEmAberto) => (
                  
                  <tr>
                    <td>{Os.CodOS}</td>
                    <td>{Os.CodFazenda}</td>
                    <td>{Os.CodEquipamento}</td>
                    <td>{Os.NomeMecanicoResponsavel}</td>
                    <td>{Os.DataEntradaOS !== undefined ? format(new Date(Os.DataEntradaOS), "dd/MM/yyyy") : ""}</td>
                    <td>{Os.MotivoOSParada}</td>
                    <td>{Os.Data !== undefined ? format(new Date(Os.Data), "dd/MM/yyyy") : ""}</td>
                    <td>{Os.Hora}</td>
                    <td align="center">{Os.DiasParados}</td>
                  </tr>
                ))}
              </Table>
            )}
          </KpiContainer>
        </Container>
      </CardBody>
    </>
  );
};

export default MotivoOsAberto;
