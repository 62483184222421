import { Reducer } from "redux";
import produce from "immer";

const INITIAL_STATE = {

};
const periodoProducao: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_PERIODO_PRODUCAO": {
      return produce(state, (draft: any) => {
        return draft = action.payload;
      });
    }
    default: {
      return state;
    }
  }
};

export default periodoProducao;
