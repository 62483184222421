import React, { forwardRef, useEffect, useState } from "react";
import { DateComponent, Container } from "./style";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import useFilter from "../../hooks/useFilter";
import useTranslate from "../../hooks/useTranslate";
import { format } from "date-fns";

const Datepicker: React.FC = () => {
  const [dateChanged, setDateChanged] = useState(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const { handleAddFilter, filter } = useFilter();
  const {translate} = useTranslate()
  
  function saveStartDate(event: any) {
    setDateChanged(true)
    const start = event.target.value;
    handleAddFilter("dataInicial", start, start, "dataInicial");
    setStartDate(start);
  }
  function saveEndDate(event: any) {
    setDateChanged(true)
    const end = event.target.value;
    handleAddFilter("dataFinal", end, end, "dataFinal");
    setEndDate(end);
  }

  useEffect(() => {
    if (!dateChanged) {
      if (filter?.filtersApply?.CodPeriodoProducao?.information?.DataInicial) {
        const formatDateStart = format(
          new Date(
            filter?.filtersApply?.CodPeriodoProducao?.information?.DataInicial
          ),
          "yyyy-MM-dd"
        );
        setStartDate(formatDateStart);
      }
      if (filter?.filtersApply?.CodPeriodoProducao?.information?.DataFinal) {
        const formatDateEnd = format(
          new Date(
            filter?.filtersApply?.CodPeriodoProducao?.information?.DataFinal
          ),
          "yyyy-MM-dd"
        );
        setEndDate(formatDateEnd);
      }
    }
  }, [filter?.filtersApply]);

  return (
    <Container>
      <>
      <label htmlFor="">{translate("data inicial")}</label>
        <DateComponent onChange={saveStartDate} type="date" value={startDate} />
      <label htmlFor="">{translate("data final")}</label>
        <DateComponent onChange={saveEndDate} type="date" value={endDate} />
      </>
    </Container>
  );
};

export default Datepicker;
