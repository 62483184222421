import React, { memo, useEffect, useState } from "react";
import CardBody from "../../Components/card";
import { Kpi } from "../../styles/globalComponents";
import useFilter from "../../hooks/useFilter";
import { groupBy, sumBy, toNumber } from "lodash";
import NodataToshow from "../../Components/noDataToShow";
import useUser from "../../hooks/useUser";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { info, dismiss } from "../../services/toasty";
import useFetch from "../../hooks/fetchData";
import useTranslate from "../../hooks/useTranslate";
import Row from "../../Components/row";
import GaugeChart from "react-gauge-chart";
import { AnaliseSafra } from "./interface";
import { BiPieChartAlt } from "react-icons/bi";

const ColheitaTalhao: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AnaliseSafra>();
  const { translate } = useTranslate();
  const { userInformation } = useUser();
  const { filter } = useFilter();
  const { fetchPeriodoProducao } = useFetch();

  const fetchData = async () => {
    if (filter?.filtersApply?.CodSafra?.value) {
      info("Carregando dados, Aguarde", true);
      const { data } = await axios.post(
        `${userInformation.UrlApiFarm4all}agricola/sig/analiseSafra`,
        {
          CodFazenda: filter?.filtersApply?.CodFazenda?.value,
          CodSafra: filter?.filtersApply?.CodSafra?.value,
          CodTalhao: filter?.filtersApply?.CodTalhao?.value,
          CodPeriodoProducao: filter?.filtersApply?.CodPeriodoProducao?.value,
          CodSetor: filter?.filtersApply?.CodSetor?.value,
        },
        {
          auth: {
            username: userInformation.UsuarioApiFarm4all,
            password: userInformation.SenhaApiFarm4all,
          },
        }
      );
      setData(data);
      dismiss();
    }
  };

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
      fetchPeriodoProducao(filter?.filtersApply?.CodPeriodoProducao?.value);
    }
  }, [userInformation, filter]);

  if (loading) {
    return (
      <CardBody style={{ maxWidth: "320px" }}>
        <SkeletonTheme color="#20202010" highlightColor="#44444410">
          <Skeleton
            count={1}
            style={{ marginLeft: "0px", marginBottom: "20px" }}
            height={30}
            width={300}
          />
        </SkeletonTheme>
      </CardBody>
    );
  }
  if (!loading && data && data?.PeriodosProducao?.[0]?.Fazendas?.length < 1) {
    return (
      <CardBody style={{ maxWidth: "320px" }}>
        <NodataToshow
          icon={<BiPieChartAlt size="50px" />}
          message={
            !filter?.filtersApply?.CodSafra?.value ? translate("selecione uma safra") : ""
          }
        />
      </CardBody>
    );
  }
  return (
    <>
      <h2 style={{ marginBottom: 10, fontWeight: "bold" }}>{data?.DscSafra}</h2>
      <Row
        style={{ justifyContent: "flex-start", gap: "16px", flexWrap: "wrap" }}
      >
        {data?.PeriodosProducao.map(
          ({ DscCultura, Fazendas, DscPeriodoProducao }) => {
            const totalAreaPlantada = sumBy(Fazendas, "AreaPlantada");
            const totalAreaColhida = sumBy(Fazendas, "AreaColhida");
            if (Fazendas.length > 0) {
              return (
                <CardBody
                  style={{ maxWidth: "300px", marginTop: 0 }}
                  title={DscCultura}
                  subtitle={DscPeriodoProducao}
                >
                  <GaugeChart
                    formatTextValue={(e) => `${e ? toNumber(e).toFixed(2).toLocaleString() : 0} %`}
                    arcPadding={0}
                    cornerRadius={0}
                    animate={false}
                    id="gauge-chart2"
                    percent={totalAreaColhida / totalAreaPlantada}
                    nrOfLevels={3}
                    textColor="#000"
                    needleColor="#345243"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <small>
                      {translate("Area plantada")}{" "}
                      <strong>{toNumber(totalAreaPlantada).toFixed(2).toLocaleString()}</strong>
                    </small>
                    <small>
                      {translate("Area colhido")}{" "}
                      <strong>{toNumber(totalAreaColhida).toFixed(2).toLocaleString()}</strong>
                    </small>
                  </div>
                </CardBody>
              );
            }
          }
        )}
      </Row>
    </>
  );
};

export default memo(ColheitaTalhao);
