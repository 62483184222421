import React, { CSSProperties, memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import { Kpi, Table } from "../../../styles/globalComponents";
import { IPrecoMedioArroba } from "../interface";
import useTranslate from "../../../hooks/useTranslate";
import useUser from "../../../hooks/useUser";
import axios from "axios";
import useSWR from "swr";
interface GenericFunctionType {
  <T>(url: string): Promise<T>;
}
interface IProps {
  style?: CSSProperties;
  dto?: any;
}

const PrecoMedioArroba: React.FC<IProps> = ({ style, dto }) => {
  const { userInformation } = useUser();
  const {translate} = useTranslate()

  const { data } = useSWR(
    userInformation.UrlApiFarm4all &&
      `${userInformation.UrlApiFarm4all}/pecuaria/sig/indicadores/precoMedioArrobaNegociada`,
    (url) => fetchData<IPrecoMedioArroba[]>(url)
  );
  const fetchData: GenericFunctionType = async (url: string) => {
    const { data } = await axios.get(url, {
      auth: {
        username: userInformation.UsuarioApiFarm4all,
        password: userInformation.SenhaApiFarm4all,
      },
    });
    return data;
  };
  const enumSemana: any = {
    Semana_Atual: translate("semana atual"),
    Semana_Passada: translate("semana passada"),
    Semana_Retrasada: translate("semana retrasada"),
  };
  return (
    <CardBody
      returnTitleUpdated={(e) => dto.defineTitle(e)}
      style={style || { marginTop: "0", justifyContent: "stretch" }}
      title={dto?.customTitle || translate("preco medio da arroba negociada")}
    >
      <div
        className="scroll"
        style={{
          overflow: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <Table>
          <thead>
            <tr>
              <th>{translate("semana")}</th>
              <th>{translate("compra")}</th>
              <th>{translate("venda")}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(({ Venda, Compra, Semana }) => {
              return (
                <tr>
                  <td>{enumSemana[Semana]}</td>
                  <td>{Compra}</td>
                  <td>{Venda}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </CardBody>
  );
};

export default memo(PrecoMedioArroba);
