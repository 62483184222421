import React from "react";
import IndicadoresAgricola from "../../reports/indicadoresAgricola";
import ButtonToTv from "../../Components/buttonToTv";
import PageTitle from "../../Components/pageTitle";
import PageBody from "../../Components/pageBody";
import { translate } from "../../utils/translate";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { TitleContainer } from "./style";
const IndicadoresAgricolaPage: React.FC = () => {
  const state = useSelector<any, any>(({ language }) => language);
  const date = format(new Date(), "dd/MM/yyyy HH:mm:ss");
  return (
    <>
      <TitleContainer style={{ width: "120%" }}>
        <PageTitle>
          <h1>{translate(state.language, "indicadores agricola")}</h1>
          <small>
          {translate(state.language, "valores referente a")} <strong>{date}</strong>
          </small>
          <ButtonToTv style={{ marginLeft: '95%' }} link="indicadoresAgricola/tv" />
        </PageTitle>
        {/* <Modal /> */}
      </TitleContainer>
      <PageBody isFullWidth={true}>
        <IndicadoresAgricola />
      </PageBody>
    </>
  );
};

export default IndicadoresAgricolaPage;
