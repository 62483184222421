import { createStore } from "redux";
import rootReducer from "./modules/rootReducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
export interface IState {
  language: string;
}
const persistConfig = {
  key: "userInfomartion",
  storage,
  blacklist: [
    `dashboardSelected`,
    "indicatorSelected",
    // "filter",
    // "months",
    // "visualization",
  ],
};
const reducerPersist = persistReducer(persistConfig, rootReducer);

export const store = createStore(reducerPersist, composeWithDevTools());
export const persistor = persistStore(store);
