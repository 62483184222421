import styled, { css } from "styled-components";
interface IEditmode {
  editMode: boolean;
}
interface IDisabled {
  disabled?: boolean;
  subLevels?: boolean;
  active?:boolean;
  dragging?: boolean;
   verification?: boolean;
}
export const Container = styled.ul<IEditmode>`
  background-color: #2F3243;
  padding: 10px 20px;
  position: fixed;
  width: 330px;
  left: ${(props) => (props.editMode ? "-500px" : "0")};
  top: 0;
  border-radius: 4px;
  transition: 0.5s;
  height: 100vh;
  color: #fff;
`;
export const Name = styled.h1`
  margin-top: 20px;
  font-size: 25px;
  font-weight: 500;
`;
export const Item = styled.li<IDisabled>`
  margin-top: 16px;
  border-radius: 4px;
  list-style: none;
  padding: 17px 17px;
  ${(props) =>
    props.subLevels &&
    css`
    cursor: pointer !important;
      /* background: #34374A; */
      padding: 10px 15px;
      &:hover {
        background: #34374A !important;
        
      }
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed !important ;
      background: #ffffff14;
      opacity: 0.3;
      padding: 17px 37px;
    `}
  ${(props) =>
    props.active &&
    css`
     {
        background: #34374A !important;
        
      }
    `}
  transition: 0.2s;
  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        cursor: grab;
        background: #34374A !important;

      `}
  }
  h1 {
    font-weight: 400;
    font-size: 15px;
  }
  small {
    font-family: "Roboto";
    padding-top: 10px;
    font-weight: 100;
  }
`;
