import { useCallback, useEffect, useState } from "react";
import { Layout } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";

const useFarm:any = () => {
  const farm = useSelector<any | any>(({ farm }) => farm);
  const dispatch = useDispatch();

  const setFarm = (data: any) => {
    dispatch({
      type: "SET_FARM",
      payload: data,
    });
  };
  return { setFarm, farm };
};

export default useFarm;
