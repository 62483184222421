import {
  AiOutlineBarChart,
  AiOutlineControl,
  AiOutlineHome,
  AiOutlineDashboard,
  AiOutlineHeatMap,
} from "react-icons/ai";
import { GiFactoryArm, GiFarmer, GiFarmTractor } from "react-icons/gi";
import { TiWeatherCloudy } from "react-icons/ti";
import { BiExit } from "react-icons/bi";
export default [
  {
    url: "/home",
    name: "Pagina Inicial",
    icon: AiOutlineHome,
    category: "generic"
  },
  {
    url: "/home-dashboard",
    name: "Dashboards",
    icon: AiOutlineDashboard,
    category: "generic"

  },
  {
    url: "/analiseProdutividade",
    name: "Análise de Produtividade",
    icon: AiOutlineBarChart,
    category: "Farm4All Agrícola"

  },
  {
    url: "/comparativoSafra",
    name: "Comparativo Safra",
    icon: GiFarmer,
    category: "generic"

  },
  {
    url: "/indicadoresAgricola",
    name: "Indicadores Agricola",
    icon: GiFarmTractor,
    category: "Farm4All Agrícola"
  },
  {
    url: "/historicoDadosClimaticos",
    name: "Historico dados climaticos",
    icon: TiWeatherCloudy,
    category: "Farm4All Agrícola"
  },
  // {
  //   url: "/dashboardPecuaria",
  //   name: "Dashboard Pecuaria",
  //   icon: FaCottonBureau,
  //   category: "Farm4All Pecuária"


  // },
  // {
  //   url: "/indicaoresAlgodoeira",
  //   name: "Indicadores Algodoeira",
  //   icon: FaCottonBureau,

  // },

  {
    url: "/painelOficina",
    name: "Painel Oficina",
    icon: AiOutlineControl,
    category: "generic"

  },
  {
    url: "/map",
    name: "Mapa",
    icon: AiOutlineHeatMap,
    category: "generic"
    
  },
  {
    url: "/pecuaria",
    name: "Pecuaria",
    icon: GiFactoryArm,
    category: "Farm4All Pecuária"

  },
  {
    url: "",
    name: "Sair",
    icon: BiExit,
    category: "generic"

  },
];
