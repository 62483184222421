import { useCallback, useEffect, useState } from "react";
import { Layout } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";


const useSector = () => {
  const sector = useSelector<any | any>(({sector}) => sector );
  const dispatch = useDispatch();
  
  const setSector = (data:any) => {
    dispatch({
      type: "SET_SECTOR",
      payload: data,
    });
  };
  
  return { setSector, sector };
};

export default useSector;
