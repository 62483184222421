import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  th {
    white-space: nowrap;
    padding: 10px;
    text-transform: uppercase;
    font-size: 14px;
  }
  td {
    white-space: nowrap;
    padding: 10px;
  }
  .center {
    text-align: center;
  }
  tr:hover {
    background: #00000010;
  }
  .vencido {
    border-left: 12px solid #f36161;
    background-color: #ff00000d;
  }
  .vencido:hover {
    background-color: #ff00000d;
  }
  .vencido td:hover {
    /* background-color: #f36161; */
  }
  .warn {
    border-left: 6px solid #f36161;
    background-color: #ff00000d;
  }
  .danger {
    border-left: 6px solid #f3e461;
    background-color: #d7c10850;
  }
  .ok {
    border-left: 6px solid #61a9f3;
    background-color: #0369b22e;
  }
  .success {
    border-left: 6px solid #61f380;
    background-color: #06b20350;
  }
`;
