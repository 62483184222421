import React, { memo, useEffect, useState } from "react";
import CardBody from "../../Components/card";
import HideModal from "../../Components/hideModal";
import LancamentosEncerradosDia from "./lancamentosEncerradosDia";
import VolumeClimatico from "./volumeClimatico";
import VolumeAbastecimento from "./volumeAbastecimento";
import InsumosAplicados from "./insumosAplicados";
import VencimentoCnh from "./vencimentoCnh";
import DuracaoProjetada from "./duracaoProjetada";
import useFilter from "../../hooks/useFilter";
import useUser from "../../hooks/useUser";
import ManutencaoPreventiva from "./manutencaoPreventiva";
import MotivoOsAberto from "./motivoOsAberto";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { IDataApi, IInsumosAplicados } from "./interface";
import Col from "../../Components/column";
import useTranslate from "../../hooks/useTranslate";

const IndicadoresAgricola: React.FC = () => {
  const { filter } = useFilter();
  const [insumosAplicados, setDataInsumosAplicados] = useState<
    IInsumosAplicados[]
  >([]);
  const { translate } = useTranslate();

  const { userInformation } = useUser();
  const [show, setShow] = useState<any | boolean>("");
  const [loading, setLoadgin] = useState(true);

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation, filter]);

  const fetchData = async () => {
    setDataInsumosAplicados(insumosAplicados);
    setLoadgin(false);
  };

  if (loading) {
    return (
      <CardBody>
        <SkeletonTheme color="#20202010" highlightColor="#44444410">
          <Skeleton
            count={1}
            style={{ marginLeft: "10px", marginBottom: "20px" }}
            height={30}
            width={300}
          />
        </SkeletonTheme>
        <SkeletonTheme color="#20202010" highlightColor="#44444410">
          <Skeleton
            count={8}
            style={{ marginLeft: "10px", marginBottom: "20px" }}
            height={20}
            width={150}
          />
          <Skeleton
            count={8}
            style={{ marginLeft: "10px" }}
            height={10}
            delay={1}
            width={150}
          />
          <Skeleton
            count={8}
            style={{ marginLeft: "10px" }}
            height={10}
            delay={1}
            width={150}
          />
          <Skeleton
            count={8}
            style={{ marginLeft: "10px" }}
            height={10}
            delay={1}
            width={150}
          />
          <Skeleton
            count={8}
            style={{ marginLeft: "10px" }}
            height={10}
            delay={1}
            width={150}
          />
        </SkeletonTheme>
        <SkeletonTheme color="#20202010" highlightColor="#44444410">
          <Skeleton
            count={3}
            style={{ marginLeft: "10px", marginBottom: "20px" }}
            width={20}
          />
        </SkeletonTheme>
      </CardBody>
    );
  }

  return (
    <>
      {show && <HideModal close={() => setShow(false)} information={show} />}
      <Col>
        <LancamentosEncerradosDia />
        <VolumeClimatico />
        <InsumosAplicados />
        <VolumeAbastecimento />
        <VencimentoCnh />
        <MotivoOsAberto />
        <ManutencaoPreventiva />
        <DuracaoProjetada />
      </Col>
      <br />
    </>
  );
};

export default memo(IndicadoresAgricola);
