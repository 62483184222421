import React, { memo, useEffect, useState } from "react";
import CardBody from "../../Components/card";
import HideModal from "../../Components/hideModal";
import Table from "../../Components/table";
import { KpiContainer, Container, Legend as LegendTable } from "./style";
import { flatten, groupBy, orderBy, sumBy, toNumber } from "lodash";
import { format, sub } from "date-fns";
import useFilter from "../../hooks/useFilter";
import useTranslate from "../../hooks/useTranslate";
import useUser from "../../hooks/useUser";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NoDataToShow from "../../Components/noDataToShow";
import {
  IAlertaVencimentoCnh,
  IDataApi,
  IInsumosAplicados,
  IManutencaoPreventiva,
  IMotivoOsEmAberto,
  IVolumeAplicado,
  DuracaoProjetadaCombustivel,
  VolumeClimatico,
} from "./interface";
const ColheitaTalhao: React.FC = () => {
  const {translate} = useTranslate()
  const { filter } = useFilter();
  const [data, setData] = useState<IDataApi[]>([]);
  const [insumosAplicados, setDataInsumosAplicados] = useState<
    IInsumosAplicados[]
  >([]);
  const [duracaoProjetada, setDuracaoProjetada] = useState<
    DuracaoProjetadaCombustivel[]
  >([]);
  const [volumeClimatico, setVolumeClimatico] = useState<VolumeClimatico[]>([]);
  const [abastecimento, setAbastecimento] = useState<IVolumeAplicado[]>([]);
  const [motivoOsAberta, setMotivoOsAberta] = useState<IMotivoOsEmAberto[]>([]);
  const [manutencaoPreventiva, setManutencaoPreventiva] = useState<
    IManutencaoPreventiva[]
  >([]);
  const [listAppoint, setListappoint] = useState<string[]>([]);
  const [alertaVencimentoCnh, setAlertaVencimentoCnh] = useState<
    IAlertaVencimentoCnh[]
  >([]);
  const [listFuelDuration, setListFuelDuration] = useState<any>([]);
  const [listFuel, setListFuel] = useState<any>([]);
  const [listFarm, setListFarm] = useState<string[]>([]);
  const [listInsumos, setInsumosList] = useState<any>([]);
  const [listVolumeClimatico, setVolumeClimaticoList] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const { userInformation } = useUser();
  const [show, setShow] = useState<any | boolean>("");
  const [loading, setLoadgin] = useState(true);

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation, filter]);
  useEffect(() => {
    formatApontamento();
    formatInsumosAplicadosData();
    formatAbastecimento();
    formatDuracaoProjetada();
    formatVolumeClimatico();
  }, [data, insumosAplicados, volumeClimatico, abastecimento]);

  const fetchData = async () => {
    const today = format(new Date(), "yyyy-MM-dd");
    const sevenDayBefore = format(
      sub(new Date(), {
        days: 6,
      }),
      "yyyy-MM-dd"
    );
    const { data: apontamentosEncerrados } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/sig/indicadores/apontamentosEncerrados?Data=${today}`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    const { data: insumosAplicados } = await axios.post(
      `${userInformation.UrlApiFarm4all}agricola/relatorios/operacionais/operacoesAgricolasInsumos`,
      {
        DataInicial: sevenDayBefore,
        DataFinal: today,
      },
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    const { data: volumeAbastecimento } = await axios.post(
      `${userInformation.UrlApiFarm4all}agricola/relatorios/logistica/consolidadoAbastecimentos`,
      {
        DataInicial: sevenDayBefore,
        DataFinal: today,
      },
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    const { data: motivoOsAberto } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/relatorios/logistica/motivoOsEmAberto`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    const { data: alertaVencimentoCnh } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/relatorios/logistica/alertaVencimentoCNH`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    const { data: manutencaoPreventiva } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/relatorios/logistica/manutencaoPreventiva`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    const { data: volumeClimatico } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/sig/indicadoresClimaticos`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    const { data: duracaoProjetadaCombustivel } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/sig/analiseDuracaoCombustiveis`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setManutencaoPreventiva(
      orderBy(manutencaoPreventiva, ["KmRestante"], ["desc"])
    );
    setAlertaVencimentoCnh(
      orderBy(alertaVencimentoCnh, ["DiasVencimento"], ["desc"])
    );
    setDuracaoProjetada(duracaoProjetadaCombustivel);
    setVolumeClimatico(volumeClimatico);
    setMotivoOsAberta(orderBy(motivoOsAberto, "CodOS"));
    setAbastecimento(volumeAbastecimento);
    setData(apontamentosEncerrados);
    setDataInsumosAplicados(insumosAplicados);
    setLoadgin(false);
  };
  const formatApontamento = () => {
    const farm: any = groupBy(data, "DscFazenda");
    const appoints: any = groupBy(data, "Apontamento");
    const listFarm: string[] = [];
    const listAppointment: string[] = [];
    Object.keys(farm).map((item) => listFarm.push(item));
    Object.keys(appoints).map((item) => listAppointment.push(item));
    setListFarm(listFarm);
    setListappoint(listAppointment);
    setColumns(appoints);
  };
  const formatInsumosAplicadosData = () => {
    let result: any = { tr: [], td: { combustiveis: [] } };
    const insumos: any = groupBy(insumosAplicados, "U_DscInsumo");
    const farm: any = groupBy(insumosAplicados, "U_DscFazenda");

    const fazendasKey = Object.keys(farm);
    const insumosKey = Object.keys(insumos);

    const arrayFazendas = [translate("insumos"), ...orderBy(fazendasKey)];
    const arrayInsumos = [...orderBy(insumosKey)];
    const dataSet = fazendasKey.map((farmName: any) => {
      const obj = {};
      const farmArr = farm[farmName];
      const groupByInsumo = groupBy(farmArr, "U_DscInsumo");
      const resultData = Object.keys(groupByInsumo).map((key: string) => {
        const insumos = groupByInsumo[key];
        const total = sumBy(insumos, "totalutilizado");
        return Object.assign(obj, { [key]: total });
      });
      result.td[farmName] = obj;
    });
    result.tr = arrayFazendas;
    result.th = arrayInsumos;
    result.td.combustiveis = arrayInsumos;

    setInsumosList(result);
  };
  const formatAbastecimento = () => {
    let result: any = { tr: [], td: { combustiveis: [] } };
    const farm: any = groupBy(abastecimento, "DscFazenda");
    const fuel: any = groupBy(abastecimento, "NomeItem");

    const fazendasKey = Object.keys(farm);
    const fuelKey = Object.keys(fuel);

    const arrayFazendas = [translate("combustivel"), ...orderBy(fazendasKey)];
    const arrayfuel = [...orderBy(fuelKey)];
    fazendasKey.map((farmName: any) => {
      const obj = {};
      const farmArr = farm[farmName];
      const groupByFuel = groupBy(farmArr, "NomeItem");
      Object.keys(groupByFuel).map((key: string) => {
        const fuel = groupByFuel[key];
        const total = sumBy(fuel, "Litros");
        return Object.assign(obj, { [key]: total });
      });
      result.td[farmName] = obj;
    });
    result.tr = arrayFazendas;
    result.th = arrayfuel;
    result.td.combustiveis = arrayfuel;

    setListFuel(result);
  };
  const formatVolumeClimatico = () => {
    let result: any = { tr: [], td: { elementoClimatico: [] } };
    const farm: any = groupBy(volumeClimatico, "DscFazenda");

    const fazendasKey = Object.keys(farm);
    const arrayFazendas = [translate("elementos climaticos"), ...orderBy(fazendasKey)];
    fazendasKey.map((farmName: any) => {
      const farmArr = farm[farmName];
      if (farmArr[0].ElementosClimaticos.length > 0) {
        result.td[farmName] = farmArr[0];
      }
    });
    result.tr = arrayFazendas;
    result.th = [
      translate("pluviometro (acumulado ano)"),
      translate("pluviometro (ultimos 30 dias)"),
      translate("pluviometro (ultimos 7 dias)")
    ];
    setVolumeClimaticoList(result);
  };
  const formatDuracaoProjetada = () => {
    let result: any = { tr: [], td: { duracaoProjetada: [] } };
    const farm: any = groupBy(duracaoProjetada, "DscFazenda");
    const fuel: any = groupBy(duracaoProjetada, "NomeItem");
    const arr = Object.values(farm).map((item: any) => {
      return item[0].Combustiveis.map((e: any) => e.DscCombustivel);
    });

    const fazendasKey = Object.keys(farm);
    const fuelKey = Object.keys(fuel);
    const arrayfuel = flatten(arr);
    const arrayFazendas = [translate("combustivel"), ...orderBy(fazendasKey)];

    const dataSet = fazendasKey.map((farmName: any) => {
      const obj = {};
      const farmArr = farm[farmName];
      const groupByFuel = groupBy(farmArr[0].Combustiveis, "DscCombustivel");
      const resultData = Object.keys(groupByFuel).map((key: string) => {
        const fuel: any = groupByFuel[key][0];
        return Object.assign(obj, { [key]: fuel.Duracao });
      });
      result.td[farmName] = obj;
    });
    result.tr = arrayFazendas;
    result.th = arrayfuel;
    result.td.duracaoProjetada = arrayfuel;

    setListFuelDuration(result);
  };

  if (loading) {
    return (
      <CardBody>
        <SkeletonTheme color="#20202010" highlightColor="#44444410">
          <Skeleton
            count={1}
            style={{ marginLeft: "10px", marginBottom: "20px" }}
            height={30}
            width={300}
          />
        </SkeletonTheme>
        <SkeletonTheme color="#20202010" highlightColor="#44444410">
          <Skeleton
            count={8}
            style={{ marginLeft: "10px", marginBottom: "20px" }}
            height={20}
            width={150}
          />
          <Skeleton
            count={8}
            style={{ marginLeft: "10px" }}
            height={10}
            delay={1}
            width={150}
          />
          <Skeleton
            count={8}
            style={{ marginLeft: "10px" }}
            height={10}
            delay={1}
            width={150}
          />
          <Skeleton
            count={8}
            style={{ marginLeft: "10px" }}
            height={10}
            delay={1}
            width={150}
          />
          <Skeleton
            count={8}
            style={{ marginLeft: "10px" }}
            height={10}
            delay={1}
            width={150}
          />
        </SkeletonTheme>
        <SkeletonTheme color="#20202010" highlightColor="#44444410">
          <Skeleton
            count={3}
            style={{ marginLeft: "10px", marginBottom: "20px" }}
            width={20}
          />
        </SkeletonTheme>
      </CardBody>
    );
  }

  return (
    <>
      {show && <HideModal close={() => setShow(false)} information={show} />}
      <CardBody title={translate("lançamentos encerrados do dia")}>
        <Container
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <KpiContainer style={{ height: "100%" }}>
            <Table>
              <tr>
                <th>{translate("apontamentos")}</th>
                {listFarm.map((farmName: string) => (
                  <th>{farmName}</th>
                ))}
              </tr>
              {listAppoint.map((farmName: string, i: number) => (
                <tr>
                  <td>{farmName}</td>
                  {columns[farmName].map((farmReturn: any, index: number) => {
                    const id = `tag${i}${index}${farmReturn["QtdEmAberto"]}${farmReturn["QtdEncerrada"]}`;
                    return (
                      <td
                        data-for={id}
                        data-tip={`${translate("em aberto")}: ${farmReturn["QtdEmAberto"]}`}
                        data-iscapture="true"
                        onClick={() =>
                          farmReturn["QtdEmAberto"] != 0 && setShow(farmReturn)
                        }
                        style={{
                          background:
                            farmReturn["QtdEmAberto"] >= 10
                              ? "red"
                              : farmReturn["QtdEmAberto"] == 0
                              ? "#fff"
                              : "yellow",
                          cursor:
                            farmReturn["QtdEmAberto"] > 0 ? "pointer" : "",
                        }}
                        className="center"
                      >
                        {farmReturn["QtdEncerrada"]}

                        <ReactTooltip
                          id={id}
                          place="bottom"
                          type="dark"
                          effect="solid"
                          multiline={true}
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </Table>
          </KpiContainer>
          <LegendTable>
            <small>{translate("legenda")}</small>
            <h1 style={{ background: "yellow" }}>{translate("entre 1 e 10")}</h1>
            <h1 style={{ background: "red" }}>{translate("acima de 10")}</h1>
          </LegendTable>
        </Container>
      </CardBody>
      <br />
    </>
  );
};

export default memo(ColheitaTalhao);
