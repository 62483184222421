import styled from "styled-components";
interface Container {
  pointed?: boolean;
}
export const Container = styled.div<Container>`
  position: fixed;
  top: 0;
  transition: 0.51s;
  right: 309px;
  background: ${({ pointed }) =>
    !pointed && "linear-gradient(178deg, #3e41503b 30%, #ffffff05 80%)"};
  overflow-y: scroll;
  height: 110vh;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 105px 20px;

  section {
    text-transform: uppercase;
    font-size: 12px;
    background: #2f3243;
    padding: 20px 40px;
    max-width: 550px;
    /* border-radius: 4px; */
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    transition: 0.51s;

    color: #fff;
    font-weight: 400;
    strong {
      font-weight: bold;
      margin-left: -20px;
    }
  }
  body {
    text-align: center;
    background: #262836;
    padding: 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-top: -10px;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    transition: 0.51s;
    max-width: 550px;
    .row {
      display: flex;
      gap: 8px;
      flex-direction: row;
    }
    .col {
      display: flex;
      flex-direction: column;
    }
    h2 {
      color: #fff;
      font-weight: bold;
      font-size: 64px;
      span {
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        margin-left: -10px;
      }
    }
    h1 {
      color: #fff;
      font-weight: bold;
      font-size: 13px;
      /* font-size: 64px; */
    }
    span,
    strong {
      color: #ffffff62;
      font-size: 12px;
      margin-top: 5px;
      font-weight: 400;
    }
  }
  header {
  }
`;
