export default {
  ligth: {
    background: "#2F3243",
    backgroundBody: "#fff",
    textColor: "#fff",
    backgroundDefault: "#fff",
    mobileDimension: "600px",
    mediumDimension: "1400px",
  },
  dark: {
    background: "#2F3243",
    backgroundBody: "#fff",
    backgroundNeutral: "#3e4150",
    textColor: "#000",
    backgroundDefault: "#fff",
    mobileDimension: "600px",
    mediumDimension: "1400px",
  },
};
