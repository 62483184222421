import React, { useContext } from "react";
import { AiOutlineDesktop, AiOutlineBackward } from "react-icons/ai";
import { useLocation, useHistory } from "react-router-dom";
import { CSSProperties } from "styled-components";
import { Button, ButtonClick } from "./style";
import { TvmodeContext } from "../../contexts/tvmode";

import useTranslate from "../../hooks/useTranslate";

import ReactTooltip from "react-tooltip";
interface Iprops {
  link: string;
  style?: CSSProperties;
}
const ButtonRedirectToTv: React.FC<Iprops> = ({ link, style }) => {
  const { changeMode, mode } = useContext(TvmodeContext);
  const { translate } = useTranslate();

  if (mode) {
    return (
      <>
        <ButtonClick
          data-for="editModeFalse"
          data-tip={translate("sair do modo de tv")}
          data-iscapture="true"
          style={style}
          onClick={() => changeMode(false)}
        >
          <AiOutlineBackward />
        </ButtonClick>
        <ReactTooltip
          id="editModeFalse"
          place="bottom"
          type="dark"
          effect="solid"
          multiline={true}
        />
      </>
    );
  }
  return (
    <>
      <ButtonClick
        data-for="editModeTrue"
        data-tip={translate("entrar em modo de tv")}
        data-iscapture="true"
        style={style}
        onClick={() => changeMode(true)}
      >
        <AiOutlineDesktop />
      </ButtonClick>
      <ReactTooltip
        id="editModeTrue"
        place="bottom"
        type="dark"
        effect="solid"
        multiline={true}
      />
    </>
  );
};

export default ButtonRedirectToTv;
