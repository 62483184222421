import styled, { css } from "styled-components";
import theme from "../../styles/theme";
import { Link } from "react-router-dom";

interface IItem {
  isEnable?: boolean;
  hide?: boolean;
}
export const Item = styled(Link)<IItem>`
  padding: 10px 20px;
  align-content: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: row;
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 10px;
  cursor: pointer;
  small {
    color: ${theme.ligth.textColor};
    margin-left: 20px;
  }
  &:hover {
    background: #9d9d9d24;
    border-radius: 5px;
  }
  ${({ isEnable }) =>
    isEnable &&
    css`
      background: #9d9d9d24;
      border-radius: 5px;
      cursor: none;
      pointer-events: none;
    `}
  @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
    ${({ hide }) =>
      !hide &&
      css`
        display: none;
      `}
  }
`;
