import translateJson from "../config/translate.json";

interface ITranslate {
  (language: string, value: string): string;
}
interface IComponents {
  [key: string]: any;
}
const translateJsonInf: IComponents = translateJson;

export const translate: ITranslate = (language, value) => {

  let valueTratament = value.toLowerCase();
  return translateJsonInf?.[valueTratament]?.[language] || `Por gentileza crie a tradução de ${valueTratament} ${language} corretamente.` ;
};
