import React, { useEffect, useState } from "react";
import { InnerProgress, ProgressBar } from "./style";
interface IProps {
  time: number;
}
const ProgressBarElement: React.FC<IProps> = ({ time }) => {
 
  return (
    <ProgressBar>
      <InnerProgress minute={time}/>
    </ProgressBar>
  );
};
export default ProgressBarElement;
