import React, { memo, useEffect, useState } from "react";
import CardBody from "../../Components/card";
import { KpiContainer, Container } from "./style";
import { groupBy, sumBy, toNumber } from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  ComposedChart,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import useFilter from "../../hooks/useFilter";
import { Iinformation } from "./interface";
import TooltipCard from "../../Components/talhaoCard";
import useUser from "../../hooks/useUser";
import useTranslate from "../../hooks/useTranslate";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
const ComparativoSafra: React.FC = () => {
  const { translate } = useTranslate();
  const { filter } = useFilter();
  const dispatch = useDispatch();
  const selector = useSelector((e: any) => e.comparativoSafra);
  const [information, setInformation] = useState<Iinformation[]>([]);
  const [dataInformation, setDataInformation] = useState<any>([]);
  const [row, setRow] = useState([]);
  const [requestInApiRow, setRequestInApi] = useState<any>([]);
  const { userInformation } = useUser();
  const fetchData = async () => {
    const returnValue =
      filter?.filtersApply &&
      Object.keys(filter.filtersApply).map((element) => {
        const [cod, id] = element.split("_");
        const val = filter.filtersApply[element].value;
        if (cod) {
          return { val, id, cod };
        }
      });
    setRow(returnValue);
  };
  const setFilterOnRequest = async (vall: any) => {
    const groupRowRequestById = groupBy(vall, "id");
    const dataTretment = Object.keys(groupRowRequestById).map((id: any) => {
      const groupItems = groupRowRequestById[id];
      const group: any = {};
      groupItems.map(({ cod, val }) => {
        return Object.assign(group, {
          ...group,
          [id]: { ...group[id], [cod]: val },
        });
      });
      return group;
    });
    setRequestInApi(dataTretment);
  };
  const requestInApi = async (item: any, id: any) => {
    const getCodSafraFilter = filter?.filtersApply[`CodSafra_${id}`];
    const getCodPeriodoProducaoFilter = filter?.filtersApply[`CodPeriodoProducao_${id}`];
    const getCodFarmFilter = filter?.filtersApply[`CodFazenda_${id}`];
    if (getCodSafraFilter && getCodPeriodoProducaoFilter) {
      const { data: dataInfo } = await axios.post(
        `${userInformation.UrlApiFarm4all}agricola/sig/analiseSafra`,
        {
          ...item,
        },
        {
          auth: {
            username: userInformation.UsuarioApiFarm4all,
            password: userInformation.SenhaApiFarm4all,
          },
        }
      );
      
      const farms = dataInfo?.PeriodosProducao[0]?.Fazendas
      const tipoCultura = dataInfo?.PeriodosProducao[0]?.CulturaPerene
      const unidadeRendimento = dataInfo?.PeriodosProducao[0]?.UnidadeRendimento
      const safra = `${dataInfo.DscSafra} | ${dataInfo?.PeriodosProducao[0]?.DscPeriodoProducao}`
      const periodoProdução = dataInfo?.PeriodosProducao[0]?.DscPeriodoProducao
      
      const totalHarvestedAreaKg = sumBy(farms, "KgsColhidos")
      const totalHarvestedAreaSacas = sumBy(farms, "SacasColhidas")
      const totalProductvity = sumBy(farms, "Produtividade")
      let sacasHec:any = []
      let resultadoSacas:any
      
      if(!getCodFarmFilter){
        farms.map((item:any)=>{
          sacasHec.push((item.areaColhida > item.areaPlantada) 
          ? 
            ( item.SacasColhidas / item.AreaColhida) 
          : 
            ( item.SacasColhidas / item.AreaPlantada) )
        })
        resultadoSacas = (sumBy(sacasHec) / farms.length)
      }else{
        
        const areaColhida = farms[0]?.AreaColhida
        const areaPlantada = farms[0]?.AreaPlantada
        const sacasColhidas = farms[0]?.SacasColhidas
        sacasHec.push((areaColhida > areaColhida) ? (sacasColhidas / areaColhida) : (sacasColhidas / areaPlantada))
        resultadoSacas = sumBy(sacasHec)
      }
      
      dispatch({
        type: "SET_COMPARATIVOSAFRA",
        payload: {
          id,
          body: {
            safra,
            periodoProdução,
            totalHarvestedAreaKg,
            totalHarvestedAreaSacas,
            name: unidadeRendimento,
            totalProductvity,
            resultadoSacas
            
          },
        },
      });
    }
  };

  const CustomTooltip: any = (props: any) => {
    
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "10px 20px",
          border: "1px solid black",
        }}
      >
        <h1 style={{ fontSize: "14px" }}>{props?.label}</h1>
        {props?.payload?.map((item: any) => {
          
          return (
            <>
            
              <h2
                style={{
                  fontSize: "14px",
                  color: item?.color,
                  marginTop: "4px",
                }}
              >
                {item?.payload?.name}/ha: {item?.payload?.color}
                <small>{item?.payload?.resultadoSacas.toFixed(2).toLocaleString()}</small>
              </h2>
            </>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    const dataI: any = [];
    Object.keys(information).map((key: any) => {
      const dataInfo: any = information[key];
      dataI.push(dataInfo);
    });
    setDataInformation(dataI);
  }, [information]);
  useEffect(() => {
    fetchData();
  }, [filter]);
  useEffect(() => {
    setFilterOnRequest(row);
  }, [row]);
  useEffect(() => {
    let dtObj: any = {};
    requestInApiRow.map((e: any) => Object.assign(dtObj, e));
    Object.keys(dtObj).map((key: string) => {
      requestInApi(dtObj[key], key);
    });
  }, [requestInApiRow]);
  return (
    <CardBody title={translate("Comparativo")}>
      <Container>
        <KpiContainer style={{ height: "700px", textAlign: "center" }}>
          <small>{translate("produtividade")}</small>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={selector && Object.values(selector)}
              width={800}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#1873a2" stopOpacity={1} />
                  <stop offset="95%" stopColor="#1873a2" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="safra"
                tickFormatter={(e) => `${e.split("|")[1]} | ${e.split("|")[2] || ' '}`}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Area
                type="monotone"
                name={translate("total produtividade")}
                dataKey="totalProductvity"
                fill="url(#colorUv)"
                stroke="#1873a2"
              />
              <Bar
                name={translate("total produtividade")}
                barSize={40}
                dataKey="totalProductvity"
                fill="#8884d8"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </KpiContainer>
      </Container>
    </CardBody>
  );
};

export default memo(ComparativoSafra);
