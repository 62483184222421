import React, { createContext, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { servicoInternoApi } from "../services";
interface AuthContextData {
  logon(value: ILogon): void;
  userInformation: any;
  information: any;
  resetInfo: () => void;
}
interface ILogon {
  userName?: string;
  password?: string;
  id?: string;
  Layout?: string;
}
interface IDataReturn {
  Usuario: string;
  Empresa: string;
  Email: string;
  CodEmpresa: string;
  Layout: string;
  Idioma: string;
  UrlApiFarm4all: string;
  UsuarioApiFarm4all: string;
  SenhaApiFarm4all: string;
  Dashboards: any[];
}
export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const [userInformation, setUserInformation] = useState();
  const [information, setInformation] = useState<any>(localStorage.getItem('info') || null);
  const dispatch = useDispatch();
  const logon = async (value: ILogon) => {
    const { userName, password } = value;
    try {
      let {data} = await servicoInternoApi.post<IDataReturn>(
        "autenticarUsuario",
        {
          Usuario: userName,
          Senha: password,
        }
      );
      setInformation(data)
      localStorage.setItem('info', JSON.stringify(data));
      dispatch({ type: "LOGIN_USER", payload: data });
      return data;
    } catch (e) {
      if (e.response.status == 401) {
        return { error: true, message: "login e senha incorreto" };
      }
      return { error: true, message: "erro ao fazer login" };
    }
  };
  const resetInfo = () => setInformation(null);
  return (
    <AuthContext.Provider
      value={{
        logon,
        userInformation,
        information,
        resetInfo
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
