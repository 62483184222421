//#region
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Label,
  LineChart,
  Line,
} from "recharts";
import "../../styles/style.css";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import PageBody from "../../Components/pageBody";
import { translate } from "../../utils/translate";
import HomeDashboard from "../../reports/home";
import Filter from "../../Components/filter";
import useFilter from "../../hooks/useFilter";
import Cotacao from "../../Components/currencyCot";
// import Weather from "../../Components/weather";
import Row from "../../Components/row";
import useUser from "../../hooks/useUser";
import axios from "axios";
import CardBody from "../../Components/card";

interface IData {
  HistoricoChuva: {
    Ano: number;
    Quantidade: number;
  }[];
  HistoricoTemperatura: {
    Ano: number;
    Maxima: number;
    Media: number;
    Minima: number;
  }[];
}

const HistoricoDadosClimaticos: React.FC = (props: any) => {
  const { language } = useSelector<any, any>((props) => props);
  const [data, setData] = useState<IData>();
  const { filter } = useFilter();
  const farm = filter?.filtersApply?.CodFazenda?.value;
  const { userInformation } = useUser();

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [filter]);

  const fetchData = async () => {
    const { data } = await axios.post(
      `${userInformation.UrlApiFarm4all}agricola/sig/historicoClimatico?CodFazenda=${farm}`,
      {},
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setData(data);
  };
  const renderCustomBarLabel = (props: any) => {
    const { x, width, y, payload } = props;
    
    return (
      <text
        x={x}
        y={y + 20}
        fill="#666"
        textAnchor="middle"
        dy={-6}
        style={{ fontSize: "10px" }}
      >{`${payload.value}`}</text>
    );
  };
  return (
    <>
      <PageTitle>
        <h1> {translate(language.language, "historico climatico")}</h1>
        <small>{translate(language.language, "de 1970 até 2021")}</small>
      </PageTitle>
      <PageBody>
        <Filter filters={["CodFazendaNoDependencies"]} />
        {!!data?.HistoricoChuva && (
          <Row style={{ marginTop: "15px", gap: "15px" }}>
            <CardBody
              title={translate(language.language, "historico de chuva | pluviometria (mm)")}
              style={{ height: "400px" }}
            >
              <ResponsiveContainer maxHeight={300}>
                <BarChart data={data?.HistoricoChuva}>
                  <CartesianGrid strokeDasharray="3 3" visibility="hidden" />
                  <XAxis
                    tick={renderCustomBarLabel}
                    interval={0}
                    dataKey="Ano"
                  />
                  <YAxis padding={{ top: 50 }} hide={true} />
                  <Tooltip />
                  <Bar
                    label={{
                      alignmentBaseline: "true",
                      color: "black",
                      fontSize: 12,
                      position: "top",
                      angle: -90,
                      offset: 25,
                    }}
                    dataKey="Quantidade"
                    maxBarSize={80}
                    name={translate(language.language, "Quantidade")}
                    background={{ fill: "#eee" }}
                    fill="#8884d8"
                  >
                    <Label />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardBody>
          </Row>
        )}
        {!!data?.HistoricoTemperatura && (
          <Row style={{ marginTop: "15px", gap: "15px" }}>
            <CardBody
              title={translate(language.language, "historico de temperatura")}
              style={{ height: "400px" }}
            >
              <ResponsiveContainer maxHeight={300}>
                <LineChart data={data.HistoricoTemperatura}>
                  <CartesianGrid strokeDasharray="3 3" visibility="hidden" />
                  <XAxis
                    tick={renderCustomBarLabel}
                    interval={0}
                    dataKey="Ano"
                  />
                  <YAxis padding={{ top: 50 }} hide={true} />
                  <Tooltip />
                  <Line type="monotone" dataKey="Maxima" stroke="#e89994" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="Media" stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="Minima" stroke="#d6d1b6" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </CardBody>
          </Row>
        )}
      </PageBody>
    </>
  );
};

export default HistoricoDadosClimaticos;
