import styled, { css } from "styled-components";
interface IBodyFilter {
  disabled?: boolean;
  isMulti?: boolean;
  selected?: boolean;
}

export const ButtonFilter = styled.button<IBodyFilter>`
  width: 100%;
  padding: 18px 32px;
  border: none;
  background: #00000033;
  /* border-radius: 5px; */
  text-transform: uppercase;
  text-align: left;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  font-size: 13px;
  outline: none;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background: #2f3243;
      opacity: 0.2;
      pointer-events: none;
    `}
  ${({ selected }) =>
    selected &&
    css`
      background: #fff;
      color: #000;
    `}
`;
interface IRadioButton {
  isSelected?: boolean;
}
export const ButtonRadio = styled.button<IRadioButton>`
  padding: 13px;
  width: 100%;
  max-width: 0%;
  border: 1px solid #2f3243;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: left;
  display: flex;
  font-size: 13px;
  transition: 0.5s;
  background-color: #2f3243;
  color: #fff;
  outline: none;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      max-width: 48%;

      background: #fff;
      color: #000;
    `}
`;
export const BodyFilter = styled.ul`
  background: #00000033;
  list-style: none;
  padding: 20px 10px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-height: 300px;
  overflow: scroll;
`;

export const ListItemFilter = styled.li<IBodyFilter>`
  padding: 10px 20px;
  font-size: 10px;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
  ${({ isMulti }) =>
    isMulti &&
    css`
      text-decoration: line-through;
      display: flex;
      justify-content: space-between;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      padding: 10px 40px;
      background: #fff;
      color: #000;
      margin-top: 8px;
    `}

  &:hover {
    background: #fff;
    color: #000;
    ${({ isMulti }) =>
      isMulti &&
      css`
        text-decoration: line-through;
      `}
  }
`;
