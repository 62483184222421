import React, { memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import HideModal from "../../../Components/hideModal";
import Table from "../../../Components/table";
import { KpiContainer, Container, Legend as LegendTable } from "../style";
import { flatten, groupBy, orderBy, sumBy, toNumber } from "lodash";
import { format, sub } from "date-fns";
import useFilter from "../../../hooks/useFilter";
import useUser from "../../../hooks/useUser";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import useTranslate from "../../../hooks/useTranslate";
import { IVolumeAplicado } from "../interface";

const VolumeAbastecimento: React.FC<any> = (props) => {
  const { userInformation } = useUser();
  const [data, setData] = useState<IVolumeAplicado[]>([]);
  const [listFuel, setListFuel] = useState<any>([]);
  const { translate } = useTranslate();

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation]);
  useEffect(() => {
    formatAbastecimento();
  }, [data]);
  const fetchData = async () => {
    const today = format(new Date(), "yyyy-MM-dd");
    const sevenDayBefore = format(
      sub(new Date(), {
        days: 6,
      }),
      "yyyy-MM-dd"
    );

    const { data: dataInfo } = await axios.post(
      `${userInformation.UrlApiFarm4all}agricola/relatorios/logistica/consolidadoAbastecimentos`,
      {
        DataInicial: sevenDayBefore,
        DataFinal: today,
      },
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setData(dataInfo);
  };

  const formatAbastecimento = () => {
    let result: any = { tr: [], td: { combustiveis: [] } };
    const farm: any = groupBy(data, "DscFazenda");
    const fuel: any = groupBy(data, "NomeItem");

    const fazendasKey = Object.keys(farm);
    const fuelKey = Object.keys(fuel);

    const arrayFazendas = [translate("combustivel"), ...orderBy(fazendasKey)];
    const arrayfuel = [...orderBy(fuelKey)];
    const dataSet = fazendasKey.map((farmName: any) => {
      const obj = {};
      const farmArr = farm[farmName];
      const groupByFuel = groupBy(farmArr, "NomeItem");
      const resultData = Object.keys(groupByFuel).map((key: string) => {
        const fuel = groupByFuel[key];
        const total = sumBy(fuel, translate("litros"));
        return Object.assign(obj, { [key]: total });
      });
      result.td[farmName] = obj;
    });
    result.tr = arrayFazendas;
    result.th = arrayfuel;
    result.td.combustiveis = arrayfuel;

    setListFuel(result);
  };
  return (
    <>
      <CardBody
        returnTitleUpdated={(e) => props.dto.defineTitle(e)}
        title={
          props?.dto?.customTitle || translate("volume de abastecimento *ultimos 7 dias")
        }
      >
        <Container>
          <KpiContainer
            style={{ height: "100%", maxHeight: "400px", overflow: "scroll" }}
          >
            <small></small>
            <Table>
              <thead>
                <tr>
                  {listFuel?.tr?.map((farmName: string) => {
                    return <th align="left">{farmName}</th>;
                  })}
                </tr>
              </thead>
              {listFuel?.th?.map((nameInsumo: string) => {
                return (
                  <tr>
                    <td
                      align="left"
                      style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {nameInsumo}
                    </td>
                    {listFuel?.tr.map((nameFarm: string) => {
                      return (
                        nameFarm != translate("combustivel") && (
                          <td align="left">
                            {listFuel?.td?.[nameFarm]?.[nameInsumo]
                              ?.toFixed(2)
                              ?.toLocaleString() || 0}
                          </td>
                        )
                      );
                    })}
                  </tr>
                );
              })}
            </Table>
          </KpiContainer>
        </Container>
      </CardBody>
    </>
  );
};

export default VolumeAbastecimento;
