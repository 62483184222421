import { combineReducers } from "redux";
import language from "./language/reducer";
import user from "./user/reducer";
import dashboardSelected from "./dashboardSelected/reducer";
import filter from "./filter/reducer";
import customTitle from "./customTitle/reducer";
import farm from "./farm/reducer";
import sector from "./sector/reducer";
import talhao from "./talhao/reducer";
import periodoProducao from "./periodoProducao/reducer";
import comparativoSafra from "./comparativoSafra/reducer";

export default combineReducers({
  language,
  user,
  filter,
  dashboardSelected,
  customTitle,
  farm,
  sector,
  talhao,
  periodoProducao,
  comparativoSafra
});
