import styled from "styled-components";
import theme from '../../styles/theme';
export const Container = styled.div`
  width: 100%;
  h1 {
    font-size: 26px;
    font-weight: 500;
    margin-top: 14px;
    color: ${theme.dark.textColor};
    line-height: 40px;
  }
  small {
    font-size: 15px !important;
    font-weight: 300 !important;
    color: ${theme.dark.textColor};


  }
  @media only screen and (max-width: 600px) {
    margin-top:80px;
  }
`;
