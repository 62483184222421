//#region
import React, { useContext } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { GlobalStyle } from "../styles/globalStyle";
import { EditModeContext } from "../contexts/openFilter.context";
import { AuthContext } from "../contexts/auth.context";
import { info } from "../services/toasty";
import { TvmodeContext } from "../contexts/tvmode";
import AnaliseProdutividade from "../Pages/analiseProdutividade";
import DashboardPecuaria from "../Pages/pecuariaDashboard";
import ComparativoSafra from "../Pages/comparativoSafra";
import PainelDeOficina from "../Pages/painelOficina";
import Pecuaria from "../Pages/pecuaria";
import IndicadoresAgricola from "../Pages/indicadoresAgricola";
import IndicadoresAlgodoeira from "../Pages/indicadoresAlgodoeira";
import HomeDashboard from "../Pages/homeDashboard";
import Home from "../Pages/home";
import HistoricoDadosClimaticos from '../Pages/historicoDadosClimatico'
import Login from "../Pages/login";
import SideMenu from "../Components/sideMenu";
import Dashboard from "../Pages/dashboard";
import UserManager from "../Pages/userManager";
import Map from "../Pages/maps";
//#endregion

const Routes: React.FC = () => {
  const { pathname } = useLocation();
  const { show } = useContext(EditModeContext);
  const { mode } = useContext(TvmodeContext);
  const { information } = useContext(AuthContext);

  return (
    <>
      {pathname === "/" &&
        information &&
        (info(`Bem vindo de volta`),
        (<Redirect to={{ pathname: "/home", state: { getBack: true } }} />))}
      {pathname !== "/" && !information && (
        <Redirect to={{ pathname: "/", state: { redirect: true } }} />
      )}
      {pathname !== "/" && !mode && <SideMenu activeItem={pathname} />}
      <GlobalStyle tv={mode} hide={show} isLogin={pathname === "/" && true} />
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/map" exact component={Map} />
        <Route path="/home" exact component={Home} />
        <Route path="/usuario" exact component={UserManager} />
        <Route path="/pecuaria" exact component={Pecuaria} />
        <Route path="/dashboard/:id" exact component={Dashboard} />
        <Route path="/painelOficina" exact component={PainelDeOficina} />
        <Route path="/historicoDadosClimaticos" exact component={HistoricoDadosClimaticos} />
        <Route path="/home-dashboard" exact component={HomeDashboard} />
        <Route path="/comparativoSafra" exact component={ComparativoSafra} />
        <Route path="/dashboardPecuaria" exact component={DashboardPecuaria} />
        <Route path="/indicadoresAgricola" exact component={IndicadoresAgricola} />
        <Route path="/analiseProdutividade" exact component={AnaliseProdutividade} />
        <Route path="/indicaoresAlgodoeira" exact component={IndicadoresAlgodoeira} />
      </Switch>
    </>
  );
};

export default Routes;
