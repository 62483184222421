import { motion } from "framer-motion";
import styled, { css } from "styled-components";
interface IChangeView {
  stateView?: string;
  subtitle?: boolean;
  isTitle?: boolean;
  isInDraggableComponent?: boolean;
}
export const Text = styled(motion.h1)<IChangeView>`
  white-space: nowrap;
  text-transform:uppercase;
  ${({ subtitle }) =>
    subtitle &&
    css`
      font-size: 15px !important;
      font-weight: 400 !important;
      padding-right: 140px;
    `}
  ${({ stateView }) =>
    stateView !== "text" &&
    css`
      display: none;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      top: 0;
    `}
    ${({ isTitle }) =>
    !isTitle &&
    css`
      max-width: 80%;
      overflow: hidden;
    `}
`;
export const Input = styled.input<IChangeView>`
  padding-left: 10px;
  outline: none;
  /* margin-top: 15px; */
  text-transform: uppercase;
  font-weight: 500;
  border: none;
  font-size: 48px;
  ${({ subtitle }) =>
    subtitle &&
    css`
      font-size: 20px;
      font-weight: 400;
    `};
  ${({ stateView }) =>
    stateView !== "input" &&
    css`
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
    `}
  ${({ isInDraggableComponent }) =>
    isInDraggableComponent &&
    css`
      font-size: 14px !important;
    `}
`;
