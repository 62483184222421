import React, { useEffect, useState } from "react";
import axios from "axios";
import { toNumber } from "lodash";
import EuaFlag from "../../assets/euaFlag.png";
import BrasilBandeira from "../../assets/brasilBandeira.jpg";
import useTranslate from "../../hooks/useTranslate";
import {Card, Image} from "./style";
interface ICurrency {
  ask: string;
  bid: string;
  code: string;
  codein: string;
  create_date: Date;
  high: string;
  low: string;
  name: string;
  pctChange: string;
  timestamp: string;
  varBid: string;
}
const CurrencyCot: React.FC = () => {
  const {translate} = useTranslate()
  const [cotacao, setCotacao] = useState<ICurrency>();
  useEffect(() => {
    fetchCotacao();
  }, []);

  const fetchCotacao = async () => {
    const { data } = await axios.get(
      `https://economia.awesomeapi.com.br/json/last/USD-BRL`
    );

    setCotacao(data.USDBRL);
  };
  return (
    <>
        <Card>
            <div className='rowContainer'>
                <Image srcUrl={EuaFlag} style={{zIndex: 1, position: 'relative'}} />
                <Image srcUrl={BrasilBandeira}style={{marginLeft: '-28px', zIndex: 0}} />
                <div className='colContainer'>
                    <h1>USD-BRL</h1>
                    <h2>{translate("dolar")} / {translate("real")}</h2>
                </div>

            </div>
            <div className='rowContainer'>
                <h1><strong>{toNumber(cotacao?.bid).toLocaleString()}</strong></h1>
                <h1 className='percent'> - {toNumber(cotacao?.pctChange)} %</h1>
            </div>
        </Card>
    </>
  );
};
export default CurrencyCot;
