import React from "react";
import Pecuaria from "../../reports/pecuaria";
import PageTitle from "../../Components/pageTitle";
import PageBody from "../../Components/pageBody";
import { useSelector } from "react-redux";
import { translate } from "../../utils/translate";
import Modal from "../../Components/modal";
import { TitleContainer } from "../indicadoresAgricola/style";

const PecuariaPage: React.FC = () => {
  const state = useSelector<any, any>(({ language }) => language);
  return (
    <>
      <TitleContainer style={{ width: "120%" }}>
        <PageTitle>
          <h1>{translate(state.language, "Pecuaria")}</h1>
          <small>{translate(state.language, "Pecuaria")}</small>
        </PageTitle>
        {/* <Modal /> */}
      </TitleContainer>
      <PageBody isFullWidth={true}>
        <Pecuaria />
      </PageBody>
    </>
  );
};

export default PecuariaPage;
