import React, { memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import Table from "../../../Components/table";
import { KpiContainer, Container, Legend as LegendTable } from "../style";
import { flatten, groupBy, orderBy, sumBy, toNumber } from "lodash";
import useUser from "../../../hooks/useUser";
import axios from "axios";
import useTranslate from "../../../hooks/useTranslate";
import { DuracaoProjetadaCombustivel } from "../interface";

const DuracaoProjetada: React.FC<any> = (props) => {
  const { translate } = useTranslate();
  const [data, setData] = useState<DuracaoProjetadaCombustivel[]>([]);
  const { userInformation } = useUser();
  const [listFuelDuration, setListFuelDuration] = useState<any>([]);

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation]);
  useEffect(() => {
    formatDuracaoProjetada();
  }, [data]);
  const fetchData = async () => {
    const { data: dataInfo } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/sig/analiseDuracaoCombustiveis`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );

    setData(dataInfo);
  };

  const formatDuracaoProjetada = () => {
    let result: any = { tr: [], td: { duracaoProjetada: [] } };
    const farm: any = groupBy(data, "DscFazenda");
    const fuel: any = groupBy(data, "NomeItem");
    const arr = Object.values(farm).map((item: any) => {
      return item[0].Combustiveis.map((e: any) => e.DscCombustivel);
    });

    const fazendasKey = Object.keys(farm);
    const fuelKey = Object.keys(fuel);
    const arrayfuelList = flatten(arr);
    const arrayFazendas = [translate("combustivel"), ...orderBy(fazendasKey)];
    const arrayfuel: any = [];
    const dataSet = fazendasKey.map((farmName: any) => {
      const obj = {};
      const farmArr = farm[farmName];
      const groupByFuel = groupBy(farmArr[0].Combustiveis, "DscCombustivel");
      const resultData = Object.keys(groupByFuel).map((key: string) => {
        const fuel: any = groupByFuel[key][0];
        return Object.assign(obj, { [key]: fuel.Duracao });
      });
      result.td[farmName] = obj;
    });

    arrayfuelList.forEach((item) => {
      let duplicated =
        arrayfuel.findIndex((redItem: any) => {
          return item == redItem && item !== "";
        }) > -1;
      if (!duplicated) {
        arrayfuel.push(item);
      }
    });

    result.tr = arrayFazendas;
    result.th = arrayfuel;
    result.td.duracaoProjetada = arrayfuel;

    setListFuelDuration(result);
  };
  const colorRule = (val: number) => {
    if (val > 7 && val < 14) {
      return "danger";
    }
    if (val < 7) {
      return "warn";
    }

    if (val > 14 && val < 21) {
      return "ok";
    }

    if (val >= 21) {
      return "success";
    }
  };
  return (
    <>
      <CardBody
        returnTitleUpdated={(e) => props.dto.defineTitle(e)}
        title={props?.dto?.customTitle || translate("duracao projetada de combustiveis")}
      >
        <Container
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <KpiContainer
            style={{
              height: "100%",
              width: "100%",
              maxHeight: "400px",
              overflow: "scroll",
            }}
          >
            <small></small>
            <Table>
              <thead>
                <tr>
                  {listFuelDuration?.tr?.map((farmName: string) => {
                    return <th align="left">{farmName}</th>;
                  })}
                </tr>
              </thead>

              {listFuelDuration?.th?.map((nameFuel: string) => {
                return (
                  <tr>
                    <td
                      align="left"
                      style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {nameFuel}
                    </td>
                    {listFuelDuration?.tr?.map((nameFarm: string) => {
                      const pureVal: number =
                        listFuelDuration?.td?.[nameFarm]?.[nameFuel] || 0;
                      const duration: any = pureVal
                        ?.toFixed(2)
                        ?.toLocaleString();
                      return (
                        nameFarm != translate("combustivel") && (
                          <>
                            <td className={colorRule(pureVal)} align="left">
                              {duration}
                            </td>
                          </>
                        )
                      );
                    })}
                  </tr>
                );
              })}
            </Table>
          </KpiContainer>
          <LegendTable>
            <small>{translate("legenda")}</small>
            <h1 style={{ background: "#ff00000d" }}>1 {translate("semana")}</h1>
            <h1 style={{ background: "#d7c10850" }}>2 {translate("semana")}</h1>
            <h1 style={{ background: "#0369b22e" }}>3 {translate("semana")}</h1>
            <h1 style={{ background: "#06b20350" }}>{`4 + ${translate("semana")}`}</h1>
          </LegendTable>
        </Container>
      </CardBody>
    </>
  );
};

export default DuracaoProjetada;
