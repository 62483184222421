import React, { CSSProperties, memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import { Kpi, Table } from "../../../styles/globalComponents";
import { IAnimaisPesoAbate } from "../interface";
import useUser from "../../../hooks/useUser";
import useTranslate from "../../../hooks/useTranslate";

import axios from "axios";
import useSWR from "swr";
interface GenericFunctionType {
  <T>(url: string): Promise<T>;
}
interface IProps {
  style?: CSSProperties;
  dto?: any;
}
const AnimaisComPesoAbate: React.FC<IProps> = ({ style, dto }) => {
  const { userInformation } = useUser();
  const {translate} = useTranslate()
  const { data } = useSWR(
    userInformation.UrlApiFarm4all &&
      `${userInformation.UrlApiFarm4all}/pecuaria/sig/indicadores/animaisComPesoAbate`,
    (url) => fetchData<IAnimaisPesoAbate[]>(url)
  );
  const fetchData: GenericFunctionType = async (url: string) => {
    const { data } = await axios.get(url, {
      auth: {
        username: userInformation.UsuarioApiFarm4all,
        password: userInformation.SenhaApiFarm4all,
      },
    });
    return data;
  };
  return (
    <CardBody
    
      returnTitleUpdated={(e) => dto.defineTitle(e)}
      style={style || { marginTop: "0", justifyContent: "stretch" }}
      
      title={
        dto?.customTitle || translate("quantidade de animais com peso para abate *(na data de hoje)")
      }
    >
      <div
        className="scroll"
        style={{
          overflow: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <Table>
          <thead>
            <tr>
              <th>{translate("tipo")}</th>
              <th>{translate("quantidade")}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(({ Tipo, Quantidade }) => {
              return (
                <tr>
                  <td>{Tipo}</td>
                  <td>{Quantidade}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </CardBody>
  );
};

export default memo(AnimaisComPesoAbate);
