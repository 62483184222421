import { AiOutlineMenuFold } from "react-icons/ai";
import styled, { css } from "styled-components";
import ImageUploader from 'react-images-upload';
import theme from "../../styles/theme";
interface IHeader {
  hide: boolean;
}
interface IImage {
  image: string;
}
interface IItem {
  isEnable?: boolean;
  isEveryHover?: boolean;
  hide?: boolean;
}
interface ICard {
  hide?: boolean;
}
export const HeaderContainer = styled.div<IHeader>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  ${({ hide }) =>
    !hide &&
    css`
      flex-direction: column;
    `}
  @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
    ${({ hide }) =>
      !hide &&
      css`
        flex-direction: row;
      `}
  }
`;
export const Select = styled.select<IItem>`
  border: none;
  width: 100%;
  padding: 10px 0px;
  background: transparent;
  color: ${theme.ligth.textColor};
  outline: none;
  display: block;
  option {
    color: #000;
  }
`;
export const Card = styled.div<ICard>`
  width: 100%;
  transition: 0.5s;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  min-width: 200px;
  max-width: 300px;
  padding: 25px 20px;
  overflow-x: hidden;
  z-index: 10005;
  background: ${theme.ligth.background};
  z-index: 10005;
  justify-content: space-between;
  img {
    border-radius: 50%;
  }
  ${({ hide }) =>
    !hide &&
    css`
      min-width: 100px;
      max-width: 100px;
    `}
  @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
    width: 100%;
    max-height: 100%;
    max-width: none;
    position: fixed;
    overflow-x: hidden;
    ${({ hide }) =>
      !hide &&
      css`
        padding: 10px 20px;
        max-height: 64px;
        overflow-y: hidden;
      `}
  }
`;
export const Image = styled.div<IImage>`
  background-image: url(${({ image }) => image});
  border-radius: 50%;
  width: 40px;
  background-size: cover;
  background-position: center;
  height: 40px;
`;
export const Header = styled.div<ICard>`
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  color: ${theme.ligth.textColor};
  small {
    font-size: 13px;
    font-weight: 300;
  }
  h1 {
    margin-top: 4px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  ${({ hide }) =>
    !hide &&
    css`
      h1 {
        margin-top: 4px;
        font-size: 8px;
        display: none;
      }
      small {
        display: none;
      }
    `}
  @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
    ${({ hide }) =>
      !hide &&
      css`
        h1 {
          margin-top: 4px;
          display: block;
          font-size: 16px;
        }
        small {
          font-size: 12px;
          display: block;
        }
      `}
  }
`;
export const Item = styled.div<IItem>`
  padding: 5px 20px;
  align-content: center;
  align-items: center;
  flex-direction: row;
  user-select: none;
  display: flex;
  width: 100%;
  ${({ isEveryHover }) =>
    isEveryHover &&
    css`
      background: #9d9d9d24;
    `};
  flex-direction: row;
  margin-top: 10px;
  cursor: pointer;
  small {
    color: #626262;
    margin-left: 20px;
  }
  &:hover {
    background: #9d9d9d24;
    border-radius: 5px;
  }
  ${({ isEnable }) =>
    isEnable &&
    css`
      background: #9d9d9d24;
      border-radius: 5px;
      &:hover {
        background: #ffe1e1;
        border-radius: 5px;
      }
    `}
  @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
    ${({ hide }) =>
      !hide &&
      css`
        display: none;
      `}
  }
`;
export const HideButton = styled(AiOutlineMenuFold)<IItem>`
  cursor: pointer;
  background-color: transparent;
  height: 32px;
  ${({ hide }) =>
    !hide &&
    css`
      width: 59px;
      height: 32px;
      padding: 8px;
      background-color: #9d9d9d24;
      margin-top: 20px;
    `}
  @media only screen and (max-width: ${theme.ligth.mobileDimension}) {
    background-color: #9d9d9d24;
    ${({ hide }) =>
      !hide &&
      css`
        margin-top: 0px;
        background-color: transparent;
      `}
  }
`;
interface IProfilePhotoChange {
  image: string
}
export const ProfilePhotoChange = styled(ImageUploader)<IProfilePhotoChange>`
  margin: 20px auto 5px;
  cursor: pointer;
  text-align: center;
  transition: 1s;
  overflow: hidden;
  background-repeat: no-repeat;
  .fileContainer{
    background:none;
  }
  &:hover{
  filter: grayscale(1);

  }
  background-image: url(${({ image }) => image});
  border-radius: 50%;
  width: 40px;
  background-size: cover;
  background-position: center;
  height: 40px;
`;