import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
interface IHandleSelectedItem {
  itemInformation: any;
  filters: string[];
}
interface ISelectedItem {
  name: string;
  id: string;
  filters: string[];
}
interface IReturn {
  selectedItem: ISelectedItem;
  handleSelectedItem(object: IHandleSelectedItem): void;
  handleRemoveSelection(): void;
}
const useSelectedIndicator = (): IReturn => {
  const dispatch = useDispatch();
  const selectedInformation: any = useSelector<any>(
    ({ dashboardSelected }) => dashboardSelected
  );
  const handleSelectedItem = useCallback((object: IHandleSelectedItem) => {
    const objectTreatmentToPassReducer: ISelectedItem = {
      name: object.itemInformation.i,
      id: object.itemInformation.id,
      filters: object.filters,
    };
    dispatch({
      type: "SET_INDICATOR_SELECTED",
      payload: objectTreatmentToPassReducer,
    });
  }, []);
  const handleRemoveSelection = useCallback(() => {
    const objectTreatmentToPassReducer = {
      name: "",
      id: "",
      filters: [],
    };
    dispatch({
      type: "SET_INDICATOR_SELECTED",
      payload: objectTreatmentToPassReducer,
    });
  }, []);
  return {
    selectedItem: selectedInformation?.indicatorSelected,
    handleSelectedItem,
    handleRemoveSelection,
  };
};
export default useSelectedIndicator;
