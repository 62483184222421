import React from "react";
import { Container } from "./style";
interface IProps {
  isFullWidth?: boolean;
}
const PageBody: React.FC<IProps> = ({ children, isFullWidth }) => {
  return <Container isFullWidth={isFullWidth}>{children}</Container>;
};

export default PageBody;
