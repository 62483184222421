import React from 'react'
import { Input } from './style'

interface IInputAutoComplet {
    onChange?: (e: any) => void ;
    type: string;
    placeholder: string;
}
const InputComponentAutoComplet: React.FC<IInputAutoComplet> = ({ onChange, type, placeholder }) => (
    <Input
        onChange={onChange}
        placeholder={placeholder}
        type={type}
    />
)

export default InputComponentAutoComplet