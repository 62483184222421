import styled from "styled-components";
interface IProps {
    minute: number;
}
export const ProgressBar = styled.div`
  width: 200px;
  margin-top: 10px;
  margin-bottom: -20px;
  background-color: #3e415025;
  height: 8px;
  overflow: hidden;
  border-radius: 100px;
`;
export const InnerProgress = styled.div<IProps>`
  background-color: #2f3243;
  width: 100%;
  height: 40px;
  animation: example ${({minute}) => `${minute * 60}s`} infinite;
  @keyframes example {
    from {
      max-width: 0;
    }
    to {
      max-width: 100%;
    }
  }
`;
