import React, { memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import HideModal from "../../../Components/hideModal";
import Table from "../../../Components/table";
import { KpiContainer, Container, Legend as LegendTable } from "../style";
import { flatten, groupBy, orderBy, sumBy, toNumber } from "lodash";
import { format, sub } from "date-fns";
import useFilter from "../../../hooks/useFilter";
import useUser from "../../../hooks/useUser";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import useTranslate from "../../../hooks/useTranslate";
import { IDataApi, VolumeClimatico } from "../interface";

const VolumeClimaticoComponent: React.FC<any> = (props) => {
  const { translate } = useTranslate();
  const { userInformation } = useUser();
  const [listVolumeClimatico, setVolumeClimaticoList] = useState<any>([]);
  const [data, setData] = useState<VolumeClimatico[]>([]);

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation]);
  useEffect(() => {
    formatVolumeClimatico();
  }, [data]);
  const fetchData = async () => {
    const { data: dataInfo } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/sig/indicadoresClimaticos`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setData(dataInfo);
  };
  const formatVolumeClimatico = () => {
    let result: any = { tr: [], td: { elementoClimatico: []  } };
    const farm: any = groupBy(data, "DscFazenda");
    const fazendasKey = Object.keys(farm);
    const arrayFazendas = [translate("elementos climaticos"), ...orderBy(fazendasKey)];
    const dataSet = fazendasKey.map((farmName: any) => {
      const farmArr = farm[farmName];
      if (farmArr[0].ElementosClimaticos.length > 0) {
        result.td[farmName] = farmArr[0];
      }
    });
    result.tr = arrayFazendas;
    result.th = [
      translate("pluviometro (acumulado ano)"),
      translate("pluviometro (ultimos 30 dias)"),
      translate("pluviometro (ultimos 7 dias)"),
    ];
    setVolumeClimaticoList(result);
  };
  return (
    <>
      <CardBody
        returnTitleUpdated={(e) => props.dto.defineTitle(e)}
        title={props?.dto?.customTitle || translate("volume climatico")}
      >
        <Container>
          <KpiContainer style={{ height: "100%" }}>
            <small></small>
            <Table>
              <thead>
                <tr>
                  {listVolumeClimatico?.tr?.map((farmName: string) => {
                    return <th align="left">{farmName}</th>;
                  })}
                </tr>
              </thead>
              {listVolumeClimatico?.th?.map((nameVolumeClimatico: string) => {
                return (
                  <tr>
                    <td
                      align="left"
                      style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {nameVolumeClimatico}
                    </td>
                    {listVolumeClimatico?.tr?.map(
                      (nameElementoClimatico: string) => {
                        if (nameElementoClimatico != translate("elementos climaticos")) {
                          return (
                            <td>
                              {listVolumeClimatico?.td?.[
                                nameElementoClimatico
                              ]?.ElementosClimaticos?.map((item: any) => {
                                return (
                                  <>
                                    <td align="center">
                                      {toNumber(
                                        item[
                                          nameVolumeClimatico ===
                                          translate("pluviometro (acumulado ano)")
                                            ? "AcumuladoAno"
                                            : nameVolumeClimatico ===
                                            translate("pluviometro (ultimos 30 dias)")
                                            ? "Acumulado30Dias"
                                            : "Acumulado7Dias"
                                        ].toFixed(2)
                                      ).toLocaleString()}
                                    </td>
                                  </>
                                );
                              }) || "-"}
                            </td>
                          );
                        }
                      }
                    )}
                  </tr>
                );
              })}
            </Table>
          </KpiContainer>
        </Container>
      </CardBody>
    </>
  );
};

export default VolumeClimaticoComponent;
