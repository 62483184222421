import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
interface IRemoveFilter {
  key: string;
  idKey?: string;
}
interface IMultiFilter {
  (key: string, value: string, title: string, actualStateFilter: any[]): void;
}
const useFilter = (id?: string) => {
  const path = window.location.pathname.substr(1);
  const filter: any = useSelector<any, string>(({ filter }) => filter[path]);
  const dispatch = useDispatch();
  const handleAddFilter = useCallback(
    (
      key: string,
      value: string | number,
      title: string,
      information?: any
    ) => {
      const keyWithId = id ? `${key}_${id}` : key;
      dispatch({
        type: "UPDATE_FILTER",
        payload: { key: keyWithId, value, title, indicator: path, information },
      });
    },
    []
  );
  const handleRemoveSection = useCallback(({ key, idKey }: IRemoveFilter) => {
    const keyWithId = id ? `${key}_${id}` : idKey ? `${key}_${idKey}` : key;
    dispatch({
      type: "REMOVE_SECTION",
      payload: { key: keyWithId, indicator: path },
    });
  }, []);
  const handleRemoveFilter = useCallback(({ key, idKey }: IRemoveFilter) => {
    const keyWithId = id ? `${key}_${id}` : idKey ? `${key}_${idKey}` : key;
    dispatch({
      type: "REMOVE_FILTER",
      payload: {
        indicator: path,
        key: keyWithId,
        value: "",
      },
    });
  }, []);
  const handleAddMultFitler: IMultiFilter = useCallback(
    (key, value, title, actualStateFilter) => {
      const values = [...actualStateFilter, { value, title }];
      dispatch({
        type: "UPDATE_MULTFILTER",
        payload: { key, values, indicator: path },
      });
    },
    []
  );
  const handleRemoveMultFitler = useCallback(
    (key, value, actualStateFilter) => {
      const filteringValues = actualStateFilter.filter(
        (props: any) => props.value != value
      );

      dispatch({
        type: "UPDATE_MULTFILTER",
        payload: { key, values: filteringValues, indicator: path },
      });
    },
    []
  );
  const cleanFilter = useCallback(() => {
    dispatch({
      type: "CLEAR_FILTER_LIST",
      payload: {},
    });
  }, []);
  const removeComparativoSafra = (id: string) => {
    dispatch({ type: "REMOVE_COMPARATIVOSAFRA", payload: { id } });
  };
  return {
    handleAddFilter,
    handleRemoveFilter,
    cleanFilter,
    filter,
    handleRemoveSection,
    handleAddMultFitler,
    removeComparativoSafra,
    handleRemoveMultFitler,
  };
};
export default useFilter;
