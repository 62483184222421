import React, { CSSProperties, memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import { Kpi, Table } from "../../../styles/globalComponents";
import { IIventarioAnimaisRebanho } from "../interface";
import useUser from "../../../hooks/useUser";
import useTranslate from "../../../hooks/useTranslate";

import axios from "axios";
import useSWR from "swr";
interface GenericFunctionType {
  <T>(url: string): Promise<T>;
}
interface IProps {
  style?: CSSProperties;
  dto?: any;
}
const Pecuaria: React.FC<IProps> = ({ style, dto }) => {
  const { userInformation } = useUser();
  const {translate} = useTranslate()

  const {
    data: indicadoresAcumuladoAno,
  } = useSWR(
    userInformation.UrlApiFarm4all &&
      `${userInformation.UrlApiFarm4all}/pecuaria/sig/indicadores/inventarioAnimaisRebanho`,
    (url) => fetchData<IIventarioAnimaisRebanho[]>(url)
  );
  const fetchData: GenericFunctionType = async (url: string) => {
    const { data } = await axios.get(url, {
      auth: {
        username: userInformation.UsuarioApiFarm4all,
        password: userInformation.SenhaApiFarm4all,
      },
    });
    return data;
  };
  return (
    <CardBody
      returnTitleUpdated={(e) => dto.defineTitle(e)}
      style={style || { marginTop: "0", justifyContent: "stretch" }}
      title={
        dto?.customTitle || translate("inventario de animais por rebanho *(na data do e-mail)")
        
      }
    >
      <div
        style={{
          minHeight: "300px",
          overflow: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <Table>
          <tbody>
            <tr
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {indicadoresAcumuladoAno?.map(({ Quantidade, Rebanho }) => {
                return (
                  <td
                    style={{
                      display: "flex",
                      border: "1px solid #ddd",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      textAlign: "center",
                      padding: "10px 30px",
                      margin: "3px",
                    }}
                  >
                    <p> {Rebanho}</p>
                    <p style={{ marginLeft: "16px" }}>{Quantidade}</p>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>
      </div>
    </CardBody>
  );
};

export default memo(Pecuaria);
