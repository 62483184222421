import React, { useEffect, useState } from "react";
import CardBody from "../../Components/card";
import { Kpi } from "../../styles/globalComponents";
import useFilter from "../../hooks/useFilter";
import { useSelector } from "react-redux";
import { translate } from "../../utils/translate";
import { KpiContainer, Container } from "./style";
import { toNumber } from "lodash";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
} from "recharts";
import useUser from "../../hooks/useUser";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NoDataToShow from "../../Components/noDataToShow";
import { info, dismiss } from "../../services/toasty";
import useFetch from "../../hooks/fetchData";
import { GiGrain, GiPlantsAndAnimals } from "react-icons/gi";
import { BiTimeFive } from "react-icons/bi";
import { AnaliseSafra } from "./interface";
import useTranslate from "../../hooks/useTranslate";
import Col from "../../Components/column";

const ColheitaTalhao: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { translate } = useTranslate();
  const [data, setData] = useState<AnaliseSafra>();
  const { userInformation } = useUser();
  const { filter } = useFilter();
  const { fetchPeriodoProducao } = useFetch();
  const colors = ["#8884d8", "#00C49F"];

  const fetchData = async () => {
    if (
      filter?.filtersApply?.CodPeriodoProducao?.value &&
      filter?.filtersApply?.CodSafra?.value
    ) {
      info("Carregando dados, Aguarde", true);
      const { data } = await axios.post(
        `${userInformation.UrlApiFarm4all}agricola/sig/analiseSafra`,
        {
          CodFazenda: filter?.filtersApply?.CodFazenda?.value,
          CodSafra: filter?.filtersApply?.CodSafra?.value,
          CodTalhao: filter?.filtersApply?.CodTalhao?.value,
          CodPeriodoProducao: filter?.filtersApply?.CodPeriodoProducao?.value,
          CodSetor: filter?.filtersApply?.CodSetor?.value,
        },
        {
          auth: {
            username: userInformation.UsuarioApiFarm4all,
            password: userInformation.SenhaApiFarm4all,
          },
        }
      );
      setData(data);
      dismiss();
    }
  };

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
      fetchPeriodoProducao(filter?.filtersApply?.CodPeriodoProducao?.value);
    }
  }, [userInformation, filter]);

  if (loading) {
    return (
      <CardBody>
        <SkeletonTheme color="#20202010" highlightColor="#44444410">
          <Skeleton
            count={1}
            style={{ marginLeft: "10px", marginBottom: "20px" }}
            height={30}
            width={300}
          />
        </SkeletonTheme>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <SkeletonTheme color="#20202010" highlightColor="#44444410">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Skeleton
                count={1}
                style={{ marginLeft: "10px" }}
                height={10}
                delay={1}
                width={300}
              />
              <Skeleton
                count={1}
                style={{ marginLeft: "10px" }}
                height={50}
                delay={1}
                width={300}
              />
              <Skeleton
                count={2}
                style={{ marginLeft: "10px" }}
                height={10}
                delay={1}
                width={100}
              />

              <Skeleton
                count={2}
                style={{ marginLeft: "10px" }}
                height={10}
                delay={1}
                width={100}
              />
            </div>
          </SkeletonTheme>
          <SkeletonTheme color="#20202010" highlightColor="#44444410">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Skeleton
                count={1}
                style={{ marginLeft: "10px" }}
                height={10}
                delay={1}
                width={300}
              />
              <Skeleton
                count={1}
                style={{ marginLeft: "10px" }}
                height={80}
                circle={true}
                delay={1}
                width={80}
              />

              <Skeleton
                count={2}
                style={{ marginLeft: "10px", marginTop: "20px" }}
                height={10}
                delay={1}
                width={100}
              />
            </div>
          </SkeletonTheme>
          <SkeletonTheme color="#20202010" highlightColor="#44444410">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Skeleton
                count={1}
                style={{ marginLeft: "10px" }}
                height={10}
                delay={1}
                width={300}
              />
              <Skeleton
                count={1}
                style={{ marginLeft: "10px" }}
                height={50}
                delay={1}
                width={300}
              />
              <Skeleton
                count={2}
                style={{ marginLeft: "10px" }}
                height={10}
                delay={1}
                width={100}
              />

              <Skeleton
                count={2}
                style={{ marginLeft: "10px" }}
                height={10}
                delay={1}
                width={100}
              />
            </div>
          </SkeletonTheme>
        </div>
      </CardBody>
    );
  }
  if (!filter?.filtersApply?.CodSafra?.value) {
    return (
      <CardBody title={translate("sem safra definida")}>
        <Container>
          <KpiContainer style={{ flexBasis: "20%" }}>
            <NoDataToShow
              icon={<GiGrain size="50px" />}
              message={translate("por gentileza, selecione a safra")}
            />
          </KpiContainer>
        </Container>
      </CardBody>
    );
  }
  if (!filter?.filtersApply?.CodPeriodoProducao?.value) {
    return (
      <CardBody title={translate("sem periodo de producao definida")}>
        <Container>
          <KpiContainer style={{ flexBasis: "20%" }}>
            <NoDataToShow
              icon={<BiTimeFive size="50px" />}
              message={translate("por gentileza, selecione o periodo de producao")}
            />
          </KpiContainer>
        </Container>
      </CardBody>
    );
  }
  if (data && data?.PeriodosProducao[0]?.Fazendas?.length < 1) {
    return (
      <CardBody title={translate("sem dados para visualizar")}>
        <Container>
          <KpiContainer style={{ flexBasis: "20%" }}>
            <NoDataToShow />
          </KpiContainer>
        </Container>
      </CardBody>
    );
  }
  return (
    <>
      <Col>
        {data?.PeriodosProducao[0]?.Fazendas?.map(
          ({
            AreaColhida,
            AreaPlantada,
            DscFazenda,
            KgsColhidos,
            PctColhido,
            Produtividade,
            SacasColhidas,
            SacasEstimadas,
            KgsEstimados,
          }) => {
            const { UnidadeRendimento } = data?.PeriodosProducao[0];
            const pieInformation = [
              {
                name: translate("nao colhido (ha)"),
                value: parseFloat(((PctColhido - 100) * -1).toFixed(2)),
              },
              {
                name: translate("percentual colhido (%)"),
                value: parseFloat(PctColhido.toFixed(2)),
              },
            ];
            const barChartSc = [
              {
                name: translate("sacas"),
                Estimado: SacasEstimadas.toFixed(3),
                Colhido: SacasColhidas.toFixed(2),
              },
            ];
            const barChartKg = [
              {
                name: "KG",
                Estimado: KgsEstimados.toFixed(2),
                Colhido: KgsColhidos.toFixed(2),
              },
            ];
            return (
              <CardBody title={`${DscFazenda}`}>
                <Container>
                  <KpiContainer style={{ flexBasis: "20%" }}>
                    <small>{translate("colhido (ha)")}</small>
                    <Kpi>
                      <h1>
                        {PctColhido.toFixed(2)} <small>%</small>
                      </h1>
                    </Kpi>
                    <br />
                    <Kpi>
                      <p>
                        <strong>{`${AreaPlantada.toLocaleString()}`} </strong>
                        <small>{`${translate("ha area plantada")}`}</small>
                      </p>
                    </Kpi>
                    <Kpi>
                      <p>
                        <strong>{`${AreaColhida.toLocaleString()}`} </strong>
                        <small>{`${translate("ha area colhida")}`}</small>
                      </p>
                    </Kpi>
                  </KpiContainer>
                  <KpiContainer
                    style={{ flexBasis: "20%", textAlign: "center" }}
                  >
                    <small>{translate("percentual colhido (%)")}</small>
                    <PieChart className="centerGraph" width={350} height={200}>
                      <Pie
                        data={pieInformation}
                        dataKey="value"
                        nameKey="name"
                        cx={"50%"}
                        labelLine={false}
                        cy={"50%"}
                        outerRadius={50}
                        fill="#82ca9d"
                        label
                      >
                        {pieInformation.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={colors[index % colors.length]}
                          />
                        ))}
                      </Pie>
                      <Legend />
                      <Tooltip />
                    </PieChart>
                  </KpiContainer>
                  <KpiContainer style={{ flexBasis: "20%" }}>
                    <small>{translate("quantidade")}</small>
                    <Kpi>
                      <h1>
                        {`${toNumber(SacasColhidas).toLocaleString()} `}
                        <small>{UnidadeRendimento}</small>
                      </h1>
                    </Kpi>
                    <br />
                    <Kpi>
                      <p>
                        <strong>
                          {`${toNumber(
                            KgsColhidos.toFixed(2)
                          ).toLocaleString()}`}
                        </strong>
                        <small> Kg</small>
                      </p>
                    </Kpi>
                    <Kpi>
                      <p>
                        <strong>{`${Produtividade.toLocaleString()}`}</strong>
                        <small> {`${translate("produtividade")}`}</small>
                      </p>
                    </Kpi>
                  </KpiContainer>
                </Container>
                <br />
                <Container>
                  <KpiContainer
                    style={{ flexBasis: "20%", textAlign: "center" }}
                  >
                    <small>{translate("Estimado x Colhido | Sacas")}</small>
                    <BarChart
                      width={500}
                      height={400}
                      data={barChartSc}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <XAxis dataKey="name" />
                      <Tooltip
                        formatter={(e) => toNumber(e).toLocaleString()}
                      />
                      <Legend />
                      <Bar
                        label={{
                          color: "black",
                          fontSize: 15,
                          position: "insideTop",
                          formatter: (e) => toNumber(e).toLocaleString(),
                        }}
                        background={{ fill: "#eee" }}
                        dataKey={translate("Estimado")}
                        fill="#c7c4ff"
                      />
                      <Bar
                        label={{
                          color: "black",
                          fontSize: 15,
                          position: "insideTop",
                          formatter: (e) => toNumber(e).toLocaleString(),
                        }}
                        background={{ fill: "#eee" }}
                        dataKey={translate("Colhido")}
                        fill="#82ca9d"
                      />
                    </BarChart>
                  </KpiContainer>
                  <KpiContainer
                    style={{ flexBasis: "20%", textAlign: "center" }}
                  >
                    <small>{translate("Estimado x Colhido | Kg")}</small>
                    <BarChart
                      barCategoryGap="10%"
                      width={500}
                      height={400}
                      data={barChartKg}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <XAxis dataKey="name" />
                      <Tooltip
                        formatter={(e) => toNumber(e).toLocaleString()}
                      />
                      <Legend />
                      <Bar
                        label={{
                          color: "black",
                          fontSize: 15,
                          position: "insideTop",
                          formatter: (e) => toNumber(e).toLocaleString(),
                        }}
                        background={{ fill: "#eee" }}
                        dataKey={translate("Estimado")}
                        fill="#c7c4ff"
                      />
                      <Bar
                        label={{
                          color: "black",
                          fontSize: 15,
                          position: "insideTop",
                          formatter: (e) => toNumber(e).toLocaleString(),
                        }}
                        background={{ fill: "#eee" }}
                        dataKey={translate("Colhido")}
                        fill="#82ca9d"
                      />
                    </BarChart>
                  </KpiContainer>
                </Container>
              </CardBody>
            );
          }
        )}
      </Col>
    </>
  );
};

export default ColheitaTalhao;
