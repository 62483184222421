import styled from "styled-components";

export const Table = styled.table`
  text-align: left;
  top: 50px;
  font-family: "Roboto";
  border-collapse: collapse;
  left: 10px;
  width: 100% !important;
  thead tr th {
    font-weight: 500;
    padding: 9px 20px 10px 10px;
    text-transform:uppercase;
    font-weight:bold;
  }
  tbody tr {
    height: 40px;
  }
  tbody tr td {
    padding-left: 10px;
    border-left: none;
  }
  tbody tr:hover {
    background: #efefef;
  }
`;
export const Kpi = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  text-align: left;
  h1 {
    flex-basis: 20%;
    font-size: 36px;
    font-weight: 500;
    flex-basis: 100%;
  }
  p {
    text-transform: uppercase;
  }
  small {
    font-size: 20px;
    opacity: 0.5;
  }
  @media only screen and (max-width: 600px) {
    align-items: left;
  justify-content: left;
  text-align: left;
  }
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  font-family: "Roboto";
  flex-wrap: wrap;
  justify-content: center;
`;
export const InformationNotDataToShow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1 {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
`;
