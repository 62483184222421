import styled, { css } from "styled-components";
interface IEditmode {
  editMode: boolean;
}
interface IDisabled {
  disabled?: boolean;
  subLevels?: boolean;
  dragging?: boolean;
}
export const Container = styled.ul<IEditmode>`
  background-color: #2F3243;
  overflow-y:scroll;
  padding: 10px 20px;
  position: fixed;
  width:300px;
  height: 100%;
  right: ${(props) => (props.editMode ? "-500px" : "0")};
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  top:0px;
  z-index:99999;
`;
export const Name = styled.h1`
  margin-top: 20px;
  font-size: 25px;
  font-weight: 500;
`;
export const Item = styled.li<IDisabled>`
  margin-top: 16px;
  border-radius: 4px;
  padding: 5px 8px;
  list-style: none;
  padding-left: 16px;
  ${(props) =>
    props.subLevels &&
    css`
    cursor: pointer !important;
      background: #34374A;
      padding: 17px 37px;
      &:hover {
        background: #34374A !important;
        
      }
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed !important ;
      background: #ffffff14;
      padding: 17px 37px;
      opacity: 0.3;
    `}
  transition: 0.2s;
  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        cursor: grab;
        background: linear-gradient(
          90deg,
          #5672b5 0%,
          rgba(0, 212, 255, 0) 100%
        );
        padding: 17px 37px;
      `}
  }
  h1 {
    font-weight: 400;
    font-size: 15px;
  }
  small {
    font-family: "Roboto";
    padding-top: 10px;
    font-weight: 100;
  }
`;
