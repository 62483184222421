import React, { memo, useEffect, useState } from "react";
import CardBody from "../../../Components/card";
import HideModal from "../../../Components/hideModal";
import Table from "../../../Components/table";
import { KpiContainer, Container, Legend as LegendTable } from "../style";
import { flatten, groupBy, orderBy, sumBy, toNumber } from "lodash";
import useUser from "../../../hooks/useUser";
import axios from "axios";
import NoDataToShow from "../../../Components/noDataToShow";
import { IManutencaoPreventiva } from "../interface";
import useTranslate from "../../../hooks/useTranslate";

const ManutencaoPreventiva: React.FC<any> = (props) => {
  const { userInformation } = useUser();
  const [data, setData] = useState<IManutencaoPreventiva[]>([]);
  const {translate} = useTranslate()

  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation]);

  const fetchData = async () => {
    const { data: dataInfo } = await axios.get(
      `${userInformation.UrlApiFarm4all}agricola/relatorios/logistica/manutencaoPreventiva`,
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setData(orderBy(dataInfo, ["KmRestante"], ["desc"]));
  };

  return (
    <>
      <CardBody
        returnTitleUpdated={(e) => props.dto.defineTitle(e)}
        title={props?.dto?.customTitle || translate("manutencao preventiva")}
      >
        <Container>
          <KpiContainer
            style={{ maxHeight: "400px", height: "100%", overflow: "scroll" }}
          >
            {data.length < 1 ? (
              <NoDataToShow />
            ) : (
              <Table style={{ borderCollapse: "collapse" }}>
                <tr>
                  <th>{translate("fazenda")}</th>
                  <th>{translate("equipamento")}</th>
                  <th>{translate("tipo manutencao")}</th>
                  <th>{translate("sistema veicular")}</th>
                  <th>{translate("km atual")}</th>
                  <th>{translate("km prox. manutencao")}</th>
                  <th>{translate("km restante")}</th>
                  <th>{translate("status")}</th>
                </tr>
                {data.map((Os: IManutencaoPreventiva) => (
                  <tr className={Os.Status === "V" ? "vencido" : ""}>
                    <td>{Os.Fazenda}</td>
                    <td>{Os.Equipamento}</td>
                    <td>{Os.Tipo}</td>
                    <td>{Os.Sistema}</td>
                    <td>
                      {Os.KmAtual
                        ? toNumber(Os.KmAtual).toFixed(2).toLocaleString()
                        : Os.KmAtual}
                    </td>
                    <td>
                      {Os.KmProximaManutencao
                        ? toNumber(Os.KmProximaManutencao)
                            .toFixed(2)
                            .toLocaleString()
                        : Os.KmProximaManutencao}
                    </td>
                    <td>
                      {Os.KmRestante
                        ? toNumber(Os.KmRestante).toFixed(2).toLocaleString()
                        : Os.KmRestante}
                    </td>
                    <td>{Os.Status == "V" ? translate("vencida") : translate("pendente")}</td>
                  </tr>
                ))}
              </Table>
            )}
          </KpiContainer>
        </Container>
      </CardBody>
    </>
  );
};

export default ManutencaoPreventiva;
