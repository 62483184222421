import React, { useEffect, useState } from "react";
import { ContainerModal, Container, ContainerHeader } from "./style";
import Table from "../table";
import { Button } from "../../Pages/homeDashboard/style";
import axios from "axios";
import useUser from "../../hooks/useUser";
import { format } from "date-fns";
// import { translate } from "../../utils/translate";
import useTranslate from "../../hooks/useTranslate";

interface IProps {
  filters?: string[];
  information?: {
    Apontamento: any;
    CodFazenda: string;
    DscFazenda: string;
    QtdEmAberto: number;
    QtdEncerrada: number;
  };
  close: () => void;
}
const HideModal: React.FC<IProps> = ({ information, close ,children}) => {
  const { translate } = useTranslate();

  const { userInformation } = useUser();
  const [dataObj, setData] = useState<any>();
  const methods: any = {
    Abastecimento: "agricola/abastecimento/get",
    "Boletim de Colheita": "agricola/boletimColheita/get",
    Fitossanidade: "agricola/aptFitossanidade/get",
    Lubrificação: "agricola/lubrificacao/get",
    "Operações Agrícolas": "agricola/aptOperacaoAgricola/get",
    "Ordem de Serviço da Oficina": "agricola/ordemServicoOficina/get",
    "Recomendação Técnica": "agricola/recomendacaoTecnica/get",
    "Registro Climático": "agricola/registroClimatico",
  };
  useEffect(() => {
    if (userInformation.UrlApiFarm4all) {
      fetchData();
    }
  }, [userInformation, information]);

  const fetchData = async () => {
    const { data } = await axios.post(
      `${userInformation.UrlApiFarm4all}${methods[information?.Apontamento]}`,
      {
        CodFazenda: information?.CodFazenda,
        Status: "A",
      },
      {
        auth: {
          username: userInformation.UsuarioApiFarm4all,
          password: userInformation.SenhaApiFarm4all,
        },
      }
    );
    setData(data);
  };
  return (
    <>
      <Container>
        <ContainerModal>
          <ContainerHeader>
            <div>
              <h1>{translate("apontamentos abertos")}</h1>
              <small style={{ opacity: 0.8 }}>
                <strong>{information?.QtdEmAberto} </strong>{translate("apontamento de")}{" "}
                <strong>{information?.Apontamento}</strong> {translate("na fazenda")}{" "}
                <strong>{information?.DscFazenda}</strong> {translate("em aberto")}
              </small>
            </div>
          </ContainerHeader>
          <div style={{ maxHeight: "300px", overflow: "scroll" }}>
            <Table style={{ marginTop: "20px", textAlign: "center" }}>
              <thead>
                <th>{translate("codigo")}</th>
                <th>{translate("data")}</th>
              </thead>
              <tbody>
                {dataObj?.map((prop: any) => {
                  const dataEntrada =
                    prop.Data || prop.DtEntrada || prop.DtInicial;
                  return (
                    <tr>
                      <td>{prop.Codigo}</td>
                      <td>
                        {dataEntrada
                          ? format(new Date(dataEntrada), "dd/MM/yy")
                          : translate("sem data definida")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {children}
          <Button onClick={close} style={{ width: "100%", marginLeft: 0 }}>
            {translate("fechar")}
          </Button>
        </ContainerModal>
      </Container>
    </>
  );
};

export default HideModal;
