import styled, { css, createGlobalStyle } from "styled-components";
import { AiOutlineClose, AiOutlineCoffee, AiOutlineCopy } from "react-icons/ai";
interface IEditMode {
  editMode?: boolean;
  selectedItem?: boolean;
  dragging?: boolean;
}

export const Item = styled.div<IEditMode>`
  background-color: #fff !important;
  border-radius: 7px;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
  ${({ editMode }) =>
    editMode
      ? css`
          opacity: 0.5;
          border: 1px solid #70a5ff !important;
          box-shadow: -1px 2px 8px rgba(0, 0, 0, 0);
        `
      : css`
          border: 1px solid #00000026 !important;
        `}
  ${({ selectedItem }) =>
    selectedItem &&
    css`
      border: 3px solid #2f3243 !important;
    `}

    .grabbingDrag {
    ${({ dragging }) =>
      dragging
        ? css`
            cursor: grabbing;
          `
        : css`
            cursor: grab;
          `}
  }
`;
interface IIcon {
  distance: string;
}
export const Icon = styled.div<IIcon>`
  position: absolute;
  z-index: 99999;
  color: #fff;
  height: 20px;
  width: 20px;
  right: ${({ distance }) => distance};
  top: 17px;
  cursor: pointer;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    background-color:#fff;
  }

`;
