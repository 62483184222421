import React from "react";
import { CSSProperties } from "styled-components";
import {Container} from './style'

interface IProps {
  style?: CSSProperties;
}

const Row: React.FC<IProps> = ({ children, style }) => {
  return <Container style={style}>{children}</Container>;
};

export default Row;
