import styled, { css } from "styled-components";
export const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;
export const Select = styled.select`
  border:none;
  width:100%;
  padding:10px 0px;
  background:transparent;
  outline:none;
`
export const Card = styled.div`
  width: 325px;
  height:auto;
  top:10px;
  border-radius: 4px;
  position: absolute;
  pointer-events: all;
  left:10px;
  min-width: 200px;
  padding: 10px 20px;
  overflow-x: hidden;
  z-index: 10005;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  z-index: 10005;
  justify-content: space-between;
  img {
    border-radius: 50%;
  }
 
`;
interface IImage {
  image: string;
}
export const Image = styled.div<IImage>`
  background-image: url(${({ image }) => image});
  border-radius: 50%;
  width: 60px;
  background-size: cover;
  background-position: center;
  height: 60px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -15%;
  small {
    font-size: 13px;
    font-weight: 300;
  }
  h1 {
    margin-top: 4px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;
interface IItem {
  isEnable?: boolean;
}
export const Item = styled.div<IItem>`
  padding: 5px 20px;
  align-content: center;
  align-items: center;
  flex-direction: row;
  user-select: none;
  display: flex;
  width: 400px;
  flex-direction: row;
  margin-top: 10px;
  cursor: pointer;
  small {
    color: #626262;
    margin-left: 20px;
  }
  &:hover {
    background: #9d9d9d24;
    border-radius: 5px;
  }
  ${({ isEnable }) =>
    isEnable &&
    css`
      background: #9d9d9d24;
      border-radius: 5px;
      &:hover {
    background: #ffe1e1;
    border-radius: 5px;
  }
    `}
`;
