import React, { useContext, useEffect } from "react";
import { Container, Name } from "./style";
import Item from "../../Components/sidebarItem";
import itemsDash from "../../config/listItemDashboard.json";
import Ilayout from "../../store/modules/dashboardSelected/interface";
import { useSelector } from "react-redux";
import useTranslate from "../../hooks/useTranslate";

interface ISidebar {
  editMode: boolean;
}
interface IData {
  dashboardSelected: {
    layout: Ilayout;
  };
}
const SidebarReports: React.FC<ISidebar> = ({ editMode }) => {
  const data = useSelector<IData, any>(
    ({ dashboardSelected }) => dashboardSelected
  );
  const {translate} = useTranslate()
  const ListItemDashboard:any = itemsDash;
  return (
    <>
      <Container editMode={!editMode}>
        {ListItemDashboard.map((item:any) => (
          <>
            <Name>{translate(item.name)}</Name>
            {item.reports.map((report:any) => {
              let verification = data.layout?.find(
                (item: any) =>
                  item?.i === report?.component && !report?.sub_components
              );
              return (
                <Item
                  verification={!!verification}
                  sharedLayout={data.layout}
                  title={translate(report?.title)}
                  subtitle={translate(report?.subtitle)}
                  component={report?.component}
                  subLevels={report?.sub_components}
                />
              );
            })}
          </>
        ))}
      </Container>
    </>
  );
};

export default SidebarReports;
