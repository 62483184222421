import React, { CSSProperties, memo } from "react";
import CardBody from "../../../Components/card";
import { IEntradaSemana } from "../interface";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Label,
} from "recharts";
import useUser from "../../../hooks/useUser";
import useTranslate from "../../../hooks/useTranslate";
import axios from "axios";
import useSWR from "swr";
interface GenericFunctionType {
  <T>(url: string): Promise<T>;
}
interface IProps {
  style?: CSSProperties;
}
const EntradaNaSemanaStackedBar: React.FC<IProps> = ({ style }) => {
  const { userInformation } = useUser();
  const {translate} = useTranslate()
  const { data: dataInformation } = useSWR(
    userInformation.UrlApiFarm4all &&
      `${userInformation.UrlApiFarm4all}/pecuaria/sig/indicadores/entradasSemana`,
    (url) => fetchData<IEntradaSemana[]>(url)
  );
  const fetchData: GenericFunctionType = async (url: string) => {
    const { data } = await axios.get(url, {
      auth: {
        username: userInformation.UsuarioApiFarm4all,
        password: userInformation.SenhaApiFarm4all,
      },
    });
    return data;
  };
  

  return (
    <CardBody style={{ height: "400px", maxWidth: '40%' }} title={translate("entradas na semana")}>
      <ResponsiveContainer>
       <BarChart data={dataInformation}>
          <CartesianGrid strokeDasharray="3 3" visibility="hidden" />
          <XAxis dataKey="Tipo" />
          <Legend/>
          <Tooltip />
          <Bar
            label={{
              color: "black",
              fontSize: 15,
              position: "center",
            }}
            stackId="a"
            maxBarSize={80}
            dataKey={translate("manejos de entrada")}
            fill="#8884d8"
          >
            <Label />
          </Bar>
          <Bar
            label={{
              color: "black",
              fontSize: 15,
              position: "center",
            }}
            stackId="a"
            maxBarSize={80}
            dataKey={translate("desembarques")}
            fill="#9fd884"
          >
            <Label />
          </Bar>
          <Bar
            label={{
              color: "black",
              fontSize: 15,
              position: "center",
            }}
            stackId="a"
            maxBarSize={80}
            dataKey={translate("gtas")}
            fill="#e92828"
          >
            <Label />
          </Bar>
          <Bar
            label={{
              color: "black",
              fontSize: 15,
              position: "center",
            }}
            stackId="a"
            maxBarSize={80}
            dataKey={translate("registro de negociacao")}
            fill="#d884d1"
          >
            <Label />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </CardBody>
  );
};

export default memo(EntradaNaSemanaStackedBar);
