import { Reducer } from "redux";
import produce from "immer";

const INITIAL_STATE = {
 
};

const customTitle: Reducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CUSTOM_TITLE": {
      return produce(state, (draft: any) => {
        draft.layout = action.payload;
      });
    }
  
    default: {
      return state;
    }
  }
};

export default customTitle;
