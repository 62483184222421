import styled from "styled-components";
import DatePicker from "react-datepicker";

export const Button = styled.button`
  background: #fff;
  cursor: pointer;
  color: #000;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 0;
  width: 100%;
  padding: 5px 20px;
  font-size: 13px !important;
`;

// export const DateComponent = styled(DatePicker)``;
export const Container = styled.div`
border-radius: 4px;
  .react-datepicker {
    width: 100% !important;
    border: none;
  }
  .react-datepicker__month-container {
    width: 100% !important;
    margin-top: 0px;
  }
  .react-datepicker__header  {
    margin-top: 0px !important;
    background:#262836;
    color:#fff;

  }
  .react-datepicker__day-name,.react-datepicker__current-month {
    color:#fff;
  }
  .react-datepicker__day--in-range {
    background:#2f3243  ;
    color: #fff;
  }
  .datepicker__day--selected{
    background:#2f3243  ;
    color: #fff;
  }
  .react-datepicker__day--in-selecting-range {
    background:#2f324320  ;
    color: #000;
  }
  width: 100% !important;
  label {
    color: #fff;
    font-size: 11px;
  }
`;

export const DateComponent = styled.input`
  background: #fff;
  padding: 10px 25px;
  width: 100%;
  transition: 0.3s;
  height: 51px;
  border: none;
  color: #000;
  outline: none;
  margin-top: 4px;
  font-weight: 400;
 
  &::-webkit-calendar-picker-indicator {
    /* filter: invert(1); */
    cursor: pointer;
  }
  `