import React from 'react';
import { CSSProperties } from 'styled-components';
import { Table } from './style';
interface Table {
    style?: CSSProperties;
    className?: string;
}
const TableComponent:React.FC<Table> = ({children, style, className}) => {
    return (
        <Table className={className} style={style}>
            {children}
        </Table>
    );
}

export default TableComponent